import {
  createSlice,
} from '@reduxjs/toolkit';

const hotkeysSlice = createSlice({
  name: 'hotkeys',
  initialState: {
    help: false,
  },
  reducers: {
    setHotkeysHelp: (state, action) => { state.help = action.payload; },
  },
});

export const {setHotkeysHelp} = hotkeysSlice.actions;

export default hotkeysSlice.reducer;
