import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  delete_file_warning: true,
};

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setFileWarningPreference: (state, action) => {
      state.delete_file_warning = action.payload;
    },
  },
});

export const {setFileWarningPreference} = preferencesSlice.actions;

export const selectDeleteFilePreference = (state) => {
  return state.preferences.delete_file_warning;
};

export default preferencesSlice.reducer;
