import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  view: 'general',
  account: null, // this should be the logged in username
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingsView: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based on those changes
      state.view = action.payload;
    },
    setSettingsAccount: (state, action) => {
      state.account = action.payload;
    },
  },
});

export const {setSettingsView, setSettingsAccount} = settingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSettingsView = (state) => {
  return state.settings.view;
};

export const selectSettingsAccount = (state) => {
  return state.settings.account;
};

export default settingsSlice.reducer;
