import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  modals: [],
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, action) => {
      if (state.modals[0] && (state.modals[0].which === action.payload.which)) {
        state.modals[0] = {
          ...state.modals[0],
          ...action.payload,
          show: true,
        };
      } else {
        state.modals.unshift({...action.payload, show: true});
      }
    },
    hideModal: (state, action) => {
      if (state.modals[0] && state.modals[0].which === action.payload.which) {
        state.modals[0] = {
          ...state.modals[0],
          show: false,
        };
      }
    },
    closeModal: (state, action) => {
      if (state.modals[0] && state.modals[0].which === action.payload.which) {
        state.modals = state.modals.slice(1);
      }
    },
    resetModals: (state, action) => {
      state.modals = [];
    },
    updateModalData: (state, action) => {
      if (state.modals[0] && state.modals[0].which === action.payload.which) {
        state.modals[0] = {
          ...state.modals[0],
          data: {
            ...action.payload.data,
          },
        };
      }
    },
  },
});

export const {showModal, hideModal, closeModal, updateModalData} = modalsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentModal = (state) => {
  return state.modal.modals[0];
};

export const selectCurrentModalData = (state) => {
  return state.modal.modals[0] && state.modal.modals[0].data;
};

export const selectIsModalShowing = (state) => {
  const m = selectCurrentModal(state);
  return m?.show;
};

export default modalsSlice.reducer;
