import {STATUS_INVALID_WORKSPACE} from '../app/constants';
import {buildRequestUrl, isElectron} from '../utils/helpers';
import {getAccessToken, logout} from '../utils/authentication';
import {buildErrorMessage} from '../../helpers';
import {logError as logRollbarError} from '../components/ErrorBoundary';

export const buildFetchArgs = async(...args) => {
  args[0] = buildRequestUrl(args[0]);
  const accessToken = await getAccessToken();
  args[1] = args[1] || {};
  if (accessToken && args[1]) {
    args[1] = {
      ...args[1],
      headers: {
        ...args[1].headers,
        'Authorization': `Bearer ${accessToken}`,
      },
      // credentials: 'include', // TODO: Should we enable this to send sesion and ALB cookies?
    };
    if (isElectron) args[1].headers.referer = window.location.origin;
  }
  return args;
};

export const fetchError = async(error, toast, logError = true) => {
  if (!error.data?.message) {
    let message = error.message || '';
    if (message === '[object Object]') {
      // message not properly stringified on BE. Build message on FE
      message = error.data?.error || '';
      if (typeof message === 'object') {
        message = buildErrorMessage(message);
      }
    }
    error.data = {...error.data, message};
    if (logError) {
      logRollbarError(message);
    }
  }
  if (toast) {
    toast({
      title: (error.data && error.data.message) || (error.data && error.data.error) || 'Something went wrong',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  }
  const {description, code, status} = error.data;
  if (
    status === STATUS_INVALID_WORKSPACE || // invalid workspace. TODO use correct status
    ['token_expired', 'invalid_auth_user', 'invalid_header'].includes(code) ||
    (code === 'invalid_header' && description === 'Unable to parse authentication token.')
  ) {
    await logout();
    window.location.reload();
  }
  throw error;
};
