import {serialize} from 'cookie';

/**
 * This sets `cookie` on `res` object
 */
const cookie = (res, name, value, options = {}) => {
  const stringValue =
    typeof value === 'object' ? 'j:' + JSON.stringify(value) : String(value);

  if ('maxAge' in options) {
    options.expires = new Date(Date.now() + options.maxAge);
    options.maxAge /= 1000;
  }

  res.setHeader('Set-Cookie', serialize(name, String(stringValue), options));
};

/**
 * Adds `cookie` function on `res.cookie` to set cookies for response
 */
const cookies = (handler) => (req, res) => {
  res.cookie = (name, value, options) => cookie(res, name, value, options);

  return handler(req, res);
};

export default cookies;

export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    /* eslint-disable-next-line eqeqeq */
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    /* eslint-disable-next-line eqeqeq */
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
if (typeof window !== 'undefined') window.getCookie = getCookie;

export const setCookie = ({path, name, value}) => {
  document.cookie = `${name}=${value}; expires=Thu, 18 Dec 2023 12:00:00 UTC; path=${path};`;
};
