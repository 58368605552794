import {datadogRum} from '@datadog/browser-rum';
// eslint-disable-next-line no-unused-vars
import {DOMAIN} from '../app/constants';
import {wayscriptBackendDomain} from '../../helpers';

const track = function(name, properties) {
  const windowDefined = typeof window !== 'undefined';
  if (!windowDefined || !wayscriptBackendDomain.includes(DOMAIN) || window.Cypress || window.Playwright) return;
  const analytics = window.analytics;
  // console.log('%cTrack', 'color:orange', name, properties);
  analytics.track(name, properties);
  datadogRum.addAction(name, properties);
};

const identify = function({
  id,
  firstName,
  lastName,
  ...properties
}) {
  const windowDefined = typeof window !== 'undefined';
  if (!windowDefined || !wayscriptBackendDomain.includes(DOMAIN) || window.Cypress || window.Playwright) return;
  const analytics = window.analytics;
  // console.log('%cIdentify', 'color:green', id);
  analytics.identify(id, {
    name: `${firstName} ${lastName}`,
    ...properties,
  });
  datadogRum.setUser({
    id,
    firstName,
    lastName,
    ...properties,
  });
};

const sessionStartedEvent = 'Session Started';
const signedOutEvent = 'Signed Out';
const workspaceDeletedEvent = 'Workspace Deleted';
const workspaceCreatedEvent = 'Workspace Created';
const inviteSentEvent = 'Invite Sent';
const lairCreatedEvent = 'Lair Created';
const lairDuplicatedEvent = 'Lair Duplicated';
const lairDeletedEvent = 'Lair Deleted';
const specialFileAddedEvent = 'Special File Added';
const processManuallyInvokedEvent = 'Process Manually Invoked';
const triggerAddedEvent = 'Trigger Added';
const lairDeployedEvent = 'Lair Deployed';
const lairDedeployedEvent = 'Lair De-deployed';
const processListViewedEvent = 'Process List Viewed';
const processLogViewedEvent = 'Process Log Viewed';
const alertCreatedEvent = 'Alert Created';
const groupCreatedEvent = 'Group Created';
const lairPublishedEvent = 'Lair Published';
const triggerRunCommandModifiedEvent = 'Trigger Run Command Modified';

export {
  track,
  identify,
  sessionStartedEvent,
  signedOutEvent,
  workspaceDeletedEvent,
  workspaceCreatedEvent,
  inviteSentEvent,
  lairCreatedEvent,
  lairDuplicatedEvent,
  lairDeletedEvent,
  specialFileAddedEvent,
  processManuallyInvokedEvent,
  triggerAddedEvent,
  lairDeployedEvent,
  lairDedeployedEvent,
  processListViewedEvent,
  processLogViewedEvent,
  alertCreatedEvent,
  groupCreatedEvent,
  lairPublishedEvent,
  triggerRunCommandModifiedEvent,
};
