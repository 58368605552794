import React from 'react';
import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {batch} from 'react-redux';
import {
  fetchFileChildren,
  buildFilePath,
  selectFileById,
  selectWorkspaceRoot,
  selectAllDevLairFiles,
  selectAllProdFiles,
  filesRemoved,
  syncFiles,
  selectLairViewFile,
  selectFilePropById,
  fetchDuplicateFile,
  fetchRenameFile,
  selectShouldFetchFileContentById,
  selectTriggersFileId,
  selectProdTriggersFileId,
  requestRunFile,
  selectLairRunIsAvailable,
  selectCurrentTriggersFileId,
} from '../files/FilesSlice';
import {closeModal, showModal, updateModalData} from '../modal/ModalSlice';
import fetchJson from '../../lib/fetchJson';
import {isElectron} from '../../utils/helpers';
import {
  getTriggerEndpointUrl,
  fetchRunTrigger,
} from '../triggers/TriggersSlice';
import {
  focusProcessTab,
  terminalClear,
  fetchLairProcesses,
} from '../processes/ProcessesSlice';
import {
  closePreview,
  removeUnsavedTabValue,
  saveAllUnsavedLairFiles,
  selectIsAnyLairTabUnsaved,
  selectLairUnsavedTabIds,
  selectLairUnsavedTabsLength,
  setFocusFirstLairTab,
  selectFileFocusedIsUpdating,
  selectFocusedId,
} from '../editor/EditorSlice';
import {IS_MOCK, TRIGGER_TYPES, TRIGGER_ENDPOINT_TYPES} from '../../app/constants';
import {selectSelectedWorkspaceName, selectWorkspaceTeamMember, memberNameNode} from '../../app/WorkspacesSlice';
import TableUsageBar from '../../components/TableUsageBar';
import theme from '../../theme';
import router from 'next/router';
import {buildErrorMessage, debugLog} from '../../../helpers';
import {IconHttp, IconClock, IconDeploy} from '../../components/Icons';
import {Flex, Text} from '@chakra-ui/react';
import {generateUniqueFileName} from '../files/helpers';
import * as analytics from '../../utils/analytics';

export const preProcessPostLair = (lair) => {
  const processed = [{
    ...lair.file_object,
    id: lair.file_object.id,
    manager: lair.lair_manager,
    created_date: '2021-06-01',
    last_run_date: '2021-06-04',
  }];
  return processed;
};

export const fetchCreateLair = createAsyncThunk(
  'lair/fetchCreateLair',
  async(name, {getState, dispatch, rejectWithValue}) => {
    analytics.track(analytics.lairCreatedEvent, {
      from: 'blank',
      template: null,
    });
    const state = getState();
    const workspaceFile = selectWorkspaceRoot(state);
    let match = false;
    const nameLower = name.toLowerCase();
    for (let i = 0; i < workspaceFile.children.length; i++) {
      const id = workspaceFile.children[i];
      const lairName = selectFilePropById(state, id, 'name');
      if (lairName && lairName.toLowerCase() === nameLower) {
        match = true;
        break;
      }
    }
    if (match) return rejectWithValue('Lair name already exists in workspace');
    if (isElectron) {
      const {error, payload} = await window.electron.message.invoke('message', {
        type: 'CREATE_LAIR',
        path: `${state.workspaces.directory}/${workspaceFile.name}`,
        parentId: workspaceFile.id,
        lairName: name,
      });
      if (error) return rejectWithValue(error);
      await dispatch(syncFiles({pushReady: true}));
      await dispatch(syncFiles({pullReady: true}));
      const files = payload;
      return files;
    } else {
      const data = await fetchJson('/lairs', {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          workspace_id: workspaceFile.id,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const payload = preProcessPostLair(data);
      dispatch(fetchFileChildren({
        id: data.id,
        path: `${workspaceFile.name}%2F${name}`,
      }));
      return payload;
    }
  },
);

export const removeProdFiles = () => (dispatch, getState) => {
  const state = getState();
  const prodLairFileIds = selectAllProdFiles(state).map(file => file.id);
  dispatch(filesRemoved(prodLairFileIds));
};

export const createDeployment = (id, toast) => async(dispatch, getState) => {
  return await dispatch(checkUnsavedLairFileChangesThenInvoke(async() => {
    const data = await dispatch(fetchCreateDeployment(id));
    if (!data.error && toast) {
      toast({
        title: 'Deploy Successful',
        status: 'success',
        duration: 9000,
      });
    } else if (data.error && toast) {
      toast({
        title: 'Deploy Error',
        status: 'error',
        duration: 9000,
      });
    }
  }));
};

export const fetchCreateDeployment = createAsyncThunk(
  'lair/fetchCreateDeployment',
  async(id, {getState, dispatch, rejectWithValue}) => {
    analytics.track(analytics.lairDeployedEvent);
    // deploy the lair
    const state = getState();
    const currentLair = id || state.lairs.view;

    const data = await fetchJson(`/lairs/${currentLair}/deployment-jobs`, {method: 'POST'});
    if (data.error) return rejectWithValue(buildErrorMessage(data.error));
    const deployId = data.id;
    const pollData = await dispatch(fetchPollDeploymentJob({deployId, lairId: currentLair}));
    if (pollData.error) return rejectWithValue(buildErrorMessage(pollData.error));
    dispatch(removeProdFiles());
    await dispatch(fetchDeployLairData(currentLair));
    return {
      ...pollData,
      lairId: currentLair,
    };
  },
);

export const fetchPollDeploymentJob = createAsyncThunk(
  'lair/fetchPollDeploymentJob',
  async({deployId, lairId}, {rejectWithValue}) => {
    let data;
    await new Promise((resolve, reject) => {
      let pollLimit = 90; // some high number?
      const pollJob = setInterval(async() => {
        data = await fetchJson(`/lairs/${lairId}/deployment-jobs/${deployId}`, {method: 'GET'});
        if (!data.error && data.status === 'success') {
          clearInterval(pollJob);
          resolve(data);
        } else {
          if (pollLimit <= 0) {
            clearInterval(pollJob);
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('reached poll deployment job limit');
          } else {
            pollLimit--;
          }
        }
      }, 1000);
    }).catch(error => {
      data = {error};
    });
    if (data.error) return rejectWithValue(buildErrorMessage(data.error));
    return data;
  },
);

export const fetchDeployLairData = createAsyncThunk(
  'lair/fetchDeployLairData',
  async(lairId, {getState, rejectWithValue}) => {
    lairId = lairId || selectLairView(getState());

    try {
      const data = await fetchJson(`/lairs/${lairId}/deployment`, {method: 'GET', logError: false});
      return {
        ...data,
        lairId,
      };
    } catch (error) {
      return rejectWithValue({error: String(error), lairId});
    }
  },
);

export const fetchDeleteDeployment = createAsyncThunk(
  'lair/fetchDeleteDeployment',
  async(id, thunkAPI) => {
    analytics.track(analytics.lairDedeployedEvent);
    const state = thunkAPI.getState();
    const currentLair = id || state.lairs.view;
    await fetchJson(`/lairs/${currentLair}/deployment`, {method: 'DELETE'});
    return {
      lairId: currentLair,
    };
  });

export const fetchPublishLair = createAsyncThunk(
  'lair/fetchPublishLair',
  async(id, thunkAPI) => {
    analytics.track(analytics.lairPublishedEvent);
    const state = thunkAPI.getState();
    const currentLair = id || state.lairs.view;
    const data = await fetchJson(`/lairs/${currentLair}`, {
      method: 'PATCH',
      body: JSON.stringify({
        is_public: true,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return {
      lairId: currentLair,
      ...data,
    };
  },
);

export const fetchPublicLairData = createAsyncThunk(
  'lair/fetchPublicLairData',
  async(id, thunkAPI) => {
    const data = await fetchJson(`/lairs/${id}/public`, {method: 'GET'});
    return {
      ...data,
      lairId: id,
    };
  },
);

export const fetchLairMetadata = createAsyncThunk(
  'lair/fetchPublicLairData',
  async(id, thunkAPI) => {
    const data = await fetchJson(`/lairs/${id}`, {method: 'GET'});
    return {
      ...data,
      lairId: id,
    };
  },
);

export const fetchLairsMetadata = createAsyncThunk(
  'lair/fetchLairsMetadata',
  async(lairIds, thunkAPI) => {
    batch(() => {
      lairIds.forEach(id => thunkAPI.dispatch(fetchLairMetadata(id)));
    });
  },
);

export const fetchAllDeployLairsData = () => (dispatch, getState) => {
  const state = getState();
  const lairFiles = selectAllDevLairFiles(state);
  lairFiles.forEach((file) => {
    dispatch(fetchDeployLairData(file.id));
  });
};

export const fetchDeletePublishLair = createAsyncThunk(
  'lair/fetchDeletePublishLair',
  async(id, thunkAPI) => {
    const state = thunkAPI.getState();
    const currentLair = id || state.lairs.view;
    const data = await fetchJson(`/lairs/${currentLair}`, {
      method: 'PATCH',
      body: JSON.stringify({
        is_public: false,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return {
      ...data,
      lairId: currentLair,
    };
  },
);

export const selectLairViewProdFileId = (state) => {
  return selectFilePropById(state, state.lairs.view, 'deployed_lair_id');
};

export const selectLairViewProdFile = (state) => {
  const prodLairId = selectLairViewProdFileId(state);
  if (!prodLairId) return null;
  return selectFileById(state, prodLairId);
};

export const fetchProdLair = createAsyncThunk(
  'lairs/fetchProdLair',
  async(args, {getState, dispatch, rejectWithValue}) => {
    // no args needed
    const state = getState();
    const currentLair = state.lairs.view;
    const prodName = selectDeployedLairName(state, currentLair);
    if (!prodName) return rejectWithValue('deployment metada not yet loaded');
    const workspaceId = state.workspaces.selected;
    const workspacePath = buildFilePath(state, workspaceId, true);

    const data = await fetchJson(`/files/${workspacePath}/${prodName}`, {
      method: 'GET',
    });

    const prodLairFiles = data;

    let prodLairFile;
    // temporary: mock prod lair name
    for (const i in prodLairFiles) {
      if (!prodLairFiles[i].is_directory) {
        prodLairFiles[i].dirty = true;
      }
      if (prodLairFiles[i].name.includes('.prod')) {
        if (IS_MOCK) {
          prodLairFiles[i].name = prodName;
          prodLairFiles[i].parent = workspaceId;
        }
        prodLairFile = prodLairFiles[i];
      }
    }

    dispatch(fetchLairMetadata(prodLairFile.id));

    const workspaceFile = selectFileById(state, workspaceId);

    return {
      filesList: prodLairFiles,
      prodFileId: prodLairFile.id,
      workspaceFile,
      lairId: currentLair,
    };
  },
);

export const fetchClonePublicLair = createAsyncThunk(
  'lairs/fetchClonePublicLair',
  async(args, {getState}) => {
    const state = getState();
    const {source, destination, lairName, workspaceId} = args;
    const fileChildrenNames = selectFilePropById(state, workspaceId, 'children').map(c => selectFilePropById(state, c, 'name'));
    const uniqueLairName = generateUniqueFileName(lairName, fileChildrenNames);
    const res = await fetchJson('/lairs/clone/public', {
      method: 'POST',
      body: JSON.stringify({
        source,
        destination,
        new_name: uniqueLairName,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res;
  },
  // TODO: REQUIRES REFRESH SINCE BE DOESNT SEND BACK FILE DAYA RIGHT NOW. ONLY USED IN PUBLIC READ ME PAGES RIGHT NOW.
);

export const fetchHasPublicEndpoints = createAsyncThunk(
  'lairs/fetchHasPublicEndpoints',
  async(args, {dispatch, getState}) => {
    const {lairId} = args;
    const data = await fetchJson(`/lairs/${lairId}/endpoints-are-private`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    });
    return {
      lairId,
      ...data,
    };
  },
);

export const fetchMakeEndpointsPublic = createAsyncThunk(
  'lairs/fetchMakeEndpointsPublic',
  async(args, {dispatch, getState}) => {
    const {isPublic, lairId} = args;
    const data = await fetchJson(`/lairs/${lairId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        endpoints_are_private: !isPublic,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return {
      lairId,
      ...data,
    };
  },
);

export const runLair = createAsyncThunk(
  'lair/runLair',
  async(args, {dispatch, getState}) => {
    const state = getState();
    const isUpdating = selectFileFocusedIsUpdating(state);
    const lairTriggerRun = selectLairRunIsAvailable(state);
    const id = selectFocusedId(state);
    if (!isUpdating && !lairTriggerRun) {
      dispatch(checkUnsavedLairFileChangesThenInvoke(() => {
        analytics.track(analytics.processManuallyInvokedEvent, {
          type: 'file',
          target: selectFilePropById(state, id, 'name'),
        });
        dispatch(requestRunFile(id));
      }, true));
    } else if (lairTriggerRun) {
      const triggersFileId = selectCurrentTriggersFileId(state);
      const triggersContent = selectFilePropById(state, triggersFileId, 'content');
      const triggersList = triggersContent ? JSON.parse(triggersContent) : [];
      const triggerIndex = triggersList.findIndex(t => t.id === lairTriggerRun);
      await dispatch(checkUnsavedLairFileChangesThenInvoke(async() => {
        const data = await dispatch(fetchRunTrigger(triggerIndex));
        if (!data.error) setTimeout(() => { dispatch(fetchLairProcesses({offset: 0, limit: 20})); }, 1000);
      }, true));
    }
  },
);

export const fetchLairTemplates = createAsyncThunk(
  'lair/fetchLairTemplates',
  async(args, thunkAPI) => {
    const data = await fetchJson('https://neat-orchid-cavern.wayscript.cloud/api/v1/templates', {
      method: 'GET',
    });
    return data;
  },
);

export const lairsSliceDefaultState = {
  view: null,
  isProd: false,
  status: 'idle',
  publicEndpoints: null,
  running: false,
};

export const lairsSlice = createSlice({
  name: 'lairs',
  initialState: {
    ...lairsSliceDefaultState,
  },
  reducers: {
    resetLairs: (state, action) => {
      Object.entries(lairsSliceDefaultState).forEach(entry => {
        state[entry[0]] = entry[1];
      });
    },
    setLairView: (state, action) => {
      state.isProd = false;
      state.view = action.payload;
    },
    setLairIsProd: (state, action) => {
      state.isProd = action.payload;
    },
  },
  extraReducers: {
    [fetchCreateLair.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchCreateLair.fulfilled]: (state, action) => {
      state.status = 'idle';
    },
    [fetchProdLair.fulfilled]: (state, action) => {
      state.isProd = true;
    },
    [runLair.pending]: (state, action) => {
      state.running = true;
    },
    [runLair.fulfilled]: (state, action) => {
      state.running = false;
    },
    [runLair.rejected]: (state, action) => {
      state.running = false;
    },
  },
});

export const {setRunning, setLairView, resetLairs, setLairIsProd} = lairsSlice.actions;

export const checkUnsavedLairFileChangesThenInvoke = (func, disabledDenyRemoveUnsavedTabs) => async(dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    const isAnyLairTabUnsaved = selectIsAnyLairTabUnsaved(state);
    if (isAnyLairTabUnsaved) {
      const success = () => {
        resolve(func());
      };
      const deny = () => {
        const state = getState();
        const tabs = selectLairUnsavedTabIds(state);
        if (!disabledDenyRemoveUnsavedTabs) {
          tabs.forEach(tabId => {
            dispatch(removeUnsavedTabValue(tabId));
          });
        }
        resolve(func());
      };
      if (isElectron) {
        // Electron
        dispatch(saveAllLairChangesPrompt(success, deny));
      } else {
        // Web App
        // showModal
        const lairUnsavedTabsLength = selectLairUnsavedTabsLength(state);
        dispatch(showModal({
          which: 'file_save',
          data: {
            accept: async() => {
              const data = await dispatch(saveAllUnsavedLairFiles());
              const saveAllSuccess = data.reduce((acc, d) => acc && !d.error, true);
              if (saveAllSuccess) {
                resolve(func());
                dispatch(closeModal({which: 'file_save'}));
              } else {
                debugLog('ERROR SAVE LAIR FILES');
                // TODO SAVE UX - UX FOR WHEN A FILE SAVE ERRORS?
                dispatch(updateModalData({
                  which: 'file_save',
                  data: {
                    error: 'something went wrong',
                  },
                }));
              }
            },
            deny: async() => {
              deny();
              dispatch(closeModal({which: 'file_save'}));
            },
            cancel: async() => {
              dispatch(closeModal({which: 'file_save'}));
            },
            acceptText: 'Save All',
            denyText: "Don't Save",
            cancelText: 'Cancel',
            header: `There are unsaved changes to ${lairUnsavedTabsLength} files. Would you like to save your changes?`,
          },
        }));
      }
    } else {
      resolve(func());
    }
  });
};

export const openLair = (lairId, checkUnsaved = true) => async(dispatch, getState) => {
  const state = getState();
  const lairView = selectLairView(state);
  const isProd = selectLairIsProd(state);
  const workspaceName = selectSelectedWorkspaceName(state);
  const lairName = selectFilePropById(state, lairId, 'name');
  const openLairFunc = (skipSet) => {
    dispatch(setFocusFirstLairTab(lairId));
    dispatch(focusProcessTab(null));
    if (!skipSet) {
      dispatch(setLairView(lairId));
      dispatch(terminalClear());
      dispatch(closePreview());
    }
    router.push(`/workspaces/${workspaceName}/lairs/${lairName}/develop`);
  };
  if (lairView !== lairId || isProd) {
    if (checkUnsaved) dispatch(checkUnsavedLairFileChangesThenInvoke(openLairFunc));
    else openLairFunc();
  } else {
    openLairFunc(true);
    router.push(`/workspaces/${workspaceName}/lairs/${lairName}/develop`);
  }
};

export const saveAllLairChangesPrompt = (success = () => {}, deny = () => {}, error = () => {}) => async(dispatch, getState) => {
  const state = getState();
  const lairUnsavedTabsLength = selectLairUnsavedTabsLength(state);
  const {payload} = await window.electron.message.invoke('message', {
    type: 'CLOSE_LAIR_WITH_UNSAVED_CHANGES',
    length: lairUnsavedTabsLength,
  });
  const choice = payload.response;
  if (choice === 0) {
    const data = await dispatch(saveAllUnsavedLairFiles());
    const saveAllSuccess = data.reduce((acc, d) => acc && !d.error, true);
    if (saveAllSuccess) {
      success();
    } else {
      debugLog('ERROR SAVE LAIR FILES');
      // TODO SAVE UX - UX FOR WHEN A FILE SAVE ERRORS?
      error();
    }
  } else if (choice === 1) {
    deny();
  }
};

export const selectLairView = (state) => {
  return state.lairs.view;
};

export const selectLairIsProd = (state) => {
  return state.lairs.isProd;
};

export const selectCurrentLairId = (state) => {
  const isProd = selectLairIsProd(state);
  if (isProd) return selectLairViewProdFileId(state);
  else return selectLairView(state);
};

export const selectLairViewName = (state) => {
  return selectLairViewFile(state)?.name;
};

export const selectIsWorkspaceView = (state) => {
  return !selectLairView(state);
};

export const getLairEndpoint = (trigger, lairId) => {
  const content = trigger && trigger.content && JSON.parse(trigger.content);
  const endpoints = [];
  let endpoint;
  let hasEndpoint;
  content && content.map((trigger, t) => {
    if (TRIGGER_ENDPOINT_TYPES.includes(trigger.type)) {
      hasEndpoint = true;
      endpoint = getTriggerEndpointUrl(lairId, trigger);
      if (endpoint) endpoints.push(endpoint);
    }
  });
  return hasEndpoint ? endpoints[0] : 'n/a';
};

export const selectLairIds = (state) => {
  const lairFiles = selectAllDevLairFiles(state);
  return lairFiles.map(l => l.id);
};

export const selectLairIdsStringified = (state) => {
  return JSON.stringify(selectLairIds(state));
};

export const selectLairRowData = (state, lairId, rowIndex, columns, setEditMode, editMode, dispatch) => {
  const file = selectFileById(state, lairId);

  const renderDisplay = (file, propId) => {
    let display = file[propId];
    let member;
    switch (propId) {
    case 'last_deployed':
      if (!display) display = display === null ? 'never' : 'loading...';
      break;
    case 'manager':
      member = selectWorkspaceTeamMember(state, file.manager_id);
      if (member) {
        member = JSON.parse(member);
        display = {node: memberNameNode(member.firstName || member.email, member.avatar)};
      } else display = 'loading...';
      break;
    default:
      break;
    }
    return display;
  };

  const data = {};
  columns.forEach(c => {
    data[c] = {
      display: renderDisplay(file, c),
    };
  });
  data.useDispatch = true;
  data.onClick = () => openLair(lairId);

  const handleDuplicate = async(e, row) => {
    e.stopPropagation();
    dispatch(fetchDuplicateFile({id: row, isLairRoot: true}));
  };

  const handleRenameFile = async(e, row) => {
    e.stopPropagation();
    setEditMode({
      cell: [row, editableCol],
      value: data[editableCol].display,
    });
  };

  const handleDeleteFile = async(e, row) => {
    e.stopPropagation();
    dispatch(showModal({
      which: 'delete_file',
      data: {id: row, isLair: true},
    }));
  };

  const handleOnChange = e => {
    setEditMode({
      ...editMode,
      value: e.target.value,
      error: e?.target?.value.includes(' ') ? `Will be created as "${e.target.value.replaceAll(' ', '-')}"` : null,
    });
  };

  data.onChange = handleOnChange;

  const handleBlur = async(e, row, col) => {
    const display = data[col.id].display;
    if (display !== e.target.value) {
      // dispatch lair rename
      setEditMode({
        ...editMode,
        fetching: true,
        error: null,
      });
      const data = await dispatch(fetchRenameFile({id: row, name: e.target.value.replaceAll(' ', '-')}));
      if (!data.error) {
        setEditMode();
      } else {
        setEditMode({
          ...editMode,
          fetching: false,
          error: data?.error?.message || 'an error occured',
        });
      }
    } else {
      setEditMode();
    }
  };

  data.onBlur = handleBlur;

  const handleKeyDown = (e, row, col) => {
    if (e.key === 'Enter') handleBlur(e, row, col);
  };

  data.onKeyDown = handleKeyDown;

  const editableCol = 'name';
  data.overflowMenu = {
    title: '',
    items: [
      {
        title: '',
        items: [
          {
            display: 'Duplicate',
            onClick: (e) => handleDuplicate(e, lairId),
            dataCy: 'duplicate-lair',
          },
          {
            display: 'Rename Lair',
            onClick: (e) => handleRenameFile(e, lairId),
          },
          {
            display: 'Delete Lair',
            onClick: (e) => handleDeleteFile(e, lairId),
            dataCy: 'delete-lair',
          },
        ],
      },
    ],
  };

  return data;
};

export const selectLairHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectUsageRowData = (state, id, rowIndex, columns) => {
  const data = {};
  const file = selectFileById(state, id);

  const renderDisplay = (propId) => {
    let display;
    switch (propId) {
    case 'name':
      display = file[propId];
      break;
    case 'storage':
      display = {
        node: <TableUsageBar max={100} value={21.2} color={theme.colors.dark.primary[500]} unit='MB' />,
      };
      break;
    case 'runtime':
      display = {
        node: <TableUsageBar max={100} value={21.2} color={theme.colors.dark.primary[500]} unit='MB' />,
      };
      break;
    default:
      display = {
        node: <></>,
      };
    }
    return display;
  };

  columns.forEach(column => {
    data[column] = {
      display: renderDisplay(column),
    };
  });

  return data;
};

export const selectUsageHeadRowData = (state, columns) => {
  const data = {};

  const renderDisplay = (columnInfo) => {
    let display;
    switch (columnInfo.id) {
    case 'name':
      display = columnInfo.display;
      break;
    case 'storage':
      display = {
        node: <TableUsageBar label={columnInfo.display} max={100} value={21.2} unit='MB' />,
      };
      break;
    case 'runtime':
      display = {
        node: <TableUsageBar label={columnInfo.display} max={100} value={21.2} unit='MB' />,
      };
      break;
    default:
      display = {
        node: <></>,
      };
    }
    return display;
  };

  columns.forEach(column => {
    data[column.id] = {
      ...column,
      display: renderDisplay(column),
    };
  });

  return data;
};

export const selectDeployedLairName = (state, lairId) => {
  if (!lairId) lairId = state.lairs.view;
  return selectFilePropById(state, lairId, 'deployed_lair_name');
};

export const selectLairTriggersWithEndpoints = (state) => {
  // select both current lair and prod version trigger files
  const triggersFileId = selectTriggersFileId(state);
  const prodTriggersFileId = selectProdTriggersFileId(state);
  let triggersContent = selectFilePropById(state, triggersFileId, 'content');
  let prodTriggersContent = selectFilePropById(state, prodTriggersFileId, 'content');

  if (triggersContent) {
    triggersContent = JSON.parse(triggersContent)
      .filter(t => TRIGGER_ENDPOINT_TYPES.includes(t.type));
    triggersContent.forEach(t => { t.environment = 'dev'; });
  }
  if (prodTriggersContent) {
    prodTriggersContent = JSON.parse(prodTriggersContent)
      .filter(t => TRIGGER_ENDPOINT_TYPES.includes(t.type));
    prodTriggersContent.forEach(t => { t.environment = 'prod'; });
  }
  const triggersWithEndpoints = (triggersContent || []).concat(prodTriggersContent || []);
  return JSON.stringify(triggersWithEndpoints);
};

export const selectEndpointsHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectEndpointsTableRowData = (state, triggerId, rowIndex, columns, setView) => {
  const content = JSON.parse(selectLairTriggersWithEndpoints(state));
  const prodId = selectLairViewProdFileId(state);
  const trigger = content[rowIndex];
  const triggersFileId = selectTriggersFileId(state);
  const triggersLoaded = !selectShouldFetchFileContentById(state, triggersFileId);
  const data = {};
  if (!triggersLoaded) return data;
  const lairId = state.lairs.view;

  const renderTrigger = (trigger) => {
    let triggerIcon;
    switch (trigger) {
    case 'cron':
      triggerIcon = <IconClock width={14} height={14} color={theme.colors.warning[500]} />;
      break;
    case 'http':
      triggerIcon = <IconHttp width={14} height={14} />;
      break;
    default:
      triggerIcon = <IconDeploy width={15} height={15} color={theme.colors.dark.secondary[500]} />;
    }
    return triggerIcon;
  };

  columns.forEach(c => {
    const domain = selectFilePropById(state, trigger.environment === 'dev' ? lairId : prodId, 'domain');
    const endpoint = getTriggerEndpointUrl(domain, trigger);
    let display;
    if (c === 'endpoint') {
      display = trigger && TRIGGER_TYPES[trigger.type].endpoint
        ? {endpoint: endpoint}
        : {text: 'n/a'};
    } else if (c === 'environment') {
      display = trigger.environment;
    } else if (c === 'trigger') {
      display = {
        node: <Flex align="center">{renderTrigger(trigger.type)}<Text pl={3}>{trigger.type}</Text></Flex>,
      };
    } else { display = trigger[c]; }
    data[c] = {
      display,
    };
  });
  data.onClick = () => {};
  return data;
};

export default lairsSlice.reducer;
