import {createSlice} from '@reduxjs/toolkit';
import {setPanelLeft} from './PanelLeftSlice';

const initialState = {
  status: 'init',
  height: typeof window === 'undefined'
    ? 25
    : 0.382 * (window.innerHeight / 13),
  minHeight: 5,
  hidden: false,
};

const panelBottomSlice = createSlice({
  name: 'panelBottom',
  initialState,
  reducers: {
    setPanelBottom: (state, action) => {
      Object.entries(action.payload).forEach(entry => {
        const [key, val] = entry;
        state[key] = val;
      });
      state.status = 'idle';
    },
  },
  extraReducers: {
  },
});

export const selectPanels = (state) => {
  return JSON.stringify({
    left: state.panel_left,
    bottom: state.panel_bottom,
  });
};

export const setPanels = (data) => (dispatch, getState) => {
  if (data.left) dispatch(setPanelLeft(data.left));
  if (data.bottom) dispatch(setPanelBottom(data.bottom));
};

export const {
  setPanelBottom,
} = panelBottomSlice.actions;

export default panelBottomSlice.reducer;
