const getConfig = require('next/config');

const config = getConfig && getConfig.default ? getConfig.default() : null;
const defaultConfig = {DEBUG: typeof process !== 'undefined' ? process.env && process.env.NEXT_PUBLIC_DEBUG : 'false'};
const publicRuntimeConfig = config
  ? config.publicRuntimeConfig || defaultConfig
  : defaultConfig;
const DEBUG = publicRuntimeConfig.DEBUG === 'true';

const debugLog = (...args) => {
  // eslint-disable-next-line no-console
  if (DEBUG) console.log(...args);
};

// helpers shared between main and renderer
/**
 *
 * @param {string | object} newMessage
 * @param {string | undefined} currentMessage
 * @returns {string}
 */
const buildErrorMessage = (newMessage, currentMessage) => {
  let errorMessage = currentMessage === undefined ? '' : currentMessage;
  if (typeof newMessage === 'string') errorMessage += (errorMessage ? ', ' : '') + newMessage;
  else if (typeof newMessage === 'object') {
    Object.values(newMessage).forEach(m => {
      errorMessage += (errorMessage ? ', ' : '') + buildErrorMessage(m, currentMessage);
    });
  }
  return errorMessage;
};

// this should always be https://api.wayscript.com/
const wayscriptBackendDomain = 'https://api.wayscript.com/';

// this can be changed to seed the correct domain for self-hosted users
const productionBackendDomain = 'https://api.wayscript.com/';

const defaultBackendDomain = productionBackendDomain;

// this can be changed to seed the correct domain for self-hosted users
const defaultFrontendDomain = 'https://app.wayscript.com/';

exports.buildErrorMessage = buildErrorMessage;
exports.debugLog = debugLog;
exports.productionBackendDomain = productionBackendDomain;
exports.wayscriptBackendDomain = wayscriptBackendDomain;
exports.defaultBackendDomain = defaultBackendDomain;
exports.defaultFrontendDomain = defaultFrontendDomain;
