import {createSlice} from '@reduxjs/toolkit';
import {
  clearFilesSelected,
  fetchFileSave,
  isFileDescendantOfProdFile,
  selectFileById,
  selectFilePathById,
  selectFilePropById,
  selectIsFileInTree,
  setLastSelected,
  upsertFilesSelected,
} from '../files/FilesSlice';
import {
  TAB_DELIM,
  TAB_TYPES,
} from '../../app/constants';
import {selectCurrentLairId, selectLairIsProd, selectLairView, selectLairViewProdFileId} from '../lairs/LairsSlice';
import {closeModal, showModal, updateModalData} from '../modal/ModalSlice';
import {isElectron} from '../../utils/helpers';
import {
  convertEnvVarsStringToObj,
} from './helpers';
import {isFileContentEqual} from '../files/helpers';

const initialState = {
  focused: null,
  tabs: [],
  tabs_added_order: [],
  drag_source: null,
  unsaved_values: {}, // unsaved editor values
  endpoint_preview: {show: false, endpoint: ''},
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    resetEditor: (state, action) => {
      Object.entries(initialState).forEach(entry => {
        state[entry[0]] = entry[1];
      });
    },
    setEditorFocused: (state, action) => {
      let id;
      if (action.payload && typeof action.payload === 'object') {
        id = convertIdToPrefixedId(action.payload.id, action.payload.type, action.payload.lairId || 'global');
      } else {
        id = action.payload;
      }
      state.focused = id;
    },
    setFocusFirstLairTab: (state, action) => {
      const lairId = action.payload;
      let firstLairTab = null;
      for (let i = 0; i < state.tabs.length; i++) {
        const tab = state.tabs[i];
        const tabObj = convertPrefixedIdToObj(tab);
        if (tabObj?.lairId === lairId) firstLairTab = tab;
      }
      state.focused = firstLairTab;
    },
    addTab: (state, action) => {
      let id;
      if (typeof action.payload === 'object') {
        id = convertIdToPrefixedId(action.payload.id, action.payload.type, action.payload.lairId || 'global');
      } else {
        id = action.payload;
      }

      if (!state.tabs.includes(id)) {
        state.tabs = [...state.tabs, id];
        state.tabs_added_order.push(id);
      }
    },
    deleteTab: (state, action) => {
      state.tabs = state.tabs.filter((tab) => tab !== action.payload);
      state.tabs_added_order = state.tabs_added_order.filter((tab) => tab !== action.payload);
    },
    setTabs: (state, action) => {
      state.tabs = action.payload;
    },
    setTabDragSource: (state, action) => {
      state.drag_source = action.payload;
    },
    upsertUnsavedValue: (state, action) => {
      state.unsaved_values[action.payload.id] = action.payload.value;
    },
    removeUnsavedValue: (state, action) => {
      delete state.unsaved_values[action.payload];
    },
    setPreview: (state, action) => {
      state.endpoint_preview.show = true;
      state.endpoint_preview.endpoint = action.payload;
    },
    closePreview: (state, action) => {
      state.endpoint_preview.show = false;
      state.endpoint_preview.endpoint = '';
    },
  },
  extraReducers: {
  },
});

export const {
  setEditorFocused,
  setFocusFirstLairTab,
  addTab,
  deleteTab,
  setTabs,
  setTabDragSource,
  upsertUnsavedValue,
  removeUnsavedValue,
  resetEditor,
  setPreview,
  closePreview,
} = editorSlice.actions;

export const convertIdToPrefixedId = (id, type, lairId) => {
  return lairId + TAB_DELIM + type + TAB_DELIM + id;
};

export const convertPrefixedIdToObj = (id) => {
  const elements = id.split(TAB_DELIM);
  return {
    lairId: elements[0],
    type: elements[1],
    id: elements[2],
  };
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectFocused = (state) => {
  return state.editor.focused;
};

export const selectFocusedId = (state) => {
  const fileFocused = selectFocused(state);
  if (!fileFocused) return null;
  return convertPrefixedIdToObj(fileFocused).id;
};

export const selectFocusedType = (state) => {
  const fileFocused = selectFocused(state);
  if (!fileFocused) return null;
  return convertPrefixedIdToObj(fileFocused).type;
};

export const selectFileFocusedProp = (state, prop) => {
  const id = selectFocusedId(state);
  const type = selectFocusedType(state);
  if (!TAB_TYPES.includes(type)) return null;
  return selectFilePropById(state, id, prop);
};

export const selectFileFocusedPath = (state) => {
  const id = selectFocusedId(state);
  const type = selectFocusedType(state);
  if (id && ['file', 'file-comparison'].includes(type)) return selectFilePathById(state, id);
  else return null;
};

export const selectFileFocusedValue = (state) => {
  const id = selectFocusedId(state);
  return selectFileValueById(state, id);
};

export const selectFileValueByTabId = (state, prefixedId) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return selectFileValueById(state, id);
};

export const selectFileValueById = (state, id) => {
  const unsavedValue = selectUnsavedValueById(state, id);
  if (typeof unsavedValue === 'string') {
    return unsavedValue;
  } else {
    return selectFileFocusedProp(state, 'content');
  }
};

export const selectTabs = (state) => {
  return state.editor.tabs;
};

export const selectLairTabs = (state) => {
  let lairView = selectLairView(state);
  if (!lairView) lairView = 'global';
  const isProd = selectLairIsProd(state);
  if (isProd) {
    lairView = selectLairViewProdFileId(state);
  }
  return state.editor.tabs.filter(tab => convertPrefixedIdToObj(tab).lairId === lairView);
};
export const selectLairTabsStringified = (state) => {
  const tabs = selectLairTabs(state);
  return JSON.stringify(tabs || []);
};

export const selectTabsAddedOrder = (state) => {
  return state.editor.tabs_added_order;
};

export const selectTabsAddedOrderForCurrentLair = (state) => {
  const tabsAddedOrder = state.editor.tabs_added_order;
  const currentLairId = selectCurrentLairId(state);
  return tabsAddedOrder.filter(tabId => {
    const tabObj = convertPrefixedIdToObj(tabId);
    return currentLairId === tabObj.lairId;
  });
};

export const selectTabName = (state, prefixedId) => {
  const {id, type} = convertPrefixedIdToObj(prefixedId);
  if (type === 'file') {
    return selectFilePropById(state, id, 'name');
  } else if (type === 'file-comparison') {
    return `${selectFilePropById(state, id, 'name')} (conflict)`;
  } else if (type === 'settings') {
    return id + ' settings';
  } else if (type === 'triggers') {
    return 'Triggers';
  } else if (type === 'secrets') {
    return 'Secrets';
  } else if (type === 'env') {
    return 'Env';
  }
};

export const selectIsTabInTree = (state, prefixedId) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return selectIsFileInTree(state, id);
};

export const selectTabFilePropById = (state, prefixedId, prop) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return selectFilePropById(state, id, prop);
};

export const selectTabFileId = (prefixedId) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return id;
};

export const selectUnsavedValues = (state) => {
  return state.editor.unsaved_values;
};

export const selectUnsavedValueById = (state, id) => {
  const values = state.editor.unsaved_values;
  return values[id];
};

export const selectIsTabUnsaved = (state, prefixedId) => {
  const fileId = selectTabFileId(prefixedId);
  return selectIsFileUnsaved(state, fileId);
};

export const selectIsFileUnsaved = (state, id) => {
  const unsavedValue = state.editor.unsaved_values[id];
  const file = selectFileById(state, id);
  if (!file) return false;
  const savedValue = file.content;
  return (typeof unsavedValue === 'string' && !isFileContentEqual(unsavedValue, savedValue));
};

export const selectIsAnyTabUnsaved = (state) => {
  return selectAllUnsavedTabIds(state).length !== 0;
};

export const selectIsFocusedTabUnsaved = (state) => {
  const id = selectFocused(state);
  if (!id) return false;
  return selectIsTabUnsaved(state, id);
};

export const selecUnsavedTabsLength = (state) => {
  return selectAllUnsavedTabIds(state).length;
};

export const selectIsAnyLairTabUnsaved = (state) => {
  return selectLairUnsavedTabIds(state).length !== 0;
};

export const selectLairUnsavedTabsLength = (state) => {
  return selectLairUnsavedTabIds(state).length;
};

export const selectAllUnsavedTabIds = (state) => {
  const tabs = selectTabs(state);
  return tabs.filter(tabId => selectIsTabUnsaved(state, tabId));
};

export const selectLairUnsavedTabIds = (state) => {
  const tabs = selectLairTabs(state);
  return tabs.filter(tabId => selectIsTabUnsaved(state, tabId));
};

export const selectAllUnsavedFileIds = (state) => {
  return selectAllUnsavedTabIds(state).map(tabId => convertPrefixedIdToObj(tabId).id);
};

export const selectLairUnsavedFileIds = (state) => {
  return selectLairUnsavedTabIds(state).map(tabId => convertPrefixedIdToObj(tabId).id);
};

export const closeTab = (id, checkUnsaved = true, keepUnsaved = false) => async(dispatch, getState) => {
  const state = getState();
  const isUnsaved = selectIsTabUnsaved(state, id);
  if (isUnsaved && checkUnsaved) {
    if (isElectron) {
      // return new Promise((resolve, reject) => {
      const {payload} = await window.electron.message.invoke('message', {
        type: 'CLOSE_TAB_WITH_UNSAVED_CHANGES',
        id,
      });
      const choice = payload.response;
      if (choice === 0) {
        const state = getState();
        const value = selectFileValueByTabId(state, id);
        const fileId = selectTabFileId(id);
        const data = await dispatch(fetchFileSave({id: fileId, value}));
        if (!data.error) {
          dispatch(closeTab(id, false));
        } else {
          // TODO FILE SAVE UX - WHAT TO DO IF FILE SAVE ERRORS
        }
      } else if (choice === 1) {
        dispatch(closeTab(id, false));
      }
      return id;
    } else {
      const value = selectFileValueByTabId(state, id);
      const fileId = selectTabFileId(id);
      dispatch(showModal({
        which: 'file_save',
        data: {
          accept: async() => {
            const data = await dispatch(fetchFileSave({id: fileId, value}));
            if (!data.error) {
              dispatch(closeTab(id, false));
              dispatch(closeModal({which: 'file_save'}));
            } else {
              dispatch(updateModalData({
                which: 'file_save',
                data: {
                  error: 'something went wrong',
                },
              }));
            }
          },
          deny: () => {
            dispatch(closeTab(id, false));
            dispatch(closeModal({which: 'file_save'}));
          },
          cancel: () => {
            dispatch(closeModal({which: 'file_save'}));
          },
          acceptText: 'Save',
          denyText: "Don't Save",
          cancelText: 'Cancel',
          header: 'There are unsaved changes. Would you like to save your changes?',
        },
      }));
    }
  } else {
    const focused = selectFocused(state);
    dispatch(deleteTab(id));
    if (!keepUnsaved) dispatch(removeUnsavedTabValue(id));
    if (focused === id) {
      const state = getState();
      const tabsAddedOrderForCurrentLair = selectTabsAddedOrderForCurrentLair(state);
      if (tabsAddedOrderForCurrentLair.length === 0) dispatch(setEditorFocused(null));
      else dispatch(setEditorFocused(tabsAddedOrderForCurrentLair[tabsAddedOrderForCurrentLair.length - 1]));
    }
  }
};

export const focusTabByIndex = (index) => (dispatch, getState) => {
  const lairTabs = selectLairTabs(getState());
  if (lairTabs[index]) {
    dispatch(focusTab(lairTabs[index]));
  }
};

export const focusTab = (id) => (dispatch, getState) => {
  const fileId = convertPrefixedIdToObj(id).id;
  dispatch(setEditorFocused(id));
  dispatch(clearFilesSelected());
  dispatch(upsertFilesSelected({key: fileId, value: fileId}));
  dispatch(setLastSelected(fileId));
};

export const selectTabIndexById = (state, id) => {
  const tabs = selectTabs(state);
  return tabs.indexOf(id);
};

export const reOrderTab = (id, targetId) => (dispatch, getState) => {
  const state = getState();
  const tabs = [...selectTabs(state)];
  const tabIndex = tabs.indexOf(id);
  const targetIndex = tabs.indexOf(targetId);
  if (!targetId || !id || id === targetId || tabIndex === -1 || targetIndex === -1) return;
  const isLeft = tabIndex < targetIndex;
  const increment = isLeft ? 1 : -1;
  let i = tabIndex;
  while (isLeft ? i < targetIndex : i > targetIndex) {
    tabs[i] = tabs[i + increment];
    i = i + increment;
  }
  tabs[targetIndex] = id;
  dispatch(setTabs(tabs));
};

export const selectTabDragSource = (state) => {
  return state.editor.drag_source;
};

export const selectFileFocusedIsUpdating = (state) => {
  const statusSave = selectFileFocusedProp(state, 'status_save');
  const statusRun = selectFileFocusedProp(state, 'status_run');
  const statusContent = selectFileFocusedProp(state, 'status_content');
  return (
    (statusSave && statusSave !== 'idle') ||
    (statusRun && statusRun !== 'idle') ||
    (statusContent !== 'idle')
  );
};

export const removeUnsavedTabValue = (tabId) => (dispatch, getState) => {
  const id = selectTabFileId(tabId);
  dispatch(removeUnsavedValue(id));
};

export const saveAllUnsavedFiles = () => (dispatch, getState) => {
  const state = getState();
  const unsavedFileIds = selectAllUnsavedFileIds(state);
  return Promise.all(unsavedFileIds.map((id) => {
    const value = selectFileValueById(state, id);
    return dispatch(fetchFileSave({id, value}));
  }));
};

export const saveAllUnsavedLairFiles = () => (dispatch, getState) => {
  const state = getState();
  const unsavedFileIds = selectLairUnsavedFileIds(state);
  return Promise.all(unsavedFileIds.map((id) => {
    const value = selectFileValueById(state, id);
    return dispatch(fetchFileSave({id, value}));
  }));
};

export const fetchSaveFocusedFile = () => async(dispatch, getState) => {
  const state = getState();
  const focusedFileId = selectFocusedId(state);
  const focusedValue = selectFileFocusedValue(state);
  const data = await dispatch(fetchFileSave({id: focusedFileId, value: focusedValue}));
  return data;
};

export const selectVarTableRowIds = (state) => {
  const fileFocusedValue = selectFileFocusedValue(state);
  const varEntries = convertEnvVarsStringToObj(fileFocusedValue);
  if (!varEntries) return null;
  return JSON.stringify(varEntries.map((c, i) => `var-${i}`)); // use indices to identify variables
};

export const selectVarTableRowData = (state, varId, rowIndex, columns, setView, isSecrets) => {
  const fileFocusedValue = selectFileFocusedValue(state);
  const varEntries = convertEnvVarsStringToObj(fileFocusedValue);
  if (!varEntries) return null;
  const entry = varEntries[rowIndex];
  const passwordStyle = '*'.repeat(15);
  const data = {};
  columns.forEach(c => {
    let display;
    if (c === 'key') display = entry[0];
    else if (c === 'value') display = passwordStyle;
    data[c] = {
      display,
    };
  });
  if (!isSecrets) data.onClick = () => setView(`var-${rowIndex}`);
  return data;
};

export const selectVarTableHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const isTabDescendantOfProdFile = (state, prefixedId) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return isFileDescendantOfProdFile(state, id);
};

export const selectPreviewShow = (state) => {
  return state.editor.endpoint_preview.show;
};

export const selectPreviewEndpoint = (state) => {
  return state.editor.endpoint_preview.endpoint;
};

export default editorSlice.reducer;
