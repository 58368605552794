import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  view: 'lairs',
  lairView: 'Readme',
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeView: (state, action) => {
      state.view = action.payload;
    },
    setHomeLairView: (state, action) => {
      state.lairView = action.payload;
    },
  },
});

export const {setHomeView, setHomeLairView} = homeSlice.actions;

export const selectHomeView = (state) => {
  return state.home.view;
};
export const selectHomeLairView = (state) => {
  return state.home.lairView;
};

export default homeSlice.reducer;
