import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  resizeId: 0,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    incrementResizeId: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based on those changes
      state.resizeId += 1;
    },
  },
});

export const {
  incrementResizeId,
} = layoutSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectResizeId = (state) => {
  return state.layout.resizeId;
};

export default layoutSlice.reducer;
