import React from 'react';
import fetchJson from '../lib/fetchJson';
import fetchStream from '../lib/fetchStream';
import {
  Flex,
} from '@chakra-ui/react';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  selectFileById,
  getFileDescendantIds,
  selectTreeFiles,
  pullChanges,
  resetFiles,
  selectAllLairFiles,
} from '../features/files/FilesSlice';
import {resetLairs, checkUnsavedLairFileChangesThenInvoke} from '../features/lairs/LairsSlice';
import {resetEditor, setTabDragSource} from '../features/editor/EditorSlice';
import {resetProcesses} from '../features/processes/ProcessesSlice';
import NextRouter from 'next/router';
import {v4} from 'uuid';
import {isElectron, setStoredItem} from '../utils/helpers';
import UserImage from '../components/UserImage';
import SelectorUserOption from '../components/SelectorUserOption';
import {FEATURES} from '../features/flags';
import {buildErrorMessage} from '../../helpers';
import {encodeFilePath, readStreamAsText} from '../features/files/helpers';
import * as analytics from '../utils/analytics';
const workspacesAdapter = createEntityAdapter();

export const fetchUsersWorkspaces = createAsyncThunk(
  'workspaces/fetchByUserId',
  async(userId, thunkAPI) => {
    const data = await fetchJson(`/users/${userId}/workspaces`, {});
    const payload = {};
    payload.workspaceData = data.map(workspace => {
      return preProcessWorkspaceData(workspace);
    });
    payload.workspaceFiles = data.map(workspace => {
      return preProcessWorkspaceFile(workspace);
    });
    return payload;
  },
);

export const loadUsersWorkspaces = createAsyncThunk(
  'workspaces/loadFromFiles',
  async(args, {getState, dispatch, rejectWithValue}) => {
    const response = await window.electron.message.invoke('message', {
      type: 'LOAD_WORKSPACES',
      path: getState().workspaces.directory,
      files: selectTreeFiles(getState()),
    });
    let {payload, error} = response;
    if (error) return rejectWithValue(error);
    let {files, workspaces, directory} = payload;
    files = Object.values(files);
    workspaces = workspaces.map(preProcessWorkspaceData);
    const currentWorkspaces = getState().workspaces.entities;
    workspaces = workspaces.map(workspace => {
      const match = currentWorkspaces[workspace.id];
      if (match) {
        workspace.status_flags = match.status_flags;
      }
      return workspace;
    });
    payload = {files, workspaces, directory};
    dispatch(setTabDragSource(null));
    return payload;
  },
);

export const setSelectedWorkspace = createAsyncThunk(
  'workspaces/setSelected',
  async(selected, {getState, dispatch}) => {
    await setStoredItem('workspace', selected || '');
    const openWorkspaceFunc = () => {
      if (selected && getState().workspaces.selected !== selected) {
        dispatch(resetLairs());
        dispatch(resetEditor());
        dispatch(resetProcesses());
      }
    };
    await dispatch(checkUnsavedLairFileChangesThenInvoke(openWorkspaceFunc));
    return selected;
  },
);

export const createHomeDirectory = createAsyncThunk(
  'workspaces/createHome',
  async(dirPath, {dispatch, rejectWithValue}) => {
    const {payload, error} = await window.electron.message.invoke('message', {
      type: 'CREATE_HOME',
      dirPath,
    });
    if (payload === 'valid') await dispatch(loadUsersWorkspaces());
    if (error) return rejectWithValue(error);
    return dirPath;
  },
);

export const setHomeDirectory = createAsyncThunk(
  'workspaces/setHome',
  async(args, {rejectWithValue}) => {
    const response = await window.electron.message.invoke('message', {
      type: 'GET_HOME_DIRECTORY',
    });
    const {payload, error} = response;
    if (error) return rejectWithValue(error);
    return payload;
  },
);

export const preProcessWorkspaceData = (workspace, flags = FEATURES) => {
  const workspaceData = {
    id: workspace.id,
    name: workspace.name,
    owner: workspace.owner_id,
    url: 'workspaceURL',
    flags,
    status_flags: 'init',
  };
  return workspaceData;
};

export const preProcessWorkspaceFile = (workspace) => {
  const workspaceFile = {
    id: workspace.id,
    name: workspace.name,
    children: [],
    is_workspace_root: true,
  };
  return workspaceFile;
};

export const fetchUntilResponseCondition = async(
  requestFn,
  requestArgs,
  condition = () => false,
  conditionIsAsync = false,
  intervalDuration = 1000,
  maxRetries = 5,
) => {
  let numRetries = 0;
  const payload = {success: false, error: ''};
  let data;
  await new Promise((resolve, reject) => {
    const retryInterval = setInterval(async() => {
      if (numRetries < maxRetries) {
        data = await requestFn(...requestArgs);
        const conditionMet = conditionIsAsync
          ? await condition(data)
          : condition(data);
        if (conditionMet) {
          payload.success = true;
          clearInterval(retryInterval);
          resolve();
        }
        numRetries++;
      } else {
        payload.error = data.error || 'Request condition not met';
        reject(payload.error);
      }
    }, intervalDuration);
  });
  return payload;
};

export const fetchCreateWorkspace = createAsyncThunk(
  'workspaces/fetchCreateWorkspace',
  async(name, {dispatch}) => {
    analytics.track(analytics.workspaceCreatedEvent);
    const workspace = await fetchJson('/workspaces', {
      method: 'POST',
      body: JSON.stringify({
        name: name,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    let payload;
    if (isElectron) {
      // TODO race condition with pulling down files directly after creating workspace
      // wait until workspace metadata file is available

      const filePath = encodeFilePath(`${name}/.wayscript/metadata`);
      const waitData = await fetchUntilResponseCondition(
        fetchStream,
        [`/files/${filePath}?contents=true`, {method: 'GET'}],
        async(data) => {
          if (typeof data !== 'string') data = await readStreamAsText(data);
          try {
            data = JSON.parse(data);
            return true;
          } catch (error) {
            return false;
          }
        },
        true,
      );
      if (waitData.success) await dispatch(pullChanges());
    } else {
      payload = {
        workspaceData: preProcessWorkspaceData(workspace),
        workspaceFile: preProcessWorkspaceFile(workspace),
      };
    }

    NextRouter.push(`/workspaces/${name}/lairs/`);
    return payload;
  },
);

export const fetchUpdateWorkspace = createAsyncThunk(
  'workspaces/fetchUpdateWorkspace',
  async(args, thunkAPI) => {
    const id = args.id;
    const workspace = await fetchJson(`/workspaces/${id}`, {
      method: 'POST',
      body: JSON.stringify({
        workspace_name: args.name,
        workspace_url: args.url,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return workspace;
  },
);

export const fetchWorkspaceData = createAsyncThunk(
  'workspaces/fetchWorkspaceData',
  async(id) => {
    let workspace = await fetchJson(`/workspaces/${id}`, {
      method: 'GET',
    });
    workspace = preProcessWorkspaceData(workspace);
    return workspace;
  },
);

export const fetchDeleteWorkspace = createAsyncThunk(
  'workspaces/fetchDeleteWorkspace',
  async(id, thunkAPI) => {
    analytics.track(analytics.workspaceDeletedEvent);
    const state = thunkAPI.getState();
    const fileIds = getFileDescendantIds(state, id);
    const payload = {fileIds};
    const workspaceFileName = selectFileById(state, id).name;
    let data;
    try {
      data = await fetchJson(`/workspaces/${id}`, {method: 'DELETE'});
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
    if (isElectron) {
      data = await window.electron.message.invoke('message', {
        type: 'DELETE_FILE',
        id,
        path: `${state.workspaces.directory}/${workspaceFileName}`,
      });
      if (data.error) thunkAPI.rejectWithValue(data.error);
      await window.electron.message.invoke('message', {
        type: 'CLEAR_CACHE',
      });
    }
    if (!data.error) NextRouter.push('/setup');
    return payload;
  },
);

export const fetchWorkspaceMembers = createAsyncThunk(
  'workspaces/fetchWorkspaceMembers',
  async(workspaceId, thunkAPI) => {
    workspaceId = workspaceId || selectSelectedWorkspaceId(thunkAPI.getState());
    const members = await fetchJson(`/workspaces/${workspaceId}/users`, {method: 'GET'});
    const invites = await fetchJson(`/workspaces/${workspaceId}/invite`, {method: 'GET'});
    const pendingInvites = invites.filter(invite => !invite.redeemed_date);
    const workspaceMembers = members.concat(pendingInvites);
    const membersObj = workspaceMembers.map(member => {
      return preProcessWorkspaceMembers(member);
    });
    return membersObj;
  },
);

export const preProcessWorkspaceMembers = (member) => {
  const memberObj = {
    id: member.id,
    avatar: member.avatar,
    firstName: member.first_name,
    lastName: member.last_name,
    email: member.email,
    createdDate: member.created_date,
  };
  return memberObj;
};

export const fetchAddWorkspaceMember = createAsyncThunk(
  'workspaces/fetchAddWorkspaceMember',
  async(args, thunkAPI) => {
    const {id, email, workspaceId} = args;
    analytics.track(analytics.inviteSentEvent);
    const data = await fetchJson(`/workspaces/${workspaceId}/invite`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        sending_user_id: id,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchDeleteWorkspaceMember = createAsyncThunk(
  'workspaces/fetchDeleteWorkspaceMember',
  async(args, thunkAPI) => {
    const data = await fetchJson(`/workspaces/${args.workspaceId}/users`, {
      method: 'DELETE',
      body: JSON.stringify({
        user_id: args.userId,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    if (data.error) thunkAPI.rejectWithValue(data.error);
    return data;
  },
);

export const fetchDeleteWorkspaceMemberInvite = createAsyncThunk(
  'workspaces/fetchDeleteWorkspaceMemberInvite',
  async(args, thunkAPI) => {
    const data = await fetchJson(`/workspaces/${args.workspaceId}/invite`, {
      method: 'DELETE',
      body: JSON.stringify({
        email: args.email,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchUpdateWorkspaceMember = createAsyncThunk(
  'workspaces/fetchUpdateWorkspaceMember',
  async(args, thunkAPI) => {
    const {workspaceId, userId, role} = args;
    const data = await fetchJson(`/workspaces/${workspaceId}/users`, {
      method: 'PUT',
      body: JSON.stringify({
        user_id: userId,
        role,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const resetWorkspaces = createAsyncThunk(
  'workspaces/resetWorkspaces',
  async(args, {dispatch}) => {
    dispatch(resetLairs());
    dispatch(resetEditor());
    dispatch(resetProcesses());
    dispatch(resetFiles());
    dispatch(resetState());
  },
);

export const fetchLeaveWorkspace = createAsyncThunk(
  'workspaces/fetchLeaveWorkspace',
  async(args, thunkAPI) => {
    const {userId, workspaceId} = args;
    const data = await fetchJson(`/users/${userId}/leave`, {
      method: 'POST',
      body: JSON.stringify({
        workspace_id: workspaceId,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    if (isElectron) {
      const state = thunkAPI.getState();
      const workspaceFileName = selectFileById(state, workspaceId).name;
      const {error} = await window.electron.message.invoke('message', {
        type: 'DELETE_FILE',
        id: workspaceId,
        path: `${state.workspaces.directory}/${workspaceFileName}`,
      });
      if (error) thunkAPI.rejectWithValue(error);
      await window.electron.message.invoke('message', {
        type: 'CLEAR_CACHE',
      });
    }
    if (!data.error) NextRouter.push('/setup');
    return data;
  },
);

export const fetchUpdateWorkspaceBilling = createAsyncThunk(
  'workspaces/fetchUpdateWorkspaceBilling',
  async(args, thunkAPI) => {
    const {id, cardInfo} = args;
    const data = await fetchJson(`/workspaces/${id}/billing`, {
      method: 'PUT',
      body: JSON.stringify({
        cardInfo: cardInfo,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchUpgradeWorkspaceBilling = createAsyncThunk(
  'workspaces/fetchUpgradeWorkspaceBilling',
  async(arg, thunkAPI) => {
    const {seats, id} = arg;
    const data = await fetchJson(`/workspaces/${id}/upgrade`, {
      method: 'PUT',
      body: JSON.stringify({
        seats: seats,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchBoostWorkspace = createAsyncThunk(
  'workspaces/fetchBoostWorkspace',
  async(arg, thunkAPI) => {
    const {id, boosts} = arg;
    const data = await fetchJson(`/workspaces/${id}/boost`, {
      method: 'PUT',
      body: JSON.stringify({boosts}),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const preprocessWorkspaceUserGroups = (group) => {
  const groupObj = {
    id: group.id,
    name: group.name,
  };
  return groupObj;
};

export const fetchWorkspaceUserGroups = createAsyncThunk(
  'workspaces/fetchWorkspaceUserGroups',
  async(args, {dispatch, getState}) => {
    const workspaceId = args?.workspaceId || selectSelectedWorkspaceId(getState());
    const groups = await fetchJson(`/workspaces/${workspaceId}/user-groups`, {
      method: 'GET',
    });
    dispatch(setWorkspaceGroups(groups));
    groups.forEach(group => dispatch(fetchUserGroupMembersById(group.id)));
  },
);

export const fetchUserGroupMembersById = createAsyncThunk(
  'workspaces/fetchUserGroupMembersById',
  async(groupId) => {
    const groupMemberData = await fetchJson(`/user-groups/${groupId}/users`, {
      method: 'GET',
    });
    return groupMemberData;
  },
);

export const fetchAddGroupMember = createAsyncThunk(
  'workspaces/fetchAddGroupMember',
  async(args, thunkAPI) => {
    const {groupId, newMembers} = args;
    const data = await fetchJson(`/user-groups/${groupId}/users`, {
      method: 'PUT',
      body: JSON.stringify({
        user_ids: newMembers,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchDeleteGroupMember = createAsyncThunk(
  'workspaces/fetchDeleteGroupMember',
  async(args, thunkAPI) => {
    const {groupId, deletedMembers} = args;
    const data = await fetchJson(`/user-groups/${groupId}/users`, {
      method: 'DELETE',
      body: JSON.stringify({
        user_ids: deletedMembers,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchAddWorkspaceUserGroup = createAsyncThunk(
  'workspaces/fetchAddWorkspaceUserGroup',
  async(args, {dispatch, getState, rejectWithValue}) => {
    analytics.track(analytics.groupCreatedEvent);
    const {name, workspaceId, members} = args;
    const data = await fetchJson(`/workspaces/${workspaceId}/user-groups`, {
      method: 'POST',
      body: JSON.stringify({
        name,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    if (data.error) rejectWithValue(data.error);

    const addUser = members.length > 0 && await dispatch(fetchAddGroupMember({groupId: data.id, newMembers: members}));
    if (addUser.error) return rejectWithValue(addUser.error);
    data.members = [data.owner_id, ...members];
    return data;
  },
);

export const fetchDeleteWorkspaceUserGroup = createAsyncThunk(
  'workspaces/fetchDeleteUserGroup',
  async(args, thunkAPI) => {
    const {groupId} = args;
    const data = await fetchJson(`/user-groups/${groupId}`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchUpdateWorkspaceUserGroup = createAsyncThunk(
  'workspaces/fetchUpdateWorkspaceUserGroup',
  async(args, {dispatch, getState, rejectWithValue}) => {
    const {name, members, groupId, oldMembers} = args;
    const data = await fetchJson(`/user-groups/${groupId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        name,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    if (data.error) rejectWithValue(buildErrorMessage(data.error));

    const newMembers = [];
    const deletedMembers = [];
    members.forEach(member => { if (!oldMembers.includes(member)) newMembers.push(member); });
    oldMembers.forEach(member => { if (!members.includes(member)) deletedMembers.push(member); });
    const addUser = newMembers.length > 0 && await dispatch(fetchAddGroupMember({groupId, newMembers}));
    if (addUser.error) return rejectWithValue(addUser.error);
    const deleteUser = deletedMembers.length > 0 && await dispatch(fetchDeleteGroupMember({groupId, deletedMembers}));
    if (deleteUser.error) return rejectWithValue(deleteUser.error);

    return data;
  },
);

export const fetchWorkspaceFeatureFlags = createAsyncThunk(
  'workspaces/features',
  async(workspaceId, {getState}) => {
    const data = await fetchJson(`/workspaces/${workspaceId}/feature-flags`, {
      method: 'GET',
    });
    return data;
  },
);

const initialState = workspacesAdapter.getInitialState({
  status: 'init',
  selected: null,
  directory: null, // electron only
});

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.entries(initialState).forEach(entry => {
        state[entry[0]] = entry[1];
      });
    },
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    setDirectory: (state, action) => {
      state.directory = action.payload;
    },
    workspaceAdded: workspacesAdapter.addOne,
    workspacesLoaded: (state, action) => {
      // Or, call them as "mutating" helpers in a case reducer
      workspacesAdapter.setAll(state, action.payload.workspaces);
      state.status = 'idle';
    },
    workspacesChangeStatus: (state, action) => {
      state.status = action.payload;
    },
    setWorkspaceGroups: (state, action) => {
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          groups: action.payload,
        },
      });
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [setSelectedWorkspace.pending]: (state, action) => {
    },
    [setSelectedWorkspace.fulfilled]: (state, action) => {
      state.selected = action.payload;
      if (!action.payload) {
        state.status = 'select';
      }
    },
    [fetchUsersWorkspaces.fulfilled]: (state, action) => {
      workspacesAdapter.setAll(state, action.payload.workspaceData);
      state.status = 'idle';
    },
    [fetchUsersWorkspaces.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchWorkspaceData.fulfilled]: (state, action) => {
      const {payload} = action;
      const {id, owner} = payload;
      const changes = {owner};
      if (FEATURES.pricing) {
        changes.billing = {
          tier: 1,
          runtimeLimit: 1000,
          storageLimit: 1000,
          trialExpirationDate: '11/1/2021',
          billingRenewalDate: '10/1/2021',
          seats: 2,
          boosts: 0,
          inTrial: false,
          billingHistory: [{
            date: '1/1/2021',
            seats: 2,
            boosts: 1,
            total: 200,
          }],
          paymentMethod: {
            cardType: 'mastercard',
            cardDigits: '1234',
          },
        };
      }
      workspacesAdapter.updateOne(state, {id, changes});
    },
    [fetchCreateWorkspace.fulfilled]: (state, action) => {
      if (action.payload) { // created from web app
        workspacesAdapter.addOne(state, action.payload.workspaceData);
        state.selected = action.payload.workspaceData.id;
      }
    },
    [fetchUpdateWorkspace.fulfilled]: (state, action) => {
      const {name, url} = action.meta.arg;
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          name: name,
          url: url,
        },
      });
    },
    [fetchDeleteWorkspace.fulfilled]: (state, action) => {
      const id = action.meta.arg;
      workspacesAdapter.removeOne(state, id);
      state.selected = null;
      state.status = 'init';
    },
    [fetchLeaveWorkspace.fulfilled]: (state, action) => {
      const id = action.meta.arg.workspaceId;
      workspacesAdapter.removeOne(state, id);
      state.selected = null;
      state.status = 'init';
    },
    [loadUsersWorkspaces.fulfilled]: (state, action) => {
      const newWorkspaces = action.payload.workspaces.map(workspace => {
        const match = state.entities[workspace.id];
        if (match) {
          workspace = {
            ...match,
            workspace,
          };
        }
        return workspace;
      });
      workspacesAdapter.setAll(state, newWorkspaces);
      state.status = 'idle';
      state.directory = action.payload.directory || state.directory;
    },
    [loadUsersWorkspaces.pending]: (state, action) => {
      state.status = 'pending';
    },
    [createHomeDirectory.fulfilled]: (state, action) => {
      state.directory = action.payload || state.directory;
    },
    [createHomeDirectory.rejected]: (state, action) => {
      state.status = 'idle';
    },
    [createHomeDirectory.pending]: (state, action) => {
      state.status = 'pending';
    },
    [setHomeDirectory.fulfilled]: (state, action) => {
      state.directory = action.payload;
    },
    [fetchWorkspaceMembers.fulfilled]: (state, action) => {
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          members: action.payload,
        },
      });
    },
    [fetchAddWorkspaceMember.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          members: [
            ...workspace.members,
            {
              id: v4(),
              email: action.meta.arg.email,
            },
          ],
        },
      });
    },
    [fetchWorkspaceFeatureFlags.pending]: (state, action) => {
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {status_flags: 'pending'},
      });
    },
    [fetchWorkspaceFeatureFlags.fulfilled]: (state, action) => {
      const {flags} = state.entities[state.selected];
      const newFlags = {...flags, ...action.payload};
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {flags: newFlags, status_flags: 'idle'},
      });
    },
    [fetchDeleteWorkspaceMember.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          members: workspace.members.filter(member => member.id !== action.meta.arg.userId),
          groups: workspace?.groups.map(group => {
            return {
              ...group,
              members: group.members.filter(id => id !== action.meta.arg.userId),
            };
          }),
        },
      });
    },
    [fetchDeleteWorkspaceMemberInvite.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          members: workspace.members.filter(member => member.email !== action.meta.arg.email),
        },
      });
    },
    [fetchUpdateWorkspaceMember.fulfilled]: (state, action) => {
      const {userId, workspaceId, role} = action.meta.arg;
      const workspace = state.entities[workspaceId];
      let groups = workspace.groups;
      const nameMap = {
        dev: 'Editor',
        admin: 'Admin',
      };
      groups = groups.map(group => {
        if (Object.keys(nameMap).includes(group.name)) {
          if (nameMap[group.name] === role) { // add member to group
            group.members = group.members.concat(userId);
          } else { // remove member from group
            group.members = group.members.filter(memberId => memberId !== userId);
          }
        }
        return group;
      });
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: groups,
        },
      });
    },
    [fetchUpdateWorkspaceBilling.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          // update payment method
        },
      });
    },
    [fetchUpdateWorkspaceBilling.rejected]: (state, action) => {
      // TODO remove when project finished
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          // update payment method
        },
      });
    },
    [fetchUpgradeWorkspaceBilling.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          billing: {
            ...workspace.billing,
            inTrial: false,
          },
        },
      });
    },
    [fetchUpgradeWorkspaceBilling.rejected]: (state, action) => {
      // TODO remove when project finished
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          billing: {
            ...workspace.billing,
            inTrial: false,
          },
        },
      });
    },
    [fetchBoostWorkspace.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          billing: {
            ...workspace.billing,
            boosts: action.meta.arg.boosts,
          },
        },
      });
    },
    [fetchBoostWorkspace.rejected]: (state, action) => {
      // TODO remove when project finished
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          billing: {
            ...workspace.billing,
            boosts: action.meta.arg.boosts,
          },
        },
      });
    },
    [fetchUserGroupMembersById.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: workspace.groups.map(group => {
            if (group.id === action.meta.arg) {
              group.members = action.payload.map(member => member.id);
              return group;
            } else return group;
          }),
        },
      });
    },
    [fetchAddGroupMember.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: workspace.groups.map(group => {
            if (group.id === action.meta.arg.groupId) {
              group.members = group.members.concat(action.meta.arg.newMembers);
              return group;
            } else return group;
          }),
        },
      });
    },
    [fetchDeleteGroupMember.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: workspace.groups.map(group => {
            if (group.id === action.meta.arg.groupId) {
              const membersToDelete = new Set(action.meta.arg.deletedMembers);
              group.members = group.members.filter(m => !membersToDelete.has(m));
              return group;
            } else return group;
          }),
        },
      });
    },
    [fetchAddWorkspaceUserGroup.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: [
            ...workspace.groups,
            action.payload,
          ],
        },
      });
    },
    [fetchDeleteWorkspaceUserGroup.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          groups: workspace.groups.filter(group => group.id !== action.meta.arg.groupId),
        },
      });
    },
    [fetchUpdateWorkspaceUserGroup.fulfilled]: (state, action) => {
      const workspace = state.entities[state.selected];
      workspacesAdapter.updateOne(state, {
        id: state.selected,
        changes: {
          groups: workspace.groups.map(group => {
            if (group.id === action.meta.arg.groupId) {
              group.name = action.meta.arg.name;
              return group;
            } else return group;
          }),
        },
      });
    },
  },
});

export const {
  workspacesAdded,
  workspacesLoaded,
  workspacesChangeStatus,
  setDirectory,
  resetState,
  setWorkspaceGroups,
} = workspacesSlice.actions;

const {selectAll, selectById} = workspacesAdapter.getSelectors((state) => state.workspaces);
export {selectAll as selectAllWorkspaces, selectById as selectWorkspaceById};

export const selectSelectedWorkspaceId = (state) => {
  return state.workspaces && state.workspaces.selected;
};

export const selectSelectedWorkspacePropById = (state, prop) => {
  const id = state.workspaces.selected;
  if (!id) return null;
  const workspace = selectById(state, id);
  if (!workspace) return null;
  return workspace[prop];
};

export const selectSelectedWorkspaceFeatureFlag = (state, flagName) => {
  const id = state.workspaces.selected;
  if (!id) return null;
  const workspace = selectById(state, id);
  if (!workspace) return null;
  const {flags} = workspace;
  if (!flags) return null;
  return flags[flagName];
};

export const selectAllWorkspaceIDsStringified = (state) => {
  const workspaces = selectAll(state);
  return JSON.stringify(workspaces.map(w => w.id));
};

export const selectSelectedWorkspaceName = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace && workspace.name;
};

export const selectSelectedWorkspace = (state) => {
  const selectedWorkspaceId = selectSelectedWorkspaceId(state);
  return selectedWorkspaceId && selectById(state, selectedWorkspaceId);
};

export const selectSelectedWorkspaceGroups = (state) => {
  const workspace = selectSelectedWorkspace(state);
  if (!workspace.groups) return null;
  return JSON.stringify(workspace.groups);
};

export const selectSelectedWorkspaceMembers = (state) => {
  const workspace = selectSelectedWorkspace(state);
  if (!workspace.members) return null;
  return workspace.members;
};

export const selectSelectedWorkspaceMembersStringified = (state) => {
  const members = selectSelectedWorkspaceMembers(state);
  if (!members) return null;
  return JSON.stringify(members);
};

export const memberNameNode = (name, img) => {
  return <span style={{display: 'flex', alignItems: 'center'}}>
    <UserImage
      img={img}
      name={name}
      readOnly={true}
      size={'sm'} />
    <span style={{marginLeft: '.4rem'}}>{name}</span>
  </span>;
};

export const selectMembersTableRowIds = (state) => {
  const workspace = selectSelectedWorkspace(state);
  const members = workspace.members;
  if (!members) return null;
  return JSON.stringify(members.map((c, i) => c.id));
};

export const selectMembersTableRowData = (state, memberId, rowIndex, columns, setView) => {
  const workspace = selectSelectedWorkspace(state);
  const members = workspace.members;
  if (!members) return null;
  const member = members[rowIndex];
  const data = {};
  columns.forEach(c => {
    let display;
    if (c === 'firstName') display = {node: memberNameNode(member.firstName ? member.firstName : member.email, member.avatar)};
    else if (c === 'email') display = member.email;
    else if (c === 'role') {
      let role = selectWorkspaceMemberRole(state, workspace.id, memberId);
      if (!FEATURES.memberRoles && role && role !== 'Pending') role = 'Active';
      const color = !role || role === 'Pending' ? '#EDBA3B' : 'white';
      display = {text: role || 'Loading...', color};
    }
    data[c] = {
      display,
    };
  });
  data.onClick = () => setView(member.id);
  return data;
};

export const selectMembersTableHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectWorkspaceTeamMember = (state, memberId) => {
  if (!memberId) return null;
  const workspace = selectSelectedWorkspace(state);
  const members = workspace?.members;
  if (!members) return null;
  const selectedMember = members.filter(member => {
    return member.id === memberId;
  });
  return JSON.stringify(selectedMember[0]);
};

export const selectIsWorkspaceOwner = (state, id) => {
  const workspace = selectSelectedWorkspace(state);
  const owner = workspace?.owner;
  return owner === id;
};

export const selectWorkspaceRuntimeUsed = (state) => {
  return selectAllLairFiles(state).reduce((acc, currentValue) => acc + currentValue.runtimeUsed, 0);
};

export const selectWorkspaceRuntimeLimit = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.billing && workspace.billing.runtimeLimit;
};

export const selectWorkspaceStorageUsed = (state) => {
  return selectAllLairFiles(state).reduce((acc, currentValue) => acc + currentValue.storageUsed, 0);
};

export const selectWorkspaceStorageLimit = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.billing && workspace.billing.storageLimit;
};

export const selectWorkspaceTrialEndDate = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.billing && workspace.billing.trialExpirationDate;
};

export const selectIsInTrial = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.billing && workspace.billing.inTrial;
};

export const selectStorageLimitReached = (state) => {
  return selectWorkspaceStorageUsed(state) >= selectWorkspaceStorageLimit(state);
};

export const selectRuntimeLimitReached = (state) => {
  return selectWorkspaceRuntimeUsed(state) >= selectWorkspaceRuntimeLimit(state);
};

export const selectPaymentMethod = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.billing && JSON.stringify(workspace.billing.paymentMethod);
};

export const selectBillingDate = (state) => {
  const workspace = selectSelectedWorkspace(state);
  const inTrial = workspace?.billing?.inTrial;
  return workspace?.billing && inTrial ? workspace.billing.trialExpirationDate : workspace?.billing?.billingRenewalDate;
};

export const selectBillingBoosts = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace?.billing?.boosts;
};

export const selectUserGroups = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace.groups;
};

export const selectUserGroupsStringified = (state) => JSON.stringify(selectUserGroups(state));

export const selectWorkspaceGroup = (state, groupView) => {
  if (!groupView) return null;
  const workspace = selectSelectedWorkspace(state);
  const groups = workspace?.groups;
  if (!groups) return null;
  const selectedGroup = groups.filter(group => {
    return group.id === groupView;
  });
  return selectedGroup[0];
};

export const selectWorkspaceGroupStringified = (state, groupView) => {
  const group = selectWorkspaceGroup(state, groupView);
  if (group) return JSON.stringify(group);
  else return null;
};

export const selectUserGroupsTableRowIds = (state) => {
  const workspace = selectSelectedWorkspace(state);
  let groups = workspace.groups;
  if (!groups) return null;
  if (!FEATURES.memberRoles) groups = groups.filter(group => !['admin', 'dev'].includes(group.name));
  return JSON.stringify(groups.map((g, i) => g.id));
};

export const selectUserGroupsHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectUserGroupsTableRowData = (state, groupId, rowIndex, columns, setView) => {
  const workspace = selectSelectedWorkspace(state);
  const groups = workspace.groups;
  const members = workspace.members;
  if (!groups) return null;
  const group = groups.filter(group => group.id === groupId)[0];
  const data = {};
  const membersNode = (membersList, excludeId) => {
    if (!membersList || membersList.length === 0) return 'none';
    const nodeList = [];
    membersList.map((memberId, i) => {
      const match = members.find(currentMember => memberId !== excludeId && currentMember.id === memberId);
      if (match) {
        if (nodeList.length) nodeList.push(<span>,&nbsp;</span>);
        nodeList.push(SelectorUserOption({value: match.firstName || match.email, avatar: match.avatar}));
      }
    });
    return nodeList;
  };
  columns && columns.forEach(c => {
    let display;
    if (c === 'owner') display = {node: <Flex style={{color: '#686273'}}>{members ? membersNode([group.owner_id]) : 'Loading...'}</Flex>};
    else if (c === 'members') display = {node: <Flex style={{color: '#686273'}}>{members ? membersNode(group.members, group.owner_id) : 'Loading...'}</Flex>};
    else if (c === 'name') display = group.name;
    data[c] = {
      display,
    };
  });
  data.onClick = () => setView(group.id);
  return data;
};

export const selectShouldFetchWorkspaceUserGroups = (state, workspaceId) => {
  const workspace = selectById(state, workspaceId || selectSelectedWorkspaceId(state));
  if (!workspace || workspace.groups) return false;
  return true;
};

export const selectShouldFetchWorkspaceMembers = (state, workspaceId) => {
  const workspace = selectById(state, workspaceId || selectSelectedWorkspaceId(state));
  if (!workspace || workspace.members) return false;
  return true;
};

export const selectWorkspaceMemberRole = (state, workspaceId, userId) => {
  const workspace = selectById(state, workspaceId);
  if (!workspace) return null;
  const groups = workspace.groups;
  if (!groups) return null;
  const adminGroup = groups.filter(group => group.name === 'admin')[0];
  const admins = adminGroup?.members;
  const devGroup = groups.filter(group => group.name === 'dev')[0];
  const devs = devGroup?.members;
  if (admins && admins.filter(memberId => memberId === userId).length) return 'Admin';
  else if (devs && devs.filter(memberId => memberId === userId).length) return 'Editor';
  else return 'Pending';
};

export const selectWorkspaceJoinedMembers = (state) => {
  const workspaceId = selectSelectedWorkspaceId(state);
  const workspaceMembers = selectSelectedWorkspaceMembers(state);
  if (!workspaceMembers) return null;
  const joinedMembers = workspaceMembers.filter(member => {
    return ['Admin', 'Editor'].includes(selectWorkspaceMemberRole(state, workspaceId, member.id));
  });
  return joinedMembers;
};

export const selectWorkspaceJoinedMembersStringified = (state) => {
  const joinedMembers = selectWorkspaceJoinedMembers(state);
  if (!joinedMembers) return null;
  return JSON.stringify(joinedMembers);
};

export default workspacesSlice.reducer;
