import checkIsElectron from 'is-electron';
import {DOMAIN, PLATFORM} from '../app/constants';
import {getCookie, setCookie} from './cookies';
import {debugLog} from '../../helpers';
export const isElectron = checkIsElectron() && (typeof window !== 'undefined' && !window.Cypress);
export const isElectronProduction = (() => {
  if (!checkIsElectron()) return false;
  return typeof process === 'undefined' && !window?.Cypress;
})();

export const validateDirName = (name, options = {}) => {
  const {charLimits} = options;
  const errors = [];
  // Workspace/home folder name constraints:
  // - Start with a a-zA-Z
  // - Follow by word or some allowed special characters
  //     - /w provids letters, numbers and _ coverage
  // - Min, max chars specified by charLimits
  // - Cannot contain spaces

  /* eslint-disable-next-line no-useless-escape */
  const invalidCharacters = /[#$%^&()+\=\[\]{};':"\\|,<>\/?]+/;
  if (charLimits) {
    const [min, max] = charLimits;
    if (name.length < min || name.length > max) {
      errors.push(`Name must be between ${min} and ${max} characters`);
    }
  }
  if (/\s/.test(name)) errors.push('Name must not contain spaces');
  if (invalidCharacters.test(name)) errors.push('Only special characters: _ ! * @ . are allowed');
  if (!/^[a-zA-Z]/i.test(name)) errors.push('Name must start with a letter');

  if (errors.length) return {error: errors};
  return {payload: 'success'};
};

export const isValidFileName = name => {
  if (name === '') return false;
  if (/\s/.test(name)) return false;
  return true;
};

export const debounce = function(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
    return timeout;
  };
};

export const throttle = function(func, limit) {
  let lastFunc,
    lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const arrayBufferToBase64 = buffer => {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const buildRequestUrl = (location) => {
  const leadChar = location[0];
  if (leadChar !== '/') return location;
  const prefix = location.split('/')[1].split('?')[0];
  let domain = DOMAIN[DOMAIN.length - 1] === '/'
    ? DOMAIN.slice(0, DOMAIN.length - 1)
    : DOMAIN;
  if (domain === 'local') {
    domain = 'http://localhost:';
    switch (prefix) {
    case 'auth':
    case 'auth-service':
      domain += '3001';
      break;
    case 'files':
      domain += '3002';
      break;
    case 'triggers':
    case 'integrations':
    case 'processes':
    case 'terminal':
    case 'workspace-integrations':
    case 'file-updates':
      domain += '3005';
      break;
    case 'lairs':
    case 'workspaces':
    case 'users':
    default:
      domain += '3003';
    }
  }
  return domain + location;
};

export const fsCommand = async(command, args) => {
  const response = await window.electron.message.invoke('message', {
    type: 'FS_COMMAND',
    command: command,
    args,
  });
  const {payload} = response;
  return payload;
};
if (typeof window !== 'undefined') window.exists = (path) => fsCommand('existsSync', path);

export const chooseLocalDirectory = async() => {
  const response = await window.electron.message.invoke('CHOOSE_DIR');
  return response;
};

export const getStoredItem = async(item) => {
  debugLog('GET STORED', isElectron);
  if (isElectron) {
    const {payload, error} = await window.electron.message.invoke('message', {
      type: 'GET_STORED_ITEM',
      payload: item,
    });
    if (error) return null;
    return payload;
  } else {
    const value = getCookie(item);
    if (!value) return null;
    return value;
  }
};

export const setStoredItem = async(name, value) => {
  debugLog('SET STORE', name, value);
  if (isElectron) {
    await window.electron.message.invoke('message', {
      type: 'SET_STORED_ITEM',
      name,
      value,
    });
  } else {
    setCookie({name, path: '/', value});
  }
};
if (typeof window !== 'undefined') window.setStored = (...args) => setStoredItem(...args);

export const uniquefileName = async(parentPath, fileName) => {
  const children = fsCommand('readDirSync', parentPath);
  if (!children.includes(fileName)) return fileName;
  let num = 1;
  const buildName = (fileName, num) => `${fileName}_${num}`;
  let name = buildName(fileName, num);
  while (children.includes(name)) {
    num++;
    name = buildName(fileName, num);
  }
  return name;
};

const invertSlashes = str => {
  let res = '';
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== '\\') {
      res += str[i];
      continue;
    }
    res += '/';
  }
  return res;
};

export const cleanPathForPlatform = path => {
  if (PLATFORM === 'win') path = invertSlashes(path);
  return path;
};

export const daysBetweenDates = date => {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const endDate = new Date(date);
  return Math.round(Math.abs((today - endDate) / oneDay));
};

export const generateUniquePath = (path, existingPaths = []) => {
  existingPaths = existingPaths.map(ep => ep || '/');
  let copyIndex = 1;
  let copyName = path || '/';

  while (existingPaths.includes(copyName)) {
    if (copyName.length >= 2) {
      const lastChar = copyName[copyName.length - 1];
      const lastCharNumber = Number(lastChar);
      if (!isNaN(lastCharNumber)) {
        copyIndex += 1;
        copyName = copyName.substring(0, copyName.length - 1);
      }
    }
    copyName += `${copyIndex}`;
  }
  return copyName;
};
