import PropTypes from 'prop-types';
import React from 'react';
import {
  Flex,
  Box,
  Text,
  useTheme,
  useColorMode,
} from '@chakra-ui/react';

const UsageBar = ({
  label,
  max,
  value,
  color,
  width,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();

  const usageBarStyle = () => {
    let usageColor;
    const percentage = value / max;
    if (percentage > 0.90) usageColor = colors.error[500];
    else if (percentage > 0.75) usageColor = colors.caution[500];
    else if (percentage > 0.50) usageColor = colors.warning[500];
    else usageColor = colors.success[500];
    return {width: `${Math.min(100, Math.floor(100 * percentage))}%`, backgroundColor: color || usageColor};
  };
  return (
    <Flex direction="column">
      {label && <Text>{label}</Text>}
      <Flex
        w={width || '100%'}
        borderRadius="25px"
        overflow="hidden"
        background={`${colorMode}.grey.500`}>
        <Box h='15px' style={usageBarStyle()} />
      </Flex>
    </Flex>
  );
};

UsageBar.propTypes = {
  label: PropTypes.string,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

export default UsageBar;
