import {
  createEntityAdapter,
  createSlice,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  fetchCreateWorkspace,
  fetchUsersWorkspaces,
  selectSelectedWorkspace,
  fetchUpdateWorkspace,
  fetchDeleteWorkspace,
  loadUsersWorkspaces,
} from '../../app/WorkspacesSlice';
import fetchJson from '../../lib/fetchJson';
import fetchNoContent from '../../lib/fetchNoContent';
import {
  selectLairView,
  fetchCreateLair,
  fetchCreateDeployment,
  fetchProdLair,
  selectLairViewProdFileId,
  selectLairIsProd,
  fetchDeployLairData,
  fetchDeleteDeployment,
  fetchPublishLair,
  fetchDeletePublishLair,
  fetchLairMetadata,
  openLair,
  selectCurrentLairId,
  fetchHasPublicEndpoints,
  fetchMakeEndpointsPublic,
  setLairView,
  selectDeployedLairName,
} from '../lairs/LairsSlice';
import fetchStream from '../../lib/fetchStream';
import {
  addTab,
  setEditorFocused,
  selectFocusedId,
  removeUnsavedValue,
  closeTab,
} from '../editor/EditorSlice';
import {FILE_EXTENSIONS, IS_MOCK, RETRY_FETCH_LIMIT, DOMAIN} from '../../app/constants';
import {
  encodeFilePath,
  generateUniqueFileName,
  getFileNameExtension,
  readStreamAsBlob,
  readStreamAsText,
  updateFileNameOfPath,
} from './helpers';
import {v4 as uuidv4} from 'uuid';
import {FILE_TYPE_RUN_COMMANDS, HIDDEN_FILE_NAMES} from './constants';
import {arrayBufferToBase64, buildRequestUrl, isElectron, isValidFileName} from '../../utils/helpers';
import {
  focusTerminalTab,
  selectTerminalStatus,
  setTerminalCommand,
  setTerminalStatus,
  resetTerminalProcessId,
} from '../processes/ProcessesSlice';
import moment from 'moment';
import Fuse from 'fuse.js';
import {
  convertEnvVarsObjToString,
  convertEnvVarsStringToObj,
} from '../editor/helpers';
import {selectUserProp} from '../user/UserSlice';
import {FEATURES} from '../flags';
import {closeModal, showModal} from '../modal/ModalSlice';
import * as analytics from '../../utils/analytics';

const filesAdapter = createEntityAdapter();

export const fetchFiles = createAsyncThunk(
  '/fetchFiles',
  async(args, thunkAPI) => {
    const files = await fetchJson('/files', {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    });
    return files;
  },
);

const getDescendantIds = (rootId, files /** @type(Array<FileObj>) */) => {
  const ids = [rootId];
  const file = files.filter(f => f.id === rootId)[0];
  const addChildren = (children) => {
    if (!children) return;
    children.forEach(childId => {
      ids.push(childId);
      const file = files.filter(f => f.id === childId)[0];
      if (file) addChildren(file.children);
    });
  };
  const children = file.children;
  addChildren(children);
  return ids;
};

export const fetchProdFiles = createAsyncThunk(
  '/fetchProdFiles',
  async(args, thunkAPI) => {
    let files = await fetchJson('/files', {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    });
    const prodLairs = files.filter(file => file.is_lair_root && file.name.includes('.prod'));
    const ids = [];
    prodLairs.forEach(file => {
      ids.push(...getDescendantIds(file.id, files));
    });
    files = files
      .filter(file => ids.includes(file.id))
      .map(file => { return {...file, is_prod: true, dirty: true}; }); // dirty forces content to be refetched
    return files;
  },
);

export const fetchFileSave = (args) => async(dispatch, getState) => {
  const state = getState();
  const {id} = args;
  const filePath = buildFilePath(state, id, false);
  dispatch(setIgnoreSNS({type: 'file changed', filePath}));
  const data = await dispatch(fetchFileSaveCAT(args));
  if (!data.error) {
    dispatch(removeUnsavedValue(id));
  }
  return data;
};

export const fetchFileSaveCAT = createAsyncThunk(
  'files/requestSaveFileById',
  async(args, {getState, dispatch, rejectWithValue}) => {
    if (args.value === undefined) return;
    const state = getState();
    const isProd = isFileDescendantOfProdFile(state, args.id);
    if (isProd) throw new Error('cannot edit files in prod lair version');
    const filePath = buildFilePath(state, args.id, !isElectron);
    const requestUpdateFile = async(encodedPath) => {
      const formData = new FormData();
      formData.append('file_content', args.value);
      await fetchJson(`/files/${encodedPath}`, {
        method: 'PUT',
        body: formData,
        toast: args.toast,
      });
    };
    if (isElectron && !filePath.includes('.prod')) {
      // chekck if special file
      const encrypted = selectIsEncryptedFileId(state, args.id);
      if (encrypted) {
        const encodedPath = filePath.replace(/\//g, '/%2F');
        await requestUpdateFile(encodedPath);
        await dispatch(syncFiles({pullReady: true}));
      } else {
        const {error} = await window.electron.message.invoke('message', {
          type: 'SAVE_FILE_CONTENT',
          path: `${state.workspaces.directory}/${filePath}`,
          content: args.value,
        });
        if (error) return rejectWithValue(error);
      }
      dispatch(clearIgnoreSNS());
      return {id: args.id, encrypted};
    } else {
      await requestUpdateFile(filePath);
      dispatch(clearIgnoreSNS());
      return {id: args.id};
    }
  },
);

export const fetchDeleteSecret = createAsyncThunk(
  'files/deleteSecret',
  async(key, {getState, dispatch}) => {
    const state = getState();
    const lairId = selectLairView(state);
    const secretsId = selectLairSecretsFileId(state);
    const requestDeleteSecret = async() => {
      await fetchNoContent(`/files/lairs/${lairId}/secrets/${key}`, {
        method: 'DELETE',
      });
    };
    if (isElectron) {
      await requestDeleteSecret();
      await dispatch(syncFiles({pullReady: true}));
      return {id: secretsId};
    } else {
      await requestDeleteSecret();
      const secretsContent = selectById(state, secretsId).content;
      let secrets = convertEnvVarsStringToObj(secretsContent);
      secrets = secrets.filter(secret => secret[0] !== key);
      dispatch(fileUpdated({
        id: secretsId,
        changes: {
          content: convertEnvVarsObjToString(secrets),
        },
      }));
      return {success: true};
    }
  },
);

export const fetchAddSecret = createAsyncThunk(
  'files/addSecret',
  async(args, {getState, dispatch}) => {
    const [key, value] = args;
    const state = getState();
    const lairId = selectLairView(state);
    const secretsId = selectLairSecretsFileId(state);
    const requestAddSecret = async() => {
      await fetchJson(`/files/lairs/${lairId}/secrets`, {
        method: 'POST',
        body: JSON.stringify({
          key,
          value,
        }),
        headers: {'Content-Type': 'application/json'},
      });
    };
    if (isElectron) {
      await requestAddSecret();
      await dispatch(syncFiles({pullReady: true}));
      return {id: secretsId};
    } else {
      await requestAddSecret();
      const secretsContent = selectById(state, secretsId).content;
      const secrets = convertEnvVarsStringToObj(secretsContent);
      secrets.push([key, '***************']);
      dispatch(fileUpdated({
        id: secretsId,
        changes: {
          content: convertEnvVarsObjToString(secrets),
        },
      }));
      return {id: secretsId};
    }
  },
);

export const requestRunFile = createAsyncThunk(
  'editor/requestRunFileById',
  async(id, {getState, dispatch}) => {
    const state = getState();
    const file = selectById(state, id);
    let command = FILE_TYPE_RUN_COMMANDS[getFileNameExtension(file.name)];
    if (!command) throw new Error('no run command found for file type');
    const lairView = selectLairView(state);
    if (!lairView) throw new Error('no running a file outside a lair');
    const filePath = '/' + buildWorkspaceRelativeFilePath(state, id, false, false);
    command = command.replace('*', filePath);
    if (isElectron) {
      await dispatch(syncFiles({pushReady: true}));
    }
    dispatch(setTerminalCommand(command));
    dispatch(focusTerminalTab());
  },
);

export const markFileDirtyByPath = (path) => async(dispatch, getState) => {
  const fileId = selectFileIdByPath(getState(), path);
  if (!fileId) return;
  dispatch(fileUpdated({id: fileId, changes: {dirty: true}}));
};

// export const markFileChangedByPath = (path) => async(dispatch, getState) => {
//   const state = getState();
//   const fileId = selectFileIdByPath(getState(), path);
//   if (!fileId) return;
//   const unsavedValues = state.editor.unsaved_values;
//   if (typeof unsavedValues[fileId] === 'string') {
//     dispatch(fileUpdated({id: fileId, changes: {changed: true}}));
//     const tabId = convertIdToPrefixedId(fileId, 'file', selectLairView(state) || 'global');
//     dispatch(closeTab(tabId, false, true));
//     dispatch(openFile(fileId));
//   } else {
//     dispatch(fileUpdated({id: fileId, changes: {dirty: true}}));
//   }
// };

export const fetchFileContentByPath = (path) => async(dispatch, getState) => {
  const fileId = selectFileIdByPath(getState(), path);
  if (!fileId) return;
  return await dispatch(fetchFileContent({id: fileId, path}));
};

export const fetchFileContent = createAsyncThunk(
  'editor/fetchFileContentById',
  async(args, {getState, rejectWithValue}) => {
    const state = getState();
    // id is required
    const {id, path} = args;
    let filePath;
    if (!path) {
      filePath = buildFilePath(state, id, !isElectron);
    } else {
      filePath = !isElectron ? encodeFilePath(path) : path;
    }
    const isEncrypted = selectIsEncryptedFileId(state, id);
    if (isElectron && !filePath.includes('.prod') && !isEncrypted) {
      const response = await window.electron.message.invoke('message', {
        type: 'LOAD_FILE_CONTENT',
        path: `${state.workspaces.directory}/${filePath}`,
      });
      const {error, payload} = response;
      if (error) return rejectWithValue(error);
      return payload;
    } else {
      const data = await fetchStream(`/files/${filePath}?contents=true`, {
        method: 'GET',
      });
      if (typeof data === 'string') return data;
      else return await readStreamAsText(data);
    }
  },
);

export const fetchFileBlob = (args) => async(dispatch, getState) => {
  const {id, path} = args;
  let filePath;
  if (!path) {
    filePath = buildFilePath(getState(), id, true);
  } else {
    filePath = encodeFilePath(path);
  }
  const data = await fetchStream(`/files/${filePath}?contents=true`, {
    method: 'GET',
  });
  if (!data.error) {
    return {
      payload: await readStreamAsBlob(data),
    };
  } else {
    // if download fails?
    return {
      error: data.error,
    };
  }
};

export const fetchFileChildren = createAsyncThunk(
  'editor/fetchFileChildren',
  async(arg, {getState, dispatch}) => {
    const {id, path} = arg;
    const state = getState();
    const filePath = path || buildFilePath(state, id, true);
    const data = await fetchJson(`/files/${filePath}`, {
      method: 'GET',
    });

    // TODO only needed for mocking
    // data = data.map(file => {
    //   file.parent = id;
    //   return file;
    // });
    return data;
  },
);

export const promptDuplicateFileNameHandlingThenInvoke = (func, fileName, fileChildrenNames, fileChildWithSameName) => async(dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const replace = async() => {
      // delete child with the same name, then proceed to add the file being uploaded with original name
      await dispatch(fetchDeleteFile(fileChildWithSameName.id));
      dispatch(closeModal({which: 'general_ask_prompt'}));
      await func(fileName);
      if (isElectron) await dispatch(loadUsersWorkspaces());
      resolve();
    };
    const keep = async() => {
      // generate a unique file name first, then request to add it.
      const newFileName = generateUniqueFileName(fileName, fileChildrenNames);
      dispatch(closeModal({which: 'general_ask_prompt'}));
      resolve(func(newFileName));
    };
    const cancel = async() => {
      // hide modal when cancellings
      dispatch(closeModal({which: 'general_ask_prompt'}));
      resolve(func());
    };

    dispatch(showModal({
      which: 'general_ask_prompt',
      data: {
        options: [
          {
            onClick: replace,
            text: 'Replace',
          },
          {
            onClick: keep,
            text: 'Keep',
          },
        ],
        cancel,
        cancelText: 'Cancel',
        header: `A file with name ${fileName} exists in this location already. `,
      },
    }));
  });
};

export const clearIgnoreSNS = (delay = 5000) => (dispatch, getState) => {
  setTimeout(() => dispatch(setIgnoreSNS('clear-all')), 5000);
};

export const fetchAddFile = createAsyncThunk(
  'files/fetchAddFile',
  async(args, {getState, rejectWithValue, dispatch}) => {
    const {is_directory, parent, name, id, content, isContentFileType, relPath, ignoreRedux} = args;
    const state = getState();
    const isProd = isFileDescendantOfProdFile(state, parent);
    if (isProd) throw new Error('cannot add file in prod lair');
    const filePath = `${buildFilePath(state, parent, false)}${relPath || '/' + name}`;
    const requestAddFile = async() => {
      const sendRequest = async(newName) => {
        const formData = new FormData();
        const filePath = `${buildFilePath(state, parent, false)}${(relPath && updateFileNameOfPath(relPath, name, newName)) || '/' + newName}`;
        const metadata = JSON.stringify({
          id: id,
          name: newName,
          file_path: filePath,
          is_directory: is_directory,
        });

        formData.append('files[]', isContentFileType ? content : new File([content || ''], newName));
        formData.append('metadata[]', metadata);
        const files = await fetchJson('/files', {
          method: 'POST',
          body: formData,
        });
        return {
          files,
          filePath,
        };
      };
      return sendRequest(name);
    };
    if (isElectron) {
      if (selectIsEncryptedFile(state, name, parent)) {
        await requestAddFile();
        await dispatch(syncFiles({pullReady: true}));
        dispatch(clearIgnoreSNS());
        return {
          ignorePush: true,
        };
      } else {
        const {error} = await window.electron.message.invoke('message', {
          type: 'ADD_FILE',
          path: `${state.workspaces.directory}/${filePath}`,
          content: content || '',
          isDirectory: is_directory,
        });
        if (error) return rejectWithValue(error);
        const files = [{
          id,
          name: name,
          is_directory: is_directory,
          is_lair_root: false,
          is_workspace_root: false,
          parent,
          children: [],
        }];
        if (!ignoreRedux) dispatch(clearIgnoreSNS());
        return {data: files};
      }
    } else {
      const {files} = await requestAddFile();
      // BE generates the parent based on file_path
      // but the FE knows what it should be. So, we check
      // if there's a conflict (always conflict for mocking)
      files.forEach(file => {
        if (file.parent !== parent) {
          file.parent = parent;
        }
      });
      if (!ignoreRedux) dispatch(clearIgnoreSNS());
      return {data: files};
    }
  },
);

const repeatUntilSuccess = async(
  func,
  inputs,
  successCondition,
  maxAttempts = 15,
  delayFunction = (i) => 500 + (500 * i),
  attempt = 0,
) => {
  const payload = {
    data: null,
    error: null,
  };
  if (attempt >= maxAttempts) return 'error';
  try {
    const data = await func(...inputs);
    if (successCondition && !successCondition(data)) throw new Error('Success criteria not met');
    payload.data = data;
    return payload;
  } catch (error) {
    if (attempt < maxAttempts - 1) {
      await new Promise(resolve => setTimeout(resolve, delayFunction(attempt)));
      const argList = [func, inputs, successCondition, maxAttempts, delayFunction, attempt + 1];
      return await repeatUntilSuccess(...argList);
    } else {
      payload.error = error;
      return payload;
    }
  }
};

export const fetchDuplicateFile = createAsyncThunk(
  'files/fetchDuplicateFile',
  async(args, {getState, dispatch, rejectWithValue}) => {
    const {id, isLairRoot} = args;
    const state = getState();
    const filePath = buildFilePath(state, id, false, false);
    const parentId = selectById(state, id).parent;
    const parentPath = buildFilePath(state, parentId, false, false);
    const fileName = selectFilePropById(state, id, 'name');
    if (isElectron && !isLairRoot) {
      let parentPath = filePath.split('/');
      const fileName = parentPath.pop();
      parentPath = parentPath.join('/');
      let {error, payload} = await window.electron.message.invoke('message', {
        type: 'DUPLICATE_FILE',
        fileName,
        parentPath: `${state.workspaces.directory}/${parentPath}`,
        parentId,
        isLairRoot,
      });
      if (error) return rejectWithValue(error);
      const files = payload;
      payload = {
        files,
        parent: parentId,
      };
      return payload;
    } else {
      const fileChildrenNames = selectFilePropById(state, parentId, 'children').map(c => selectFilePropById(state, c, 'name'));
      const copyName = generateUniqueFileName(fileName, fileChildrenNames);
      const files = await fetchJson(isLairRoot ? '/lairs/clone' : '/files/clone', {
        method: 'POST',
        body: JSON.stringify({
          source: filePath,
          destination: parentPath,
          new_name: copyName,
        }),
        headers: {'Content-Type': 'application/json'},
      });
      const fetchLairFiles = async(path) => {
        return await fetchJson(`/files/${path}`, {
          method: 'GET',
        });
      };
      const parentFilePath = buildFilePath(state, parentId);
      const lairFiles = await fetchLairFiles(`${parentFilePath}%2F${fileName}`);
      const numLairFiles = lairFiles.length;
      const encodedPath = `${parentFilePath}%2F${copyName}`;

      const data = DOMAIN !== 'local'
        ? await repeatUntilSuccess(fetchLairFiles, [encodedPath], (files) => {
          return files.length === numLairFiles && !files.some(fileObj => {
            if (!fileObj.is_directory) return false;
            return fileObj.etag === null;
          });
        })
        : fetchLairFiles(encodedPath);
      if (data.error) return rejectWithValue('Timed out waiting for file service');

      const payload = {
        parent: parentId,
        files,
      };
      if (isLairRoot) {
        analytics.track(analytics.lairDuplicatedEvent, {name: fileName});
        if (!isElectron) {
          // fetch children of cloned lair
          if (data.error) return rejectWithValue(data.error);
          payload.files = data.data;
        } else {
          await dispatch(pullChanges());
          payload.files = [];
          payload.pushReady = false;
        }
      }
      return payload;
    }
  });

export const fetchRenameFile = createAsyncThunk(
  'files/fetchRenameFile',
  async(args, {getState, dispatch, rejectWithValue}) => {
    const state = getState();
    const {id, name} = args;
    const filePath = buildFilePath(state, id, false);
    const file = selectById(state, id);
    if (!file) rejectWithValue('File not found');
    if (isElectron) {
      let newPath = filePath.split('/');
      newPath[newPath.length - 1] = name;
      newPath = newPath.join('/');
      const {error} = await window.electron.message.invoke('message', {
        type: 'RENAME_FILE',
        oldPath: `${state.workspaces.directory}/${filePath}`,
        newPath: `${state.workspaces.directory}/${newPath}`,
      });
      if (error) return rejectWithValue(error);
    } else {
      if (file.is_lair_root) {
        const data = await fetchNoContent(`/lairs/${id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            new_name: name,
          }),
          headers: {'Content-Type': 'application/json'},
        });
        if (data?.error) return rejectWithValue(data.error);
      } else {
        const data = await fetchJson('/files/rename', {
          method: 'POST',
          body: JSON.stringify({
            file_path: filePath,
            new_name: name,
          }),
          headers: {'Content-Type': 'application/json'},
        });
        if (data?.error) return rejectWithValue(data.error);
      }
    }
    if (file.is_lair_root) dispatch(resetTerminalProcessId());
  },
);

export const fetchMoveFile = createAsyncThunk(
  'files/fetchMoveFile',
  async(args, thunkAPI) => {
    const {id, location} = args;
    const state = thunkAPI.getState();
    const filePath = buildFilePath(state, id, false);
    const destPath = buildFilePath(state, location, false);
    if (isElectron) {
      let fileName = filePath.split('/');
      fileName = fileName[fileName.length - 1];
      const {error} = await window.electron.message.invoke('message', {
        type: 'MOVE_FILE',
        oldPath: `${state.workspaces.directory}/${filePath}`,
        newPath: `${state.workspaces.directory}/${destPath}/${fileName}`,
      });
      if (error) return thunkAPI.rejectWithValue(error);
    } else {
      await fetchJson('/files/move', {
        method: 'POST',
        body: JSON.stringify({
          source: filePath,
          destination: destPath,
        }),
        headers: {'Content-Type': 'application/json'},
      });
    }
  },
);

export const fetchDeleteFiles = (fileIDs, isWorkspace, isLair, toast) => async(dispatch, getState) => {
  const payload = {error: []};
  for (let i = 0; i < fileIDs.length; i++) {
    if (isWorkspace) {
      const res = await dispatch(fetchDeleteWorkspace(fileIDs[i]));
      const {error, payload: deletePayload} = res;
      if (error) payload.error.push({...error, ...deletePayload.data});
    } else {
      const res = await dispatch(fetchDeleteFile(fileIDs[i]));
      const {error, payload: deletePayload} = res;
      if (error) payload.error.push({...error, ...deletePayload.data});
      dispatch(closeTab(fileIDs[i], false));
    }
  }
  isLair && dispatch(setLairView(null));
  let errorMessage = '';
  if (payload.error.length === 1) {
    errorMessage = payload.error[0].message;
  } else if (payload.error.length > 1) {
    // errorMessage = payload.error.map(a => a.message).join('. ');
    errorMessage = 'Multiple deletes failed';
  }
  if (toast && errorMessage) {
    toast({
      title: errorMessage,
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  }
  payload.error = errorMessage;
  return payload;
};

export const fetchDeleteFile = createAsyncThunk(
  'files/fetchDeleteFile',
  async(id, {dispatch, getState, rejectWithValue}) => {
    const state = getState();
    const file = selectById(state, id);
    const requestDeleteFile = async() => {
      const filePathEnc = buildFilePath(state, id, true);
      return await fetchJson(`/files/${filePathEnc}`, {
        method: 'DELETE',
      });
    };
    const isEncrypted = selectIsEncryptedFileId(state, id);
    const filePath = buildFilePath(state, id, false);
    if (isElectron) {
      // deleting .secrets must be done via request
      if (isEncrypted) await requestDeleteFile();
      const {error} = await window.electron.message.invoke('message', {
        type: 'DELETE_FILE',
        path: `${state.workspaces.directory}/${filePath}`,
      });
      if (error) {
        return rejectWithValue(error);
      }
    } else {
      dispatch(setIgnoreSNS({type: 'file removed', filePath}));
      if (file.is_lair_root) {
        analytics.track(analytics.lairDeletedEvent);
        try {
          await fetchJson(`/lairs/${file.id}`, {
            method: 'DELETE',
          });
        } catch (error) {
          return rejectWithValue(error);
        }
      } else {
        try {
          await requestDeleteFile();
        } catch (error) {
          return rejectWithValue(error);
        }
      }
    }
    dispatch(clearIgnoreSNS());
    return {ignorePush: isEncrypted};
  },
);

export const filesDropHandler = createAsyncThunk(
  '/handleFilesDrop',
  async(args, {dispatch, getState}) => {
    // eslint-disable-next-line no-async-promise-executor
    const dropData = await new Promise(async(resolve, reject) => {
      const {event: e, id: rootId, setPendingUploads} = args;
      const state = getState();
      if (e) e.preventDefault();
      if (containsFiles(e)) {
        // TODO: Files uploaded by dragging from local machine
        const data = e.dataTransfer.items;
        if (!isElectron) dispatch(setIgnoreSNS('all'));
        const traversingFilesQueue = [];
        for (let i = 0; i < data.length; i += 1) {
          const item = data[i];
          const traverseFileDataDirectoryForEntry = (item) => {
            return new Promise((resolve, reject) => {
              const entry = item.webkitGetAsEntry();
              traverseFileDataDirectory(entry).then(async(fileData) => {
                let fileDataFlat = fileData.flat(Infinity);
                fileDataFlat = fileDataFlat.filter((data) => getFileNameExtension(data.fullPath).toLowerCase() !== 'ds_store');
                fileDataFlat.sort((a, b) => a.fullPath > b.fullPath ? -1 : 1);
                resolve(fileDataFlat);
              });
            });
          };
          traversingFilesQueue.push(traverseFileDataDirectoryForEntry(item));
        }
        const traversingFilesQueueReady = await Promise.all(traversingFilesQueue);

        let itemTotalLength = 0;
        const pendingUploads = [];
        // actually add the files
        for (let i = 0; i < traversingFilesQueueReady.length; i++) {
          const item = traversingFilesQueueReady[i];
          let skipUpload;
          // 1. check item for duplicate child names here
          // 2. then prompt,
          // 3. then mutate fullPath and file names appropriately
          const firstFile = item[0];
          const name = firstFile.fullPath.split('/')[1];
          const fileChildren = selectFilePropById(state, rootId, 'children').map(c => selectById(state, c));
          const fileChildrenNames = fileChildren.map(child => child && child.name);
          const childWithSameName = fileChildren.find(child => child?.name === name);
          const handleDuplicatePromptAnswer = (newName) => {
            if (!newName) {
              // user selected 'cancel'
              skipUpload = true;
            } else {
              // user selected 'replace' or 'keep'
              const pathNodes = firstFile.fullPath.split('/');
              pathNodes[1] = newName;
              const newPath = pathNodes.join('/');
              if (pathNodes.length <= 2) {
                // file instead of folder
                item[0].fullPath = newPath;
                item[0].name = newName;
              } else {
                // folder instead of file
                for (let j = 0; j < item.length; j++) {
                  const file = item[j];
                  const pathNodes = file.fullPath.split('/');
                  pathNodes[1] = newName;
                  const newPath = pathNodes.join('/');
                  const fileData = item[j];
                  fileData.fullPath = newPath;
                }
              }
            }
          };
          if (childWithSameName) await dispatch(promptDuplicateFileNameHandlingThenInvoke(handleDuplicatePromptAnswer, name, fileChildrenNames, childWithSameName));

          const uploadFile = (item) => {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async(resolve, reject) => {
              for (let i = 0; i < item.length; i++) {
                const fileData = item[i];
                const id = uuidv4();
                if (!isElectron && i === 0 && setPendingUploads) {
                  pendingUploads.push(fileData.fullPath.split('/')[1]);
                  setPendingUploads([...pendingUploads]);
                }
                let content = fileData.file;
                if (isElectron) {
                  content = await new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.readAsText(content);
                    reader.onload = function() {
                      resolve(reader.result);
                    };
                    reader.onerror = function() {
                      reject(new Error('FAILED TO READ FILE'));
                    };
                  });
                }
                await dispatch(fetchAddFile({is_directory: false, id, parent: rootId, name: fileData.name, content, isContentFileType: true, relPath: fileData.fullPath, ignoreRedux: true, showUploadPrompt: true}));
                itemTotalLength += 1;
              }
              resolve();
            });
          };
          if (!skipUpload) await uploadFile(item);
        }

        // TODO: BETTER SOLUTION? Issue is fetchAddFile does not return back folder data that is created if creating nested files. FE doesn't generate uuids for folders created by backend so we can never update the UI
        // settimeout is used here because files aren't updated in BE yet when its called without it.
        if (!isElectron) {
          setTimeout(async() => {
            await dispatch(fetchFileChildren({id: rootId}));
            dispatch(setIgnoreSNS('clear-all'));
            if (setPendingUploads) setPendingUploads([]);
            resolve();
          }, 1500 +
            500 * Math.max(itemTotalLength, 3) +
            100 * Math.max(0, itemTotalLength - 3),
          );
        } else resolve();
      } else if (e.target && e.target.files) {
        // Files uploaded through <Input />
        const pendingUploads = [];
        for (const file of Object.values(e.target.files)) {
          let name = file.name;
          const fileChildren = selectFilePropById(state, rootId, 'children').map(c => selectById(state, c));
          const fileChildrenNames = fileChildren.map(child => child && child.name);
          const childWithSameName = fileChildren.find(child => child?.name === name);
          let skipUpload;
          const handleDuplicatePromptAnswer = (newName) => {
            if (!newName) {
              // user selected 'cancel'
              skipUpload = true;
            } else {
              name = newName;
            }
          };
          if (childWithSameName) await dispatch(promptDuplicateFileNameHandlingThenInvoke(handleDuplicatePromptAnswer, name, fileChildrenNames, childWithSameName));
          const id = uuidv4();
          if (setPendingUploads) {
            pendingUploads.push(name);
            setPendingUploads([...pendingUploads]);
          }
          if (!skipUpload) {
            await dispatch(fetchAddFile({is_directory: false, id, parent: rootId, name: name, content: file, isContentFileType: true, ignoreRedux: true, showUploadPrompt: true}));
          }
        }
        if (setPendingUploads) setPendingUploads([]);
        resolve();
      } else {
        resolve();
      }
    });
    if (isElectron) dispatch(loadUsersWorkspaces());
    return dropData;
  },
);

export const fetchOrigin = createAsyncThunk(
  'sync/fetchOrigin',
  async(args, {getState}) => {
    const data = await window.electron.message.invoke('message', {
      type: 'FETCH_ORIGIN',
      userId: selectUserProp(getState(), 'id'),
    });
    const {payload} = data;
    return payload;
  },
);

export const pullChanges = createAsyncThunk(
  'sync/pullChanges',
  async(manual) => {
    const {payload} = await window.electron.message.invoke('message', {
      type: 'PULL_CHANGES',
      manual,
    });
    return payload;
  },
);

export const pullFile = createAsyncThunk(
  'sync/pullFile',
  async(filePath) => {
    const {payload} = await window.electron.message.invoke('message', {
      type: 'PULL_FILE',
      filePath,
    });
    return payload;
  },
);

export const pushChanges = createAsyncThunk(
  'sync/pushChanges',
  async(args, {dispatch, getState}) => {
    // first pull changes
    const state = getState();
    await window.electron.message.invoke('message', {
      type: 'PUSH_CHANGES',
      userId: selectUserProp(state, 'id'),
    });

    // if a lair is local only, then gets pushed, re-inititialize the terminal to try and connect again
    const terminalStatus = selectTerminalStatus(state);
    if (terminalStatus === 'local_only') {
      dispatch(setTerminalStatus('init'));
    }
    const lairFile = selectLairViewFile(state);
    if (lairFile && lairFile.last_deployed === undefined) {
      dispatch(fetchDeployLairData());
    }
  },
);

export const syncFiles = createAsyncThunk(
  'sync/syncFiles',
  async(args, {dispatch, getState}) => {
    const state = getState();
    const syncStatus = state.files.syncStatus;
    const syncing = ['fetching', 'pulling', 'pushing'].includes(syncStatus);

    const currentPushReady = state.files.pushReady || args?.pushReady;
    const currentPullReady = state.files.pullReady || args?.pullReady;

    if (syncing) return {pushReady: currentPushReady, pullReady: currentPullReady};

    const fetchData = await dispatch(fetchOrigin());
    const {pushReady, pullReady} = fetchData.payload;

    if (!currentPullReady && !currentPushReady) { // user sees fetch state
      return {pushReady, pullReady};
    }

    if (currentPushReady) { // user sees push state and does not need to pull
      if (pullReady) await dispatch(pullChanges(true));
      await dispatch(pushChanges());
      return {pullReady: false, pushReady: false};
    } else if (currentPullReady) { // user sees pull state
      await dispatch(pullChanges(true));
      return {pullReady: false, pushReady};
    }

    return {pushReady, pullReady};
  },
);

const initialState = filesAdapter.getInitialState({
  status: 'init',
  search: '',

  // electron
  syncStatus: 'init', // TODO fetch automatically when 'init'
  pullReady: false,
  pushReady: false,
  // conflict: null, // filepath of conflict to resolve
  socket_id: 0,
  ignore_sns: null, //
  selected: {},
  lastSelected: null,
  status_duplicate_lair: 'idle',
});

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    fileAdded: filesAdapter.addOne,
    fileRemoved: filesAdapter.removeOne,
    filesRemoved: filesAdapter.removeMany,
    fileUpdated: filesAdapter.updateOne,
    filesUpdated: filesAdapter.updateMany,
    fileUpserted: filesAdapter.upsertOne,
    filesUpserted: filesAdapter.upsertMany,
    filesLoaded: (state, action) => {
      // Or, call them as "mutating" helpers in a case reducer
      filesAdapter.setAll(state, action.payload.files);
      state.status = 'idle';
    },
    setFilesSearch: (state, action) => {
      state.search = action.payload;
    },
    resetFiles: (state, action) => {
      state.ids = [];
      state.entities = {};
    },
    incrementFilesSocketId: (state, action) => {
      state.socket_id += 1;
    },
    setIgnoreSNS: (state, action) => {
      if (state.ignore_sns === 'all') {
        if (action.payload === 'clear-all') state.ignore_sns = null;
      } else state.ignore_sns = action.payload;
    },
    setLairFileRun: (state, action) => {
      const {lairId, triggerId} = action.payload;
      if (!triggerId) state.entities[lairId].run = undefined;
      else state.entities[lairId].run = triggerId;
    },
    resetFetchFileContent: (state, action) => {
      state.entities[action.payload].status_content = 'retry-0';
    },
    upsertFilesSelected: (state, action) => {
      state.selected[action.payload.key] = action.payload.value;
    },
    removeFilesSelected: (state, action) => {
      delete state.selected[action.payload];
    },
    clearFilesSelected: (state, action) => {
      state.selected = {};
    },
    setLastSelected: (state, action) => {
      state.lastSelected = action.payload;
    },
    setFileExpanded: (state, action) => {
      const {id, value} = action.payload;
      state.entities[id].expanded = value;
    },
    setFileHasExpanded: (state, action) => {
      const {id, value} = action.payload;
      state.entities[id].hasExpanded = value;
    },
  },
  extraReducers: {
    [filesDropHandler.pending]: (state, action) => {
      state.status = 'pending';
    },
    [filesDropHandler.fulfilled]: (state, action) => {
      state.status = 'idle';
    },
    [filesDropHandler.rejected]: (state, action) => {
      state.status = 'idle';
    },
    [fetchFiles.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchFiles.fulfilled]: (state, action) => {
      filesAdapter.setAll(state, action.payload);
      state.status = 'idle';
    },
    [fetchFiles.rejected]: (state, action) => {
      state.status = 'rejected';
    },
    [fetchProdFiles.fulfilled]: (state, action) => {
      filesAdapter.upsertMany(state, action.payload);
      state.status = 'idle';
    },
    [fetchCreateLair.fulfilled]: (state, action) => {
      filesAdapter.upsertMany(state, action.payload);
      const lairFile = action.payload.filter(file => file.is_lair_root)[0];
      filesAdapter.updateOne(state, {
        id: lairFile.parent,
        changes: {
          children: [
            ...state.entities[lairFile.parent].children,
            lairFile.id,
          ],
        },
      });
    },
    [fetchUsersWorkspaces.fulfilled]: (state, action) => {
      filesAdapter.upsertMany(state, action.payload.workspaceFiles);
    },
    [loadUsersWorkspaces.pending]: (state, action) => {
      state.status = 'pending';
    },
    [loadUsersWorkspaces.fulfilled]: (state, action) => {
      filesAdapter.upsertMany(state, action.payload.files);
      if (action.payload.directory) state.directory = action.payload.directory;
      state.status = 'idle';
    },
    [fetchCreateWorkspace.fulfilled]: (state, action) => {
      if (action.payload) { // web app
        filesAdapter.upsertOne(state, action.payload.workspaceFile);
      }
    },
    [fetchFileSaveCAT.fulfilled]: (state, action) => {
      const file = state.entities[action.meta.arg.id];
      if (file) {
        file.content = action.meta.arg.value;
        file.status_save = 'idle';
        file.changed = false;
        if (action.payload.encrypted) {
          file.dirty = true;
        } else {
          state.pushReady = true;
        }
      }
    },
    [fetchFileSaveCAT.pending]: (state, action) => {
      const file = state.entities[action.meta.arg.id];
      if (file) file.status_save = 'pending';
    },
    [fetchFileSaveCAT.rejected]: (state, action) => {
      const file = state.entities[action.meta.arg.id];
      if (file) file.status_save = 'idle'; // TODO: change this to rejected and store/pass error message somehow to component to display.
      file.ignore_sns = null;
    },
    [fetchAddSecret.fulfilled]: (state, action) => {
      const file = state.entities[action.payload.id];
      if (file) {
        file.dirty = true;
      }
    },
    [fetchDeleteSecret.fulfilled]: (state, action) => {
      const {id} = action.payload;
      if (id) {
        const file = state.entities[id];
        if (file) {
          file.dirty = true;
        }
      }
    },
    [requestRunFile.pending]: (state, action) => {
      const file = state.entities[action.meta.arg];
      if (file) file.status_run = 'pending';
    },
    [requestRunFile.fulfilled]: (state, action) => {
      const file = state.entities[action.meta.arg];
      if (file) file.status_run = 'idle';
    },
    [requestRunFile.rejected]: (state, action) => {
      const file = state.entities[action.meta.arg];
      if (file) file.status_run = 'idle';
    },
    [fetchAddFile.pending]: (state, action) => {
      if (!action.meta.arg.ignoreRedux) state.ignore_sns = 'all';
    },
    [fetchAddFile.rejected]: (state, action) => {
      state.ignore_sns = null;
    },
    [fetchAddFile.fulfilled]: (state, action) => {
      const {parent, ignoreRedux, content} = action.meta.arg;
      const {data, ignorePush} = action.payload;
      if (data && !ignoreRedux) {
        // traverse response files and update each on FE
        data.forEach(fileData => {
          filesAdapter.addOne(state, {
            ...fileData,
            id: fileData.id,
            status_content: 'idle',
            content: content || '',
          });
        });

        // update parent with new child that FE creates
        filesAdapter.updateOne(state, {
          id: parent,
          changes: {
            children: [
              ...state.entities[parent].children,
              action.meta.arg.id,
            ],
          },
        });
        // for files that automatically push/pull
      }
      if (!ignorePush) state.pushReady = true;
    },
    [fetchFileContent.pending]: (state, action) => {
      const {id} = action.meta.arg;
      if (!id) return;
      const file = state.entities[id];
      if (file.status_content?.includes('retry-')) {
        const retryNum = Number(file.status_content.split('retry-')[1]);
        file.status_content = `pending-${retryNum}`;
      } else file.status_content = 'pending-0';
    },
    [fetchFileContent.fulfilled]: (state, action) => {
      const {id} = action.meta.arg;
      if (!id) return;
      const file = state.entities[id];
      if (file) {
        file.status_content = 'idle';
        file.content = action.payload;
        file.dirty = false;
      }
      // set file content as decoded payload
    },
    [fetchFileContent.rejected]: (state, action) => {
      const {id} = action.meta.arg;
      if (!id) return;
      const file = state.entities[id];
      if (!file) return;
      const retryNum = Number(file.status_content.split('pending-')[1]);
      if (retryNum >= RETRY_FETCH_LIMIT) file.status_content = 'rejected';
      else file.status_content = `retry-${retryNum + 1}`;
    },
    [fetchFileChildren.fulfilled]: (state, action) => {
      const {id, updateStatus} = action.meta.arg;
      if (updateStatus) {
        filesAdapter.updateOne(state, {
          id,
          changes: {
            status_children_fetched: 'idle',
          },
        });
      }
      filesAdapter.upsertMany(state, action.payload);
    },
    [fetchFileChildren.pending]: (state, action) => {
      const {id, updateStatus} = action.meta.arg;
      if (updateStatus) {
        filesAdapter.updateOne(state, {
          id,
          changes: {
            status_children_fetched: 'pending',
          },
        });
      }
    },
    [fetchRenameFile.pending]: (state, action) => {
    },
    [fetchRenameFile.fulfilled]: (state, action) => {
      filesAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: {
          name: action.meta.arg.name,
        },
      });
      state.pushReady = true;
    },
    [fetchAddFile.rejected]: (state, action) => {
    },
    [fetchMoveFile.pending]: (state, action) => {
    },
    [fetchMoveFile.fulfilled]: (state, action) => {
      const location = action.meta.arg.location;
      const id = action.meta.arg.id;
      const file = state.entities[id];
      const parent = file.parent;

      // update old parent and remove child file id
      filesAdapter.updateOne(state, {
        id: parent,
        changes: {
          children: state.entities[parent].children.filter(child => child !== id),
        },
      });

      // update parent of file being moved
      filesAdapter.updateOne(state, {
        id: id,
        changes: {
          parent: location,
        },
      });

      // update target location children with file id that was moved
      filesAdapter.updateOne(state, {
        id: location,
        changes: {
          children: [
            ...state.entities[location].children,
            id,
          ],
        },
      });
      state.pushReady = true;
    },
    [fetchMoveFile.rejected]: (state, action) => {
    },
    [fetchDeleteFile.pending]: (state, action) => {
      state.ignore_sns = 'all';
    },
    [fetchDeleteFile.rejected]: (state, action) => {
      state.ignore_sns = null;
    },
    [fetchDeleteFile.fulfilled]: (state, action) => {
      const id = action.meta.arg;
      const file = state.entities[id];
      const parent = file.parent;
      // remove file ID from parent
      filesAdapter.updateOne(state, {
        id: parent,
        changes: {
          children: state.entities[parent].children.filter(child => child !== id),
        },
      });
      if (!action.payload?.ignorePush) state.pushReady = true;
      // remove file
      // filesAdapter.removeOne(state, id);
    },
    [fetchDuplicateFile.pending]: (state, action) => {
      if (action.meta.arg.isLairRoot) {
        state.status_duplicate_lair = 'pending';
      }
    },
    [fetchDuplicateFile.rejected]: (state, action) => {
      if (action.meta.arg.isLairRoot) {
        state.status_duplicate_lair = 'idle';
      }
    },
    [fetchDuplicateFile.fulfilled]: (state, action) => {
      if (action.meta.arg.isLairRoot) {
        state.status_duplicate_lair = 'idle';
      }
      const {parent, files, pushReady = true} = action.payload;
      const duplicatedFile = files.filter(file => file.parent === parent)[0];
      if (duplicatedFile) {
        filesAdapter.upsertMany(state, files);
        filesAdapter.updateOne(state, {
          id: parent,
          changes: {
            children: [
              ...state.entities[parent].children,
              duplicatedFile.id,
            ],
          },
        });
        state.pushReady = pushReady;
      }
    },
    [fetchProdLair.fulfilled]: (state, action) => {
      // upsert prod file descendants
      filesAdapter.upsertMany(state, action.payload.filesList);

      // update workspace file children
      const devLairId = action.payload.lairId;
      const workspace = action.payload.workspaceFile;
      const prodId = action.payload.prodFileId;
      filesAdapter.updateOne(state, {
        id: workspace.id,
        changes: {
          children: [
            ...state.entities[workspace.id].children,
            state.entities[workspace.id].children.includes(prodId) ? null : prodId,
          ],
        },
      });

      filesAdapter.updateOne(state, {
        id: devLairId,
        changes: {
          prod_loaded: true,
          prod_dirty: false,
        },
      });
    },
    [fetchCreateDeployment.pending]: (state, action) => {
      const devLairFileId = action.meta.arg;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          status_deployment: 'pending',
        },
      },
      );
    },
    [fetchCreateDeployment.fulfilled]: (state, action) => {
      // update last_deployed on lair file
      const devLairFileId = action.payload.lairId;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          status_deployment: 'idle',
          prod_dirty: true,
        },
      },
      );
    },
    [fetchCreateDeployment.rejected]: (state, action) => {
      const devLairFileId = action.meta.arg;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          status_deployment: 'rejected',
        },
      },
      );
    },
    [fetchDeployLairData.fulfilled]: (state, action) => {
      // set last deployed to lair file.
      const devLairFileId = action.payload.lairId;
      const {last_deployed, deployed_lair_name, deployed_lair_id} = action.payload;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          last_deployed: moment(moment.utc(last_deployed).toDate()).local().format('MMMM Do YYYY, h:mm:ss a'),
          deployed_lair_name,
          deployed_lair_id,
        },
      });
    },
    [fetchDeployLairData.rejected]: (state, {payload}) => {
      const {error, lairId} = payload || {};
      if (error === 'Error: Lair has not been deployed' && lairId) {
        const devLairFileId = lairId;
        filesAdapter.updateOne(state, {
          id: devLairFileId,
          changes: {
            last_deployed: null,
          },
        });
      }
    },
    [fetchLairMetadata.fulfilled]: (state, {meta, payload}) => {
      // set last deployed to lair file.
      const id = meta.arg;
      let {created_date, is_public, last_run_date, manager_id, last_deployed, endpoint} = payload;
      /* eslint-disable-next-line camelcase */
      last_deployed = last_deployed
        ? moment(moment.utc(last_deployed).toDate()).local().format('MMMM Do YYYY, h:mm:ss a')
        : null;
      const changes = {
        created_date,
        is_public,
        last_run_date,
        manager_id,
        last_deployed,
        domain: endpoint,
      };
      if (FEATURES.pricing) {
        // mock runtime/storage data
        changes.runTimeUsed = 300;
        changes.storageUsed = 200;
      }

      filesAdapter.updateOne(state, {id, changes});
    },
    [fetchLairMetadata.rejected]: (state, action) => {
    },
    [fetchPublishLair.fulfilled]: (state, action) => {
      const devLairFileId = action.payload.lairId;
      const {is_public} = action.payload;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          is_public,
        },
      });
    },
    [fetchDeletePublishLair.fulfilled]: (state, action) => {
      const devLairFileId = action.payload.lairId;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          is_public: false,
        },
      });
    },
    [fetchDeleteDeployment.fulfilled]: (state, action) => {
      // update last_deployed on lair file
      const devLairFileId = action.payload.lairId;
      filesAdapter.updateOne(state, {
        id: devLairFileId,
        changes: {
          last_deployed: null,
        },
      },
      );
    },
    [fetchUpdateWorkspace.fulfilled]: (state, action) => {
      const {id, name} = action.meta.arg;
      filesAdapter.updateOne(state, {
        id: id,
        changes: {
          name: name,
        },
      });
    },
    [fetchDeleteWorkspace.fulfilled]: (state, action) => {
      filesAdapter.removeMany(state, action.payload.fileIds);
    },
    [fetchOrigin.pending]: (state, action) => {
      if (['init', 'idle'].includes(state.syncStatus)) state.syncStatus = 'fetching';
    },
    [fetchOrigin.fulfilled]: (state, action) => {
      if (state.syncStatus === 'fetching') state.syncStatus = 'idle';
      state.pullReady = action.payload.pullReady;
      state.pushReady = action.payload.pushReady;
    },
    [fetchOrigin.rejected]: (state, action) => {
      state.syncStatus = 'idle';
      state.pullReady = false;
      state.pushReady = false;
    },
    [pullChanges.pending]: (state, action) => {
      state.syncStatus = 'pulling';
    },
    [pullChanges.fulfilled]: (state, action) => {
      state.syncStatus = 'idle';
      state.pullReady = false;
      Object.entries(state.entities).forEach(entity => {
        if (entity[1].status_content === 'idle') {
          state.entities[entity[0]].dirty = true;
        }
      });
    },
    [pushChanges.pending]: (state, action) => {
      state.syncStatus = 'pushing';
    },
    [pushChanges.fulfilled]: (state, action) => {
      state.syncStatus = 'idle';
      state.pushReady = false;
    },
    [syncFiles.fulfilled]: (state, action) => {
      state.syncStatus = 'idle';
      const {pushReady, pullReady} = action.payload;
      state.pushReady = pushReady;
      state.pullReady = pullReady;
    },
    [fetchHasPublicEndpoints.fulfilled]: (state, action) => {
      filesAdapter.updateOne(state, {
        id: action.meta.arg.lairId,
        changes: {
          publicEndpoints: !action.payload.endpoints_are_private,
        },
      });
    },
    [fetchMakeEndpointsPublic.fulfilled]: (state, action) => {
      const {lairId, isPublic} = action.meta.arg;
      filesAdapter.updateOne(state, {
        id: lairId,
        changes: {
          publicEndpoints: isPublic,
        },
      });
    },
  },
});

export const {
  fileAdded,
  fileRemoved,
  filesRemoved,
  fileUpdated,
  filesUpdated,
  fileUpserted,
  filesUpdserted,
  filesLoaded,
  setFilesSearch,
  resetFiles,
  resolveConflict,
  incrementFilesSocketId,
  setIgnoreSNS,
  setLairFileRun,
  resetFetchFileContent,
  upsertFilesSelected,
  removeFilesSelected,
  setLastSelected,
  clearFilesSelected,
  setFileExpanded,
  setFileHasExpanded,
} = filesSlice.actions;

export const selectFilesStatus = (state) => {
  return state.files.status;
};

export const selectFilesSearch = (state) => {
  return state.files.search;
};

export const requestRenameFile = (id, newName) => async(dispatch, getState) => {
  // eager UI changes
  dispatch(updateFileFetchingState(id, true));
  dispatch(fileUpdated({
    id,
    changes: {
      name: newName,
    },
  }));
};

export const addFileToTree = (newFile, id, parentId) => (dispatch, getState) => {
  const state = getState();
  const parentData = selectById(state, parentId);
  dispatch(fileAdded({id, ...newFile}));
  dispatch(fileUpdated({
    id: parentId,
    changes: {
      children: [...parentData.children, id],
    },
  }));
};

export const removeFileFromParent = (id) => (dispatch, getState) => {
  const state = getState();
  const file = selectById(state, id);
  const parent = file.parent;
  const children = selectFilePropById(state, parent, 'children');
  // remove file ID from parent
  dispatch(fileUpdated({
    id: parent,
    changes: {
      children: children.filter(child => child !== id),
    },
  }));
};

export const removeFileFromTree = (id) => (dispatch, getState) => {
  dispatch(removeFileFromParent(id));
  dispatch(fileRemoved(id));
};

export const updateFileFetchingState = (id, value) => (dispatch) => {
  dispatch(fileUpdated({
    id,
    changes: {
      fetching: value,
    },
  }));
};

export const openFile = (id) => (dispatch, getState) => {
  const state = getState();
  const file = selectById(state, id);
  const lairId = selectCurrentLairId(state);
  if (!file) return;
  const type = (file.parent === lairId && ['.triggers', '.secrets', '.env'].includes(file.name))
    ? getFileNameExtension(file.name)
    : file.changed ? 'file-comparison' : 'file';
  const filesLairRootId = selectFilesLairRootId(state, id);
  if (lairId !== filesLairRootId) dispatch(openLair(filesLairRootId));
  dispatch(addTab({id, type, lairId: filesLairRootId}));
  dispatch(setEditorFocused({id, type, lairId: filesLairRootId}));
};

const selectAllFiles = filesAdapter.getSelectors((state) => state.files);
const {selectAll, selectById} = selectAllFiles;
export {selectAll as selectAllFiles, selectById as selectFileById};

export const selectFilePropById = (state, id, prop) => {
  const file = selectById(state, id);
  if (!prop || !file) return;
  return file[prop];
};

export const selectRoot = (state) => {
  const lairView = selectLairView(state);
  if (!lairView) {
    // global view - return main root
    return selectWorkspaceRoot(state);
  } else {
    // lair view - return file with lair file as root
    return selectLairViewFile(state);
  }
};

export const selectRootId = (state) => {
  const lairView = selectLairView(state);
  const lairIsProd = selectLairIsProd(state);
  if (!lairView) {
    // global view - return main root id
    return selectWorkspaceRootId(state);
  } else {
    // lair view - return file with lair file as root id
    if (lairIsProd) {
      const prodFileId = selectLairViewProdFileId(state);
      if (prodFileId) return prodFileId;
    } else {
      return selectLairViewFileId(state);
    }
  }
};

export const selectWorkspaceRoot = (state) => {
  const workspace = selectSelectedWorkspace(state);
  return workspace && selectById(state, workspace.id);
};

export const selectWorkspaceRootId = (state) => {
  const workspaceRoot = selectWorkspaceRoot(state);
  return workspaceRoot && workspaceRoot.id;
};

export const selectFirstFileWithAllProps = (state, props) => {
  const files = selectTreeFiles(state);
  const filesData = Object.values(files);
  let i = 0;
  while (i < filesData.length) {
    const file = filesData[i];
    if (fileHasAllProps(state, file.id, props)) return file;
    i++;
  }
  return null;
};

export const selectFilesWithAllProps = (state, props) => {
  const files = selectTreeFiles(state);
  return Object.values(files).reduce((acc, file) => {
    // traverse props and see if data.prop strictly equals each prop
    if (fileHasAllProps(state, file.id, props)) acc[file.id] = file;

    return acc;
  }, {});
};

// filter is a callback that should return boolean
// root = optional to start from that file and traverse down
export const selectFilesWithFilter = (state, filter, root, method = 'dfs', ...args) => {
  const files = {};
  if (!root) root = selectWorkspaceRootId(state);
  const cb = (fileId) => {
    const file = selectById(state, fileId);
    if (filter(file)) files[file.id] = file;
  };
  if (method === 'dfs') {
    traverseFileTree(state, root, cb, ...args);
  } else {
    traverseFileTreeBreadth(state, root, cb, ...args);
  }
  return files;
};

export const selectFileDescendantsWithFilter = (
  state,
  id,
  filterFn = () => true,
  excludeIds = [],
) => {
  const file = selectById(state, id);
  const files = [];
  const pathList = [];
  const processChildren = children => {
    children.forEach(child => {
      if (excludeIds.includes(child)) return;
      const childFile = selectById(state, child);
      if (!childFile) return;
      pathList.push(childFile.name);
      if (filterFn(childFile)) {
        files.push({
          ...childFile,
          path: pathList.join('/'),
        });
      }
      if (childFile.children) processChildren(childFile.children);
      pathList.pop();
    });
  };
  processChildren(file.children);
  return files;
};

export const selectFileChildrenEntitiesByIdStringified = (state, id) => {
  const file = selectById(state, id);
  const children = [];
  if (file?.children) {
    file.children.forEach(child => children.push(selectById(state, child)));
  }
  return JSON.stringify(children.filter(child => !!child));
};

const selectSortedFileChildren = (state, id) => {
  let sortedFileChildrenIds;
  const file = selectById(state, id);
  if (!file) return JSON.stringify([]);
  const isLair = file.is_lair_root;
  const children = file.children;
  if (children && children.length > 0) {
    const directories = [];
    const files = [];
    const specialFiles = [];
    let skipFile = false;
    children.forEach(child => {
      const childFile = selectById(state, child);
      if (childFile) {
        if (isLair) {
          if (['.triggers', '.secrets', '.env'].includes(childFile.name)) {
            specialFiles.push(childFile);
            skipFile = true;
          }
        }
        if (!skipFile) {
          if (childFile.is_directory) directories.push(childFile);
          else files.push(childFile);
        } else skipFile = false;
      }
    });
    const byName = (a, b) => a.name > b.name ? 1 : -1;
    const allFileChildren = [
      ...specialFiles.sort(byName),
      ...directories.sort(byName),
      ...files.sort(byName),
    ];
    sortedFileChildrenIds = allFileChildren.map(child => child.id);
  }
  return JSON.stringify(sortedFileChildrenIds || children);
};

export const selectFileChildrenById = (state, id) => {
  const file = selectById(state, id);
  return file?.children;
};

export const makeSelectFileView = (state) => {
  return createSelector(
    [selectById, selectCurrentLairId, selectFocusedId, selectSortedFileChildren, (state) => selectAllSelectedFileIds(state, true), (state) => selectDeletableFiles(state, true)],
    (file, lairId, focusedId, sortedFileChildren, selectedFileIds, deletableSelectedFileIDs) => {
      file = file || {};
      const isLair = file.is_lair_root;
      const isDirectory = file.is_directory;
      const fileName = file.name;
      const changed = file.changed;
      const {id, editable, parent, error} = file;
      const fileExtension = getFileNameExtension(fileName || '');
      let icon;
      if (fileExtension) {
        const info = FILE_EXTENSIONS[fileExtension];
        if (info) {
          icon = info.icon;
        }
      } else if (isLair) icon = 'lair';
      const selectedFileIdsParsed = JSON.parse(selectedFileIds);
      const fileIsSelected = selectedFileIdsParsed.includes(id);
      const isMultipleFilesSelected = Object.entries(selectedFileIdsParsed).length > 1;
      let downloadLink;
      if (!isDirectory) downloadLink = buildRequestUrl(`/files/${buildFilePath(state, id, true)}?contents=True`);
      const parentExpanded = selectFilePropById(state, file.parent, 'expanded');
      const parentIsLair = selectFilePropById(state, file.parent, 'is_lair_root');
      const expanded = file.expanded;
      const hasExpanded = file.hasExpanded;
      const forceShowChildren = parentExpanded || parentIsLair;

      return {
        id,
        fileName,
        icon,
        parent,
        isLair,
        isDirectory,
        editable,
        fileChildren: sortedFileChildren,
        fileExtension,
        error,
        changed,
        open: focusedId && id === focusedId,
        isTriggerFile: parent === lairId && fileExtension === 'triggers',
        fileIsSelected,
        isMultipleFilesSelected,
        deletableSelectedFileIDs,
        downloadLink,
        showChildren: (expanded || (!expanded && hasExpanded) || isLair || forceShowChildren),
        expanded,
      };
    },
  );
};

export const fileHasAllProps = (state, id, props) => {
  const filesData = selectById(state, id);
  if (!filesData) return;
  return Object.entries(props).reduce((acc, props) => {
    const [key, value] = props;
    return acc && filesData[key] === value;
  }, true);
};

// TODO: implement more file selector helpers
// export const selectAllFilesWithAnyProp( state, props ) {}
// export const selectFirstFileWithAnyProps( state, props ) {}
// export const selectFirstFileWithFilter(state, filter) {}

export const selectTreeFiles = (state, fullTree = true, shouldStop = () => false, includeRoot = true, root) => {
  if (!root) {
    if (fullTree) root = selectWorkspaceRoot(state);
    else root = selectRoot(state);
  }
  const files = {};
  if (root) {
    traverseFileTree(
      state,
      root.id,
      (id) => { files[id] = selectById(state, id); },
      shouldStop,
      includeRoot,
    );
  }
  return files;
};

export const selectTreeFileIDsList = (state, sorted = false) => {
  const root = selectRoot(state);
  const includeRoot = false;
  const shouldStop = () => false;
  const files = [];
  if (root) {
    traverseFileTree(
      state,
      root.id,
      (id) => { files.push(id); },
      shouldStop,
      includeRoot,
      sorted,
      true,
    );
  }
  return files;
};

export const getFileDescendantIds = (state, id) => {
  const list = [];
  const cb = file => list.push(file.id);
  traverseFileTree(state, id, cb);
  return list;
};

export const traverseFileTree = (state, root, cb, shouldStop = () => false, includeRoot = true, sorted = false, print) => {
  const file = selectById(state, root);
  if (!file) return;
  if (includeRoot) cb(root);

  if (shouldStop(file)) return;
  let fileChildren = file.children;
  if (sorted) {
    fileChildren = JSON.parse(selectSortedFileChildren(state, file.id), file.is_lair_root);
  }
  fileChildren.forEach(child => {
    // bug patch for case where child id matches file id
    if (child !== root) traverseFileTree(state, child, cb, shouldStop, true, sorted, print);
  });
};

export const traverseFileTreeBreadth = (state, root, cb, numOfLevels, level = [root]) => {
  if (!root || level.length === 0 || numOfLevels === 0) return;
  const nextLevel = [];
  level.forEach(id => {
    const file = selectById(state, id);
    cb(id);
    file && file.children.forEach(child => {
      nextLevel.push(child);
    });
  });
  traverseFileTreeBreadth(state, root, cb, numOfLevels && numOfLevels - 1, nextLevel);
};

export const selectAllLairFiles = (state) => {
  // prod lair files are not children of the workspace, so we use selectAll and filter
  return Object.values(selectTreeFiles(state)).filter(file => file.is_lair_root);
};

export const selectAllLairFileIds = (state) => {
  const lairFiles = selectAllLairFiles(state);
  return lairFiles.map(file => file.id);
};

export const selectAllLairFilesStringified = (state) => {
  // prod lair files are not children of the workspace, so we use selectAll and filter
  return JSON.stringify(selectAllLairFiles(state));
};

export const selectAllDevLairFiles = (state) => {
  return Object.values(selectTreeFiles(state)).filter(file => file.is_lair_root && getFileNameExtension(file.name) !== 'prod');
};

export const selectAllProdLairFiles = (state) => {
  return Object.values(selectTreeFiles(state)).filter(file => file.is_lair_root && getFileNameExtension(file.name) === 'prod');
};

export const selectAllProdFiles = (state) => {
  const lairViewProdFile = selectLairViewProdFileId(state);
  let prodFiles = {};
  if (lairViewProdFile) {
    prodFiles = selectTreeFiles(state, undefined, undefined, true, lairViewProdFile);
  }
  return Object.values(prodFiles);
};

export const selectFilesSearched = (state) => {
  const searchTerm = selectFilesSearch(state);
  const lairView = selectLairView(state);
  let treeFiles;
  if (!lairView) {
    // treeFiles = selectTreeFiles(state);
    treeFiles = selectFilesWithFilter(state, (file) => !file.is_directory);
  } else {
    treeFiles = selectFilesWithFilter(state, (file) => !file.is_directory, lairView);
  }
  const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['path'],
    // findAllMatches: true,
    shouldSort: true,
    ignoreLocation: true,
  };
  const treeFilesWithPath = Object.values(treeFiles).map(file => {
    return {
      ...file,
      path: buildFilePath(state, file.id, false),
    };
  });
  const fuse = new Fuse(treeFilesWithPath, options);
  const hiddenFileNames = Object.entries(HIDDEN_FILE_NAMES)
    .filter(entry => entry[1])
    .map(entry => entry[0]);
  const searchResult = fuse.search(searchTerm).filter(file => {
    return !hiddenFileNames.some(name => file.item.path.includes(name));
  });
  return JSON.stringify(searchResult.map(data => data.item.id));
};

export const selectLairViewFile = (state) => {
  const lairView = selectLairView(state);
  if (!lairView) return null;
  const file = selectById(state, lairView);
  if (!file || !file.is_lair_root) return null;
  return file;
};

export const selectFilesLairRootId = (state, id) => {
  let lairRoot = null;
  traverseFileTreeUpward(state, id, (file) => {
    if (file.is_lair_root) lairRoot = file.id;
  });
  return lairRoot;
};

export const selectShouldStopTraversing = (state, id) => {
  const lairView = selectLairView(state);
  const file = selectById(state, id);
  if (!lairView) return !file || file.is_lair_root;
  return false;
};

export const selectFilePathById = (state, id) => {
  let path = '';
  traverseFileTreeUpward(state, id, (file) => {
    path = '/' + file.name + path;
  }, (file) => file.is_workspace_root);
  return path;
};

export const traverseFileTreeUpward = (
  state,
  id,
  cb = () => {},
  shouldStop = () => false,
) => {
  const file = selectById(state, id);
  if (!file) {
    return;
  }
  cb(file);
  if (shouldStop(file) || !file.parent) {
    return {...file};
  }
  return traverseFileTreeUpward(state, file.parent, cb, shouldStop);
};

/** Construct absolute file path from workspace to {fileId}.
 * @param {string} fileId
 * @param {boolean} encode - convert / to %2F for use in request
 * @param {boolean} lowerCase - convert filePath to all lowerCase
*/
export const buildFilePath = (state, fileId, encode = true, lowerCase = true) => {
  const pathList = [];
  const cb = (file) => {
    pathList.unshift(file.name);
  };
  const shouldStop = (file) => file.is_workspace_root;
  traverseFileTreeUpward(state, fileId, cb, shouldStop);
  let filePath = pathList.join('/');
  if (lowerCase) filePath = filePath.toLowerCase();
  if (encode) filePath = encodeFilePath(filePath);
  return filePath;
};

/** Construct file path to {fileId} relative to workspace root.
 * @param {string} fileId
 * @param {boolean} encode - convert / to %2F for use in request
 * @param {boolean} lowerCase - convert filePath to all lowerCase
*/
export const buildWorkspaceRelativeFilePath = (state, fileId, encode = true, lowerCase = true) => {
  const pathList = [];
  const cb = (file) => {
    pathList.unshift(file.name);
  };
  const shouldStop = (file) => file.is_lair_root;
  traverseFileTreeUpward(state, fileId, cb, shouldStop);
  let filePath = pathList.join('/');
  if (lowerCase) filePath = filePath.toLowerCase();
  if (encode) filePath = encodeFilePath(filePath);
  return filePath;
};

export const addFile = (fileName, isDirectory, parent, addingFile, setPending, setAddingFile, setError, isMenuItem = false, isEncrypted) => async(dispatch, getState) => {
  const state = getState();
  // analytics
  const parentIsLair = selectFilePropById(state, parent, 'is_lair_root');
  if (parentIsLair) {
    if (['README.md', '.secrets', '.env'].includes(fileName)) {
      analytics.track(analytics.specialFileAddedEvent, {name: fileName});
    }
  }
  const id = uuidv4();
  const handleError = (msg = 'an error has occured') => {
    if (setAddingFile) {
      setAddingFile({
        ...addingFile,
        show: true,
      });
    }
    if (setError) setError(msg);
    setPending(null);
  };
  const {valid, error} = selectValidateFileName(state, parent, id, fileName, isMenuItem);
  if (valid) {
    setPending({
      name: fileName,
      is_directory: isDirectory,
    });
    if (setAddingFile) {
      setAddingFile({
        ...addingFile,
        show: false,
      });
    }
    if (setError) setError('');
    const data = await dispatch(fetchAddFile({parent: parent, name: fileName, is_directory: isDirectory, id}));
    if (isEncrypted) {
      await dispatch(syncFiles({pullReady: true}));
    }

    if (!data.error) {
      if (setAddingFile) setAddingFile(null);
      setPending(null);
      if (setError) setError('');
      if (!isDirectory) {
        if (isElectron && isEncrypted) {
          let secretsId;
          let retries = 10;
          while (!secretsId && retries > 0) { // have to wait for secrets file to come in and derive the secretsId
            const state = getState();
            secretsId = selectLairSecretsFileId(state);
            await new Promise((resolve, reject) => setTimeout(resolve, 1000));
            retries--;
          }
          dispatch(openFile(secretsId)); // the original uuid generated in electron is not same uuid that is returned from CLI
        } else {
          dispatch(openFile(id));
        }
      }
    } else {
      handleError();
    }
  } else {
    handleError(error || 'Invalid file name');
  }
};

export const renameFile = (id, newName, setFilePending, setRenameError, setRenaming) => async(dispatch, getState) => {
  const state = getState();
  const parent = selectFilePropById(state, id, 'parent');
  const {valid, error} = selectValidateFileName(state, parent, id, newName);
  if (valid) {
    setFilePending({name: newName});
    setRenameError('');
    setRenaming(false);
    const data = await dispatch(fetchRenameFile({id: id, name: newName}));
    if (!data.error) {
      setRenaming(false);
      setFilePending(null);
      setRenameError('');
    } else {
      setRenaming(true);
      setRenameError('error occurred');
      setFilePending(null);
    }
  } else {
    // set error invalidated name
    setRenaming(true);
    setRenameError(error || 'Invalid file name');
    setFilePending(null);
  }
};

export const uploadImgFile = (imgFile, parent) => async(dispatch) => {
  const id = uuidv4();
  const arrayBuffer = await imgFile.arrayBuffer();
  const content = arrayBufferToBase64(arrayBuffer);

  await dispatch(fetchAddFile({
    id,
    parent,
    name: imgFile.name,
    is_directory: false,
    content,
  }));
};

export const isChildNotUnique = (state, parentId, childId, fileName = null, addFile = false) => {
  const parent = selectById(state, parentId);
  const childFile = selectById(state, childId);
  if (parent.children.length === 0) return false;
  let notUnique = false;
  parent.children.forEach(child => {
    const file = selectById(state, child);
    const name = addFile ? fileName : childFile.name;
    if (file?.name === name) {
      notUnique = true;
    }
  });
  return notUnique;
};

export const isFileDescendantOfFile = (state, dropTarget, fileId) => {
  let result = false;
  traverseFileTreeUpward(state, dropTarget, (file) => {
    // file = each file at every iteration
    if (file.id === fileId) result = true;
  });
  return result;
};

export const validateMoveFile = (parent, targetId, fileId) => (dispatch, getState) => {
  const state = getState();
  // can't move file within the same folder
  if (parent === targetId) return false;
  // can't move a file to a folder that has a file with the same name
  if (isChildNotUnique(state, targetId, fileId)) return false;
  // can't move folder to a folder that is it's own descendant
  if (isFileDescendantOfFile(state, targetId, fileId)) return false;
  return true;
};

export const fileUpdateRemoved = (filePath) => (dispatch, getState) => {
  const state = getState();
  const id = selectFileIdByPath(state, filePath);
  const shouldIgnore = selectShouldIgnoreFileUpdate(state, filePath, 'file removed');
  if (!shouldIgnore) {
    if (id) {
      dispatch(removeFileFromParent(id));
      dispatch(setIgnoreSNS(null));
    }
  }
};

export const fileUpdateChanged = (filePath) => (dispatch, getState) => {
  const state = getState();
  const id = selectFileIdByPath(state, filePath);
  const isProdFile = filePath.includes('.prod');
  const currentLairIsProd = selectLairIsProd(state);
  const shouldIgnore = selectShouldIgnoreFileUpdate(state, filePath, 'file changed');
  if (!shouldIgnore) {
    if (id) {
      dispatch(markFileDirtyByPath(filePath));
    } else {
      if (isProdFile) {
        if (currentLairIsProd) dispatch(fetchProdFiles());
      } else {
        const closestFile = selectNearestParentFile(state, filePath);
        if (closestFile) {
          dispatch(fetchFileChildren({id: closestFile}));
        } else {
          dispatch(fetchFiles());
        }
      }
    }
    dispatch(setIgnoreSNS(null));
  }
};

export const expandFileAncestorDirectories = (id) => (dispatch, getState) => {
  const state = getState();
  const cb = (file) => {
    if (file.is_directory) dispatch(setFileExpanded({id: file.id, value: true}));
  };

  const shouldStop = (file) => {
    return file.is_lair_root;
  };

  traverseFileTreeUpward(state, id, cb, shouldStop);
};

export const selectShouldIgnoreFileUpdate = (state, fileUpdateFilePath, fileUpdateType) => {
  const ignoreSNS = selectIgnoreSNS(state);
  return ignoreSNS && (
    ignoreSNS === 'all' || (
      ignoreSNS.filePath === fileUpdateFilePath && fileUpdateType === ignoreSNS.type));
};

export const selectIgnoreSNS = (state) => {
  return state.files.ignore_sns;
};

export const selectValidateFileName = (state, parent, fileId, fileName, isMenuItem) => {
  const response = {valid: true, error: null};
  if (!isValidFileName(fileName)) {
    response.valid = false;
    response.error = 'Invalid file name';
  } else if (isChildNotUnique(state, parent, fileId, fileName, true)) {
    response.valid = false;
    response.error = 'Name already exists';
  } else if (!isMenuItem && ['.secrets', '.env', '.triggers', '.wayscript'].includes(fileName)) {
    response.valid = false;
    response.error = 'Reserved file name';
  }
  return response;
};

export const selectLairViewFileId = (state) => {
  const lairView = selectLairView(state);
  if (!lairView) return null;
  const lairFile = selectFirstFileWithAllProps(state, {id: lairView, is_lair_root: true});
  return lairFile && lairFile.id;
};

export const selectLairSecretsFileId = (state) => {
  const root = selectLairViewFileId(state);
  const filter = (file) => file && file.name === '.secrets';
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file.id;
};

export const selectLairEnvFileId = (state) => {
  const root = selectLairViewFileId(state);
  const filter = (file) => file && file.name === '.env';
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file.id;
};

export const selectLairReadMeFile = (state) => {
  const root = selectLairViewFileId(state);
  const filter = (file) => file && file.name === 'README.md';
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file;
};

export const selectLairReadMeFileId = (state) => {
  const file = selectLairReadMeFile(state);
  return file && file.id;
};

export const selectIntegrationsFileId = (state) => {
  const root = selectWorkspaceRootId(state);
  const filter = (file) => {
    return file && file.name === 'integrations';
  };
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 3))[0];
  return file && file.id;
};

export const selectIsEncryptedFileId = (state, id) => {
  const secretsId = selectLairSecretsFileId(state);
  const integrationsId = selectIntegrationsFileId(state);
  return [secretsId, integrationsId].includes(id);
};

// use this to check if file is an encrypted type before it is added to store
export const selectIsEncryptedFile = (state, name, parent) => {
  if (name === '.secrets') {
    // check if parent is lair
    const lairFile = selectLairViewFile(state);
    return parent === lairFile.id;
  } else if (name === 'integrations') {
    // check if parent is '.wayscript'
    const parentFile = selectById(parent);
    return parentFile?.name === '.wayscript';
  }
  return false;
};

export const selectTriggersFileId = (state) => {
  const root = selectLairView(state);
  const filter = (file) => {
    return file && file.name === '.triggers';
  };
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file.id;
};

export const selectProdTriggersFileId = (state) => {
  const root = selectLairViewProdFileId(state);
  if (!root) return;
  const filter = (file) => {
    return file && file.name === '.triggers';
  };
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file.id;
};

export const selectCurrentTriggersFileId = (state) => {
  const root = selectCurrentLairId(state);
  const filter = (file) => {
    return file && file.name === '.triggers';
  };
  const file = Object.values(selectFilesWithFilter(state, filter, root, 'bfs', 2))[0];
  return file && file.id;
};

export const selectTriggerFiles = (state, lairFiles) => {
  return JSON.parse(lairFiles).map(lair => {
    const files = selectFirstFileWithAllProps(state, {name: '.triggers', parent: lair.id});
    return files;
  });
};

export const isFileDescendantOfProdFile = (state, id) => {
  let isDescendant = false;
  const cb = (file) => { file.name.includes('.prod') ? isDescendant = true : isDescendant = false; };
  const shouldStop = (file) => file.name.includes('.prod');
  traverseFileTreeUpward(state, id, cb, shouldStop);
  return isDescendant;
};

export const selectWayscriptFolder = (state) => {
  const workspace = selectWorkspaceRootId(state);
  return workspace && selectFirstFileWithAllProps(state, {name: '.wayscript', parent: workspace});
};

export const selectSettingsFile = (state) => {
  const wayscriptFolder = selectWayscriptFolder(state);
  return wayscriptFolder && selectFirstFileWithAllProps(state, {name: 'settings', parent: wayscriptFolder.id});
};

export const selectSettingsFileId = (state) => {
  const file = selectSettingsFile(state);
  if (!file) return null;
  return file.id;
};

export const selectWayScriptImgFolder = (state) => {
  const wayscriptFolder = selectWayscriptFolder(state);
  return wayscriptFolder && selectFirstFileWithAllProps(state, {name: 'img', parent: wayscriptFolder.id});
};

export const selectWorkspaceImgFile = (state) => {
  const settingsFile = selectSettingsFile(state);
  if (!settingsFile || !settingsFile.content) return null;
  const icon = JSON.parse(settingsFile.content).icon;
  const imgFolderId = selectWayScriptImgFolder(state).id;
  const imgFile = selectFirstFileWithAllProps(state, {
    name: icon,
    parent: imgFolderId,
  });
  if (!imgFile) return null;
  return JSON.stringify(imgFile);
};

export const selectFileIdByPath = (state, path) => {
  let fileId = selectWorkspaceRootId(state);
  const pathList = path.split('/');
  let i = 0;
  while (i < pathList.length) {
    const file = selectById(state, fileId);
    if (!file) return;
    const fileNameCheck = pathList[i];
    const childrenList = i === 0 ? [fileId] : file.children;

    const foundFile = childrenList.reduce((acc, child) => {
      const childFile = selectById(state, child);
      if (!childFile) return acc;
      return childFile.name === fileNameCheck ? child : acc;
    }, null);

    if (foundFile) {
      fileId = foundFile;
    } else {
      return null;
    }

    i++;
  }

  return fileId;
};

export const selectAlertsFile = (state) => {
  const lairView = selectLairView(state);
  // selected lair wayscript folder
  const wayscriptFolder = selectFirstFileWithAllProps(state, {name: '.wayscript', parent: lairView});
  const alertsFile = wayscriptFolder && selectFirstFileWithAllProps(state, {name: 'alerts', parent: wayscriptFolder.id});

  // TODO: fix mock files
  if (!IS_MOCK) return alertsFile;
  else return selectFirstFileWithAllProps(state, {name: 'alerts'});
};

export const selectAlertsFileId = (state) => {
  const alertsFile = selectAlertsFile(state);
  if (!alertsFile) return null;
  return alertsFile.id;
};

export const selectAlertsFileAsString = (state) => {
  const file = selectAlertsFile(state);
  if (!file) return null;
  return JSON.stringify(file);
};

export const selectIsFileInTree = (state, id) => {
  const files = selectTreeFiles(state);
  return !!files[id];
};

export const selectIsLairPublished = (state) => {
  const lairView = selectLairView(state);
  const endpoint = selectFilePropById(state, lairView, 'is_public');
  return endpoint;
};

export const selectFilePathIsGlobal = (state, filePath) => {
  // filePath has format /{workspace}/{subpath}
  // if {subpath} is the file name, must be a global file
  if (typeof filePath !== 'string') return false;
  const pathSplit = filePath.split('/');
  return filePath[0] === '/' && pathSplit.length === 3;
};

// helper to check if user is dragging in file data from their local machine.
export const containsFiles = (event) => {
  if (event?.dataTransfer && event.dataTransfer.types) {
    for (let i = 0; i < event.dataTransfer.types.length; i++) {
      if (event.dataTransfer.types[i] === 'Files') {
        return true;
      }
    }
  }
  return false;
};

export const traverseFileDataDirectory = (entry) => {
  if (entry.isFile) {
    return Promise.all([getFile(entry)]);
  }
  const reader = entry.createReader();
  return new Promise((resolve, reject) => {
    const iterationAttempts = [];

    function readEntries() {
      reader.readEntries((entries) => {
        if (!entries.length) {
          resolve(Promise.all(iterationAttempts));
        } else {
          iterationAttempts.push(Promise.all(entries.map((entry) => {
            if (entry.isFile) {
              return getFile(entry);
            }
            return traverseFileDataDirectory(entry);
          })));
          readEntries();
        }
      }, error => reject(error));
    }
    readEntries();
  });

  function getFile(entry) {
    return new Promise(resolve => {
      entry.file(file => {
        resolve({
          file: file,
          name: entry.name,
          fullPath: entry.fullPath,
        });
      });
    });
  }
};

export const selectFilesSocketId = (state) => {
  return state.files.socket_id;
};

export const selectNearestParentFile = (state, filePath) => {
  filePath = filePath.split('/');
  let fileId;
  while (!fileId && filePath.length) {
    filePath.pop();
    fileId = selectFileIdByPath(state, filePath.join('/'));
  }
  return fileId;
};

export const selectProdLairTriggers = (state) => {
  const prodLairId = selectLairViewProdFileId(state);
  const triggerFile = selectFirstFileWithAllProps(state, {name: '.triggers', parent: prodLairId});
  return JSON.stringify(triggerFile);
};

export const selectHasPublicEndpoints = (state) => {
  const lairFile = selectLairViewFile(state);
  return lairFile && lairFile.publicEndpoints ? lairFile.publicEndpoints : null;
};

export const selectLairRunIsAvailable = (state) => {
  const lairId = selectCurrentLairId(state);
  const currentLair = selectById(state, lairId);
  return currentLair && currentLair.run;
};

export const selectShouldFetchFileContentById = (state, id) => {
  const file = selectById(state, id);
  if (!file) return false;
  if (file.dirty || !file.status_content || file.status_content?.includes('retry-')) return true;
  return false;
};

export const selectAllSelectedFiles = (state, stringify = false) => {
  return stringify ? JSON.stringify(state.files.selected) : state.files.selected;
};

export const selectAllSelectedFileIds = (state, stringify = false) => {
  const fileIds = Object.keys(state.files.selected);
  return stringify ? JSON.stringify(fileIds) : fileIds;
};

export const selectAllSelectedFilesNames = (state) => {
  const selectedFileIds = Object.keys(selectAllSelectedFiles(state));
  return selectedFileIds.map(id => selectFilePropById(state, id, 'name'));
};

export const selectAllDeletableSelectedFilesNames = (state) => {
  const selectedDeletableFileIds = selectDeletableFiles(state);
  return selectedDeletableFileIds.map(id => selectFilePropById(state, id, 'name'));
};

export const selectFileIsSelected = (state, id) => {
  return selectAllSelectedFiles(state).hasOwnProperty(id);
};

export const selectLastSelectedFile = (state) => {
  return state.files.lastSelected;
};

export const handleShiftClickFile = (e, id) => async(dispatch, getState) => {
  const state = getState();
  const filesFlat = selectTreeFileIDsList(state, true);
  const selectedFiles = selectAllSelectedFiles(state);
  const lastSelected = selectLastSelectedFile(state);
  const lastSelectedIndex = filesFlat.indexOf(lastSelected);
  const currentSelectedIndex = filesFlat.indexOf(id);

  let start;
  let end;
  if (lastSelectedIndex < currentSelectedIndex) {
    start = lastSelectedIndex;
    end = currentSelectedIndex;
  } else {
    start = currentSelectedIndex;
    end = lastSelectedIndex;
  }

  // clear all that have lastSelected
  const clearSelections = Object.entries(selectedFiles).filter(entry => {
    const [, anchor] = entry;
    return anchor === lastSelected;
  });

  clearSelections.forEach(selection => {
    dispatch(removeFilesSelected(selection[0]));
  });

  while (start <= end) {
    dispatch(upsertFilesSelected({key: filesFlat[start], value: lastSelected}));
    start += 1;
  }
};

export const selectFileIsDeletable = (state, id) => {
  const file = selectById(state, id);
  if (!file) return false;
  const filePath = buildFilePath(state, file.id, false);
  const lairId = selectCurrentLairId(state);
  const isProdDescendant = isFileDescendantOfProdFile(state, id);
  const parent = file.parent;
  const fileName = file.name;
  const fileExtension = getFileNameExtension(fileName || '');
  const isTriggerFile = parent === lairId && fileExtension === 'triggers';
  const isWayscriptFolder = parent === lairId && fileExtension === 'wayscript';
  const isDescendantOfWayscriptFolder = filePath.includes('.wayscript');
  // check is root ?
  return !isProdDescendant && !isTriggerFile && !isWayscriptFolder && !isDescendantOfWayscriptFolder;
};

export const selectDeletableFiles = (state, stringify = false) => {
  const selectFileIDs = selectAllSelectedFileIds(state);
  const ignoreFiles = [];
  const selectFileIDsWithPath = selectFileIDs.map((id) => {
    return {
      id,
      path: buildFilePath(state, id, false),
    };
  });
  selectFileIDsWithPath.sort((a, b) => a.path > b.path ? 1 : -1);

  const directoryPaths = [];
  selectFileIDsWithPath.forEach((entry) => {
    const isSubPathOfDirectoryPath = directoryPaths.reduce((acc, directoryPath) => {
      return acc || entry.path.indexOf(directoryPath + '/') === 0;
    }, false);
    if (isSubPathOfDirectoryPath) ignoreFiles.push(entry.id);
    const file = selectById(state, entry.id);
    if (file?.is_directory) {
      directoryPaths.push(entry.path);
    }
  });

  const processFile = (id) => {
    const isDeletable = selectFileIsDeletable(state, id);
    if (!isDeletable) ignoreFiles.push(id);
  };
  selectFileIDs.forEach(processFile);
  const filesToDelete = selectFileIDs.filter(f => !ignoreFiles.includes(f));
  return stringify ? JSON.stringify(filesToDelete) : filesToDelete;
};

export const selectShouldFetchProdFiles = (state) => {
  const currentLair = state.lairs.view;
  const prodLoaded = selectFilePropById(state, currentLair, 'prod_loaded');
  const prodDirty = selectFilePropById(state, currentLair, 'prod_dirty');
  const prodName = selectDeployedLairName(state);
  return (!prodLoaded || prodDirty) && prodName;
};

export default filesSlice.reducer;
