import React from 'react';
import {Link, Text} from '@chakra-ui/react';

export const integrationReadmes = {
  'new_relic': {
    img: '/new_relic_readme.jpg',
    title: 'Create an integration with New Relic',
    body: <>
      <Text>Use New Relic to monitor infrastructure and/or applications within your WayScript Lairs. Simply enter your New Relic account ID and license key, which you can copy from the WayScript partner page found <Link href='https://one.newrelic.com/external-install' target='_blank'>here</Link>, to connect your account and that’s it!</Text>
      <Text mt={4}>Don’t have a New Relic account? <Link href='https://newrelic.com/' target='_blank'>Create one</Link></Text>
    </>,
  },
  'default': {
    img: '/blank_lair_readme.jpg',
    title: 'Create an integration',
    body: <>
      <Text>Use this integration to monitor activity within your WayScript Lairs. Simply enter your API Key to connect your New Relic account and that’s it!</Text>
    </>,
  },
};

export const getIntegrationReadme = (type) => {
  switch (type) {
  case 'new_relic':
    return integrationReadmes[type];
  default:
    return integrationReadmes.default;
  }
};

export const integrationCategories = {
  'monitoring': {
    id: 'monitoring',
    display: 'Monitoring',
    integrations: ['new_relic'], // integration types that are under this category
  },
};
