import React from 'react';
import {SVG} from './SVG.js';
import {
  ICON_USER_PATH,
  ICON_TEAMS_PATH,
  ICON_COG_PATHS,
  ICON_DOCS_PATHS,
  ICON_BLOG_PATH,
  ICON_MARKETPLACE_PATH,
  ICON_EDIT_PATH,
  ICON_DELETE_PATH,
  ICON_COPY_PATH,
  ICON_SHARE_PATH,
  ICON_LEFT_PATH,
  ICON_RIGHT_PATH,
  ICON_X_PATH,
  ICON_LOG_PATH,
  ICON_ZOOMOUT_PATH,
  ICON_ZOOMIN_PATH,
  ICON_UNDO_PATH,
  ICON_REDO_PATH,
  ICON_SEARCH_PATH,
  ICON_BRACKET_PATH,
  ICON_LOGIC_PATH,
  ICON_COMMENT_PATH,
  ICON_LOCK_PATH,
  ICON_FIT_PATHS,
  ICON_DOWNLOAD_PATH,
  ICON_ARROWLEFT_PATH,
  ICON_ARROWRIGHT_PATH,
  ICON_ARROWUP_PATH,
  ICON_ARROWDOWN_PATH,
  ICON_VIEW_PATH,
  ICON_HIDE_PATH,
  ICON_LIGHTENING_PATH,
  ICON_ACTION_PATH,
  ICON_RETURN_PATH,
  ICON_CHEVRON_DOWN_PATH,
  ICON_CHEVRON_RIGHT_PATH,
  ICON_PLUS_PATH,
  ICON_NOTIFICATIONS_PATH,
  ICON_CLOSE_PATH,
  ICON_PACKAGE_PATHS,
  ICON_CODE_PATHS,
  ICON_OPEN_PATH,
  ICON_COLLAPSE_PATH,
  ICON_DRAGHANDLE_PATH,
  ICON_PLAY_PATH,
  ICON_DEBUG_PATH,
  ICON_DEBUGFORWARD_PATH,
  ICON_REFRESH_PATH,
  ICON_INFO_PATH,
  ICON_CAUTION_PATHS,
  ICON_PRIVATE_PATH,
  ICON_OPEN_MODAL_PATH,
  ICON_FILTER_PATH,
  ICON_CHECK_PATH,
  ICON_TABLE_PATH,
  ICON_HELP_PATH,
  ICON_DROPDOWN_ARROW_PATH,
  ICON_FILE_PATH,
  ICON_LOGO_PATH,
  ICON_UPLOAD_PATH,
  ICON_GLOBE_PATH,
  ICON_SYNC_PATHS,
  ICON_FOLDER_PATH,
  ICON_LINK_OUT_PATH,
  ICON_SAVE_AS_PATH,
  ICON_SAVED_PATHS,
  ICON_SAVING_PATHS,
  ICON_FUNCTION_PATHS,
  ICON_BLOCK_PATH,
  ICON_KEY_PATH,
  ICON_DIRECTION_LEFT_PATH,
  ICON_DIRECTION_RIGHT_PATH,
  ICON_DIRECTION_UP_PATH,
  ICON_DIRECTION_DOWN_PATH,
  ICON_DOLLAR_PATH,
  ICON_LAIR_PATH,
  ICON_DEPLOY_PATH,
  ICON_WRENCH_PATH,
  ICON_TREE_PATH,
  ICON_CONTAINER_PATHS,
  ICON_INFINITY,
  ICON_CLI_PATH,
  ICON_FILE_PY_PATHS,
  ICON_WS_PATH,
  ICON_CLOCK_PATH,
  ICON_TERMINAL,
  ICON_SLACK_PATHS,
  ICON_FETCH_PATHS,
  ICON_HTTP_PATHS,
  ICON_SQL_PATHS,
  ICON_HOME_PATH,
  ICON_FILE_JS_PATH,
  ICON_USAGE,
  ICON_TEMPLATE,
} from '../icons';

const IconUser = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-user',
}) => (
  <SVG id={id} width={width} height={height} className={className}>
    <path
      fill={color}
      stroke={color}
      strokeWidth=".2"
      d={ICON_USER_PATH}
    ></path>
  </SVG>
);

const IconTeams = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-teams',
}) => (
  <SVG id={id} width={width} height={height} className={className}>
    <path
      stroke={color}
      d={ICON_TEAMS_PATH[0]}
    ></path>
    <path
      stroke={color}
      d={ICON_TEAMS_PATH[1]}
    ></path>
    <path
      stroke={color}
      d={ICON_TEAMS_PATH[2]}
    ></path>
  </SVG>
);

const IconCog = ({
  id,
  style = {},
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-cog',
}) => (
  <SVG id={id} width={width} height={height} className={className}>
    <path stroke={color} fill="none" d={ICON_COG_PATHS[0]}></path>
    <path stroke={color} fill="none" d={ICON_COG_PATHS[1]}></path>
  </SVG>
);

const IconDocs = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-docs no-fill',
}) => (
  <SVG
    id={id}
    viewBox="0 0 13 16"
    width={width}
    height={height}
    className={className}
  >
    <path stroke={color} fill="none" d={ICON_DOCS_PATHS[0]}></path>
    <path stroke={color} fill="none" d={ICON_DOCS_PATHS[1]}></path>
  </SVG>
);

const IconBlog = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-blog',
}) => (
  <SVG id={id} width={width} height={height} className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth=".2"
      d={ICON_BLOG_PATH}
    ></path>
  </SVG>
);

const IconMarketplace = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon icon-marketplace',
}) => (
  <SVG id={id} width={width} height={height} className={className}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth=".2"
      d={ICON_MARKETPLACE_PATH}
    ></path>
  </SVG>
);

const IconHamburger = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon hamburger',
  handleClick,
}) => (
  <SVG
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}
  >
    <line x1="5" y1="7" x2="15" y2="7" stroke={color} strokeWidth=".7" />
    <line x1="5" y1="10.5" x2="15" y2="10.5" stroke={color} strokeWidth=".7" />
    <line x1="5" y1="14" x2="15" y2="14" stroke={color} strokeWidth=".7" />
  </SVG>
);

const IconEdit = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '28px',
  height = '23px',
  className = 'ws-icon no-fill',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="2 0 20 20"
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1"
      d={ICON_EDIT_PATH}
    ></path>
  </SVG>
);

const IconDelete = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '13px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    viewBox="0 0 18 19"
    style={style}
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1.25"
      d={ICON_DELETE_PATH}
    ></path>
  </SVG>
);

const IconCopy = ({
  style = {},
  id,
  color = 'white',
  width = '22px',
  height = '23px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
  >
    <rect
      fill="none"
      x="5.5"
      y="4.5"
      width="12"
      height="14"
      rx="0.35"
      stroke={color}
      strokeWidth="1.7"
    />
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1"
      d={ICON_COPY_PATH}
    ></path>
  </SVG>
);

const IconShare = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    style={style}
    height={height}
    width={width}
    handleClick={handleClick}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_SHARE_PATH}
    ></path>
  </SVG>
);

const IconLeft = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 14 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_LEFT_PATH}
    ></path>
  </SVG>
);

const IconLock = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG id={id} className={className} height={height} width={width}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_LOCK_PATH}
    ></path>
  </SVG>
);

const IconRight = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 14 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_RIGHT_PATH}
    ></path>
  </SVG>
);

const IconX = ({
  style = {},
  id,
  color = 'white',
  width = '24px',
  height = '17px',
  className = 'ws-icon',
}) => (
  <SVG id={id} className={className} height={height} width={width}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_X_PATH}
    ></path>
  </SVG>
);

const IconLog = ({
  style = {},
  id,
  color = 'white',
  width = '24px',
  height = '17px',
  className = 'ws-icon no-fill',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="1 1 25 25"
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1.5"
      d={ICON_LOG_PATH}
    ></path>
  </SVG>
);

const IconZoomOut = ({
  style = {},
  id,
  color = 'white',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="1 1 23 23"
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_ZOOMOUT_PATH}
    ></path>
    <rect x="7" y="9" width="5" height="1" fill={color} />
  </SVG>
);

const IconZoomIn = ({
  style = {},
  id,
  color = 'white',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="1 1 23 23"
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_ZOOMIN_PATH}
    ></path>
    <rect x="7" y="9" width="5" height="1" fill={color} />
    <rect
      x="10"
      y="7"
      width="5"
      height="1"
      transform="rotate(90 10 7)"
      fill={color}
    />
  </SVG>
);

const IconUndo = ({
  style = {},
  id,
  color = 'white',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_UNDO_PATH}
    ></path>
  </SVG>
);

const IconRedo = ({
  style = {},
  id,
  color = 'white',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_REDO_PATH}
    ></path>
  </SVG>
);

const IconBrackets = ({
  style = {},
  id,
  color = 'white',
  width = '19px',
  height = '23px',
  className = 'ws-icon',
}) => (
  <SVG id={id} className={className} height={height} width={width}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_BRACKET_PATH}
    ></path>
  </SVG>
);

const IconSearch = ({
  style = {},
  id,
  color = 'white',
  width = '26px',
  height = '26px',
  className = 'ws-icon',
}) => (
  <SVG id={id} className={className} height={height} width={width}>
    <path fill={color} stroke="none" d={ICON_SEARCH_PATH}></path>
  </SVG>
);

const IconDownload = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox=".3 1 14.5 15.1"
  >
    <line
      x1="0.5"
      y1="11.64"
      x2="0.5"
      y2="14.64"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="14.1365"
      y1="15.0716"
      x2="-9.53674e-07"
      y2="15.0716"
      stroke={color}
    />
    <line
      x1="13.6365"
      y1="14.6423"
      x2="13.6365"
      y2="11.6423"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_DOWNLOAD_PATH}
    ></path>
  </SVG>
);

const IconLogic = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox=".5 .5 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth=".3"
      d={ICON_LOGIC_PATH}
    ></path>
  </SVG>
);

const IconComment = ({
  style = {},
  id,
  color = 'white',
  width = '28px',
  height = '28px',
  className = 'ws-icon no-fill',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="2 0 25 20"
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1.4"
      d={ICON_COMMENT_PATH}
    ></path>
  </SVG>
);

const IconFit = ({
  style = {},
  id,
  color = 'white',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path stroke={color} fill={color} d={ICON_FIT_PATHS[0]}></path>
    <path stroke={color} fill={color} d={ICON_FIT_PATHS[1]}></path>
    <path stroke={color} fill={color} d={ICON_FIT_PATHS[2]}></path>
    <path stroke={color} fill={color} d={ICON_FIT_PATHS[3]}></path>
  </SVG>
);

const IconMenu = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '25px',
  height = '25px',
  className = 'ws-icon',
  viewBox = '0 0 5 20',
  handleClick,
}) => (
  <SVG
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}
  >
    <circle cx="2.5" cy="16.5" r="2.5" fill={color} />
    <circle cx="2.5" cy="2.5" r="2.5" fill={color} />
    <circle cx="2.5" cy="9.5" r="2.5" fill={color} />
  </SVG>
);

const IconDropdownMenu = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '19px',
  height = '5px',
  className = 'ws-icon',
  handleClick,
  viewBox = '0 0 22 8',
}) => (
  <SVG
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }
    viewBox={ viewBox }>
    <circle cx="16.5" cy="2.5" r="2.5" transform="rotate(-90 16.5 2.5)" fill={ color } />
    <circle cx="2.5" cy="2.5" r="2.5" transform="rotate(-90 2.5 2.5)" fill={ color } />
    <circle cx="9.5" cy="2.5" r="2.5" transform="rotate(-90 9.5 2.5)" fill={ color } />
  </SVG>
);

const IconArrowLeft = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    style={style}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_ARROWLEFT_PATH}
    ></path>
  </SVG>
);

const IconArrowRight = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    style={style}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_ARROWRIGHT_PATH}
    ></path>
  </SVG>
);

const IconArrowUp = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox=".5 0 21.5 21"
    className={className}
    height={height}
    width={width}
    style={style}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_ARROWUP_PATH}
    ></path>
  </SVG>
);

const IconArrowDown = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="-.5 -.5 20.5 20.5"
    className={className}
    height={height}
    width={width}
    style={style}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_ARROWDOWN_PATH}
    ></path>
  </SVG>
);

const IconView = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_VIEW_PATH}
    ></path>
  </SVG>
);

const IconHide = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_HIDE_PATH}
    ></path>
  </SVG>
);

const IconDebug = ({
  id,
  style = {},
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="0 0 19 20"
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_DEBUG_PATH}
    ></path>
  </SVG>
);

const IconNotifications = ({
  style = {},
  id,
  color = 'white',
  width = '38px',
  height = '26px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 17 20"
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="1.25"
      d={ICON_NOTIFICATIONS_PATH}
    ></path>
  </SVG>
);

const IconCode = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '20px',
  height = '20px',
  className = 'ws-icon icon-code',
  handleClick,
}) => (
  <SVG
    viewBox="1 1 18 18"
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}
  >
    <path stroke={color} fill="none" d={ICON_CODE_PATHS[0]}></path>
    <path stroke={color} fill="none" d={ICON_CODE_PATHS[1]}></path>
    <line x1="11.4889" y1="3.10476" x2="8.4889" y2="17.1048" stroke={color} />
  </SVG>
);

const IconLightning = ({
  style = {},
  id,
  color = '#EDBA3B',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_LIGHTENING_PATH}
    ></path>
  </SVG>
);

const IconDebugForward = ({
  id,
  style = {},
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <circle cx="3" cy="10" r="1" fill={color} />
    <line
      x1="5.75"
      y1="10"
      x2="14.75"
      y2="10"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="2"
      d={ICON_DEBUGFORWARD_PATH}
    ></path>
  </SVG>
);

const IconAction = ({
  style = {},
  color = '#CA59A3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
  id,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_ACTION_PATH}
    ></path>
  </SVG>
);

const IconReturn = ({
  style = {},
  id,
  color = '#91DC76',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="0 0 12 12"
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_RETURN_PATH}
    ></path>
  </SVG>
);

const IconRefresh = ({
  id,
  style = {},
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      d={ICON_REFRESH_PATH}
    ></path>
  </SVG>
);

const IconPlus = ({
  style = {},
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  id,
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_PLUS_PATH}
    ></path>
  </SVG>
);

const IconChevronDown = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="3 4 22 20"
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path fill={color} stroke="none" d={ICON_CHEVRON_DOWN_PATH}></path>
  </SVG>
);

const IconChevronRight = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="10 1 16 20"
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path fill={color} stroke="none" d={ICON_CHEVRON_RIGHT_PATH}></path>
  </SVG>
);

const IconChevronLeft = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="10 1 16 20"
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={ {
      transform: 'scale(-1)',
    } }
  >
    <path fill={color} stroke="none" d={ICON_CHEVRON_RIGHT_PATH}></path>
  </SVG>
);

const IconClose = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    style={style}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path fill={color} stroke="none" d={ICON_CLOSE_PATH}></path>
  </SVG>
);

const IconPackage = ({
  style = {},
  id,
  color = '#9F9F9F',
  width = '20px',
  height = '20px',
  className = 'ws-icon icon-package mask-fill',
  handleClick,
}) => (
  <SVG
    id={id}
    viewBox="2 2 20 18"
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}
  >
    <mask id="path-2-inside-1" fill='#FFFFFF'>
      <path
        fillRule="evenodd"
        stroke="none"
        fill='#FFFFFF'
        clipRule="evenodd"
        d={ICON_PACKAGE_PATHS[0]}
      ></path>
    </mask>
    <path
      stroke={color}
      fill={color}
      d={ICON_PACKAGE_PATHS[1]}
      mask="url(#path-2-inside-1)"
    ></path>
  </SVG>
);

const IconOpen = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_OPEN_PATH}
    ></path>
  </SVG>
);

const IconCollapse = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_COLLAPSE_PATH}
    ></path>
  </SVG>
);

const IconDragHandle = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_DRAGHANDLE_PATH}
    ></path>
  </SVG>
);

const IconPlay = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      strokeWidth="0.35"
      d={ICON_PLAY_PATH}
    ></path>
  </SVG>
);

const IconPrivate = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    className={ className }
    height={ height }
    width={ width }
    handleClick={ handleClick }>
    <path
      fill='none'
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={ color }
      strokeWidth="0.846154"
      d={ ICON_PRIVATE_PATH } >
    </path>
  </SVG>
);

const IconInfo = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={ className }
    height={ height }
    width={ width }
    style={ style }
    handleClick={ handleClick }>
    <path
      fill={ color }
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d={ ICON_INFO_PATH } >
    </path>
  </SVG>
);

const IconCaution = ({
  id,
  style = {},
  color = '#E3724E',
  width = '20px',
  height = '20px',
  className = 'ws-icon icon-caution',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 11 11"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d={ ICON_CAUTION_PATHS[0] }></path>
    <path stroke="none" fill={ '#0F0E11' } d={ ICON_CAUTION_PATHS[1] }></path>
  </SVG>
);

const IconTriangle = ({
  id,
  style = {},
  color = '#E3724E',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 6 6"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d="M3 0L6 6H0L3 0Z"></path>
  </SVG>
);

const IconCircle = ({
  id,
  style = {},
  color = '#ACDC22',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 6 6"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <circle cx="3" cy="3" r="3" stroke="none" fill={ color }></circle>
  </SVG>
);

const IconDropdownArrow = ({
  id,
  style = {},
  color = '#E3724E',
  width = '11',
  height = '7',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 11 7"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d={ ICON_DROPDOWN_ARROW_PATH }></path>
  </SVG>
);

const IconOpenModal = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d={ ICON_OPEN_MODAL_PATH }></path>
  </SVG>
);

const IconFilter = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 17 16"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_FILTER_PATH }></path>
  </SVG>
);

const IconCheck = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke='none' fill={ color } d={ ICON_CHECK_PATH }></path>
  </SVG>
);

const IconStop = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <rect x="1" y="1" width="18" height="18" rx="2" fill="#FDFAFF"/>
  </SVG>
);

const IconTable = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path fillRule="evenodd" clipRule="evenodd" stroke='none' fill={ color } d={ ICON_TABLE_PATH }></path>
  </SVG>
);

const IconHelp = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path fillRule="evenodd" clipRule="evenodd" stroke='none' fill={ color } d={ ICON_HELP_PATH }></path>
  </SVG>
);

const IconSync = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    style={style}
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}>
    <path fill={color} d={ICON_SYNC_PATHS[0]}></path>
    <path fill={color} d={ICON_SYNC_PATHS[1]}></path>
  </SVG>
);

const IconFile = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="-1.5 -.5 17 18"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_FILE_PATH }></path>
  </SVG>
);

const IconLogo = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 149 128"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke="none"
      d={ICON_LOGO_PATH}
    ></path>
  </SVG>
);

const IconUpload = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d={ ICON_UPLOAD_PATH }></path>
  </SVG>
);

const IconGlobe = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ color } d={ ICON_GLOBE_PATH }></path>
  </SVG>
);

const IconFolder = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_FOLDER_PATH }></path>
  </SVG>
);

const IconLinkOut = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke="none" fill={ color } d={ ICON_LINK_OUT_PATH }></path>
  </SVG>
);

const IconSaveAs = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_SAVE_AS_PATH[0] }></path>
    <path stroke={ color } fill="none" d={ ICON_SAVE_AS_PATH[1] }></path>
  </SVG>
);

const IconSaved = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ color } d={ ICON_SAVED_PATHS[0] }></path>
    <path stroke={ color } fill="none" d={ ICON_SAVED_PATHS[1] }></path>
  </SVG>
);

const IconSaving = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_SAVING_PATHS[0] }></path>
    <path stroke={ color } fill="none" d={ ICON_SAVING_PATHS[1] }></path>
  </SVG>
);

const IconDiscord = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 245 240"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path fill={ color } d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z"></path>
    <path fill={ color } d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"></path>
  </SVG>
);

const IconFunction = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_FUNCTION_PATHS[0] }></path>
    <path stroke={ color } fill="none" d={ ICON_FUNCTION_PATHS[1] }></path>
    <path stroke={ color } fill="none" d={ ICON_FUNCTION_PATHS[2] }></path>
  </SVG>
);

const IconBlock = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20',
  height = '20',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_BLOCK_PATH }></path>
  </SVG>
);

const IconKey = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill="none" d={ ICON_KEY_PATH }></path>
  </SVG>
);

const IconDirectionLeft = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_DIRECTION_LEFT_PATH}
    ></path>
  </SVG>
);

const IconDirectionRight = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_DIRECTION_RIGHT_PATH}
    ></path>
  </SVG>
);

const IconDirectionUp = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_DIRECTION_UP_PATH}
    ></path>
  </SVG>
);

const IconDirectionDown = ({
  style = {},
  id,
  color = 'white',
  width = '13px',
  height = '20px',
  className = 'ws-icon',
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={color}
      d={ICON_DIRECTION_DOWN_PATH}
    ></path>
  </SVG>
);

const IconDollarSign = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  fill = 'none',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ fill } d={ ICON_DOLLAR_PATH }></path>
  </SVG>
);

const IconDeploy = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={id}
    className={className}
    height={height}
    width={width}
    viewBox="2.5 2.5 15 15"
    handleClick={ handleClick }
  >
    <path stroke={color} strokeWidth='.5' fill='none' d={ICON_DEPLOY_PATH[0]}></path>
    <path stroke={color} strokeWidth='.5' fill='none' d={ICON_DEPLOY_PATH[1]}></path>
    <path stroke={color} strokeWidth='.5' fill='none' d={ICON_DEPLOY_PATH[2]}></path>
    <line x1="6.18976" y1="14.9844" x2="5.1422" y2="16.0319" stroke={color} strokeWidth="0.740741"/>
    <line x1="5.14219" y1="13.9368" x2="1.9995" y2="17.0795" stroke={color} strokeWidth="0.740741"/>
    <line x1="4.09463" y1="12.8892" x2="1.9995" y2="14.9844" stroke={color} strokeWidth="0.740741"/>
  </SVG>
);

const IconWrench = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  fill = 'none',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ fill } d={ ICON_WRENCH_PATH }></path>
  </SVG>
);

const IconLair = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  fill = 'none',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ fill } d={ ICON_LAIR_PATH }></path>
  </SVG>
);

const IconContainer = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  fill = 'none',
  handleClick,
}) => (
  <SVG
    viewBox="1 1.5 18 18.5"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path d={ ICON_CONTAINER_PATHS[0] } fill={ color }/>
    <path d={ ICON_CONTAINER_PATHS[1] } fill='none' stroke={ color } strokeWidth="0.870689"/>
  </SVG>
);

const IconTree = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="1 1 18 18"
    style={ style }
    id={ id }
    fillRule="evenodd"
    clipRule="evenodd"
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke='none' fill={ color } d={ ICON_TREE_PATH }></path>
  </SVG>
);

const IconInfinity = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 20 20"
    style={ style }
    id={ id }
    width={ width }
    height={ height }
    className={ className }
    handleClick={ handleClick }>
    <path stroke={ color } fill={ color } d={ ICON_INFINITY }></path>
  </SVG>
);

const IconCLI = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={ id }
    className={ className }
    height={ height }
    width={ width }
    viewBox="0 0 20 20"
    style={ style }
    handleClick={ handleClick }>
    <path fill={ color } d={ ICON_CLI_PATH }></path>
    <rect x="12.5" y="2.5" width="6" height="15" stroke={ color } fill='none' />
  </SVG>
);

const IconFilePy = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    id={ id }
    className={ className }
    height={ height }
    width={ width }
    viewBox="0 0 741 737"
    style={ style }
    handleClick={ handleClick }>
    <path fill={ color } d={ ICON_FILE_PY_PATHS[0] }></path>
    <path fill={ color } d={ ICON_FILE_PY_PATHS[1] }></path>
  </SVG>
);

const IconWS = ({
  style = {},
  id,
  color = 'white',
  textColor = 'black',
  width = '22px',
  height = '23px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 20 20"
  >
    <rect
      fill={color}
      width="20"
      height="20"
      rx="2.43902"
    />
    <path
      fill={textColor}
      d={ICON_WS_PATH}
    ></path>
  </SVG>
);

const IconClock = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 20 20"
  >
    <path
      stroke={color}
      d={ICON_CLOCK_PATH}
    ></path>
  </SVG>
);

const IconTerminal = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 20 20"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d={ICON_TERMINAL}
    ></path>
  </SVG>
);

const IconFetch = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 20 20"
  >
    <path
      fill="none"
      stroke={color}
      d={ICON_FETCH_PATHS[0]}
    ></path>
    <path
      fill="none"
      stroke={color}
      d={ICON_FETCH_PATHS[1]}
    ></path>
  </SVG>
);

const IconSlack = ({
  style = {},
  id,
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 2447.6 2452.5"
  >
    <path
      fill='#36c5f0'
      d={ICON_SLACK_PATHS[0]}
    ></path>
    <path
      fill='#2eb67d'
      d={ICON_SLACK_PATHS[1]}
    ></path>
    <path
      fill='#ecb22e'
      d={ICON_SLACK_PATHS[2]}
    ></path>
    <path
      fill='#e01e5a'
      d={ICON_SLACK_PATHS[3]}
    ></path>
  </SVG>
);

const IconHttp = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 512 512"
  >
    <path
      fill={color}
      d={ICON_HTTP_PATHS[0]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[1]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[2]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[3]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[4]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[5]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[6]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[7]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[8]}
    ></path>
    <path
      fill={color}
      d={ICON_HTTP_PATHS[9]}
    ></path>
  </SVG>
);

const IconSql = ({
  style = {},
  id,
  width = '20px',
  height = '20px',
  color = 'white',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d={ICON_SQL_PATHS[0]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[1]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[2]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[3]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[4]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[5]}
    ></path>
    <path
      fill={color}
      d={ICON_SQL_PATHS[6]}
    ></path>
  </SVG>
);

const IconHome = ({
  style = {},
  id,
  color = 'white',
  width = '20px',
  height = '20px',
  className,
  handleClick,
}) => (
  <SVG id={id}
    className={className}
    height={height}
    width={width}
    handleClick={handleClick}
    style={style}
    viewBox="0 0 20 20"
  >
    <path
      fill="none"
      stroke={color}
      d={ICON_HOME_PATH}
    ></path>
  </SVG>
);

const IconFileJS = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 410 410"
    style={style}
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}>
    <path fill={color} d={ICON_FILE_JS_PATH}></path>
  </SVG>
);

const IconUsage = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 16 16"
    style={style}
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}>
    <path fill={color} d={ICON_USAGE}></path>
  </SVG>
);

const IconTemplate = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG
    viewBox="0 0 16 16"
    fill="none"
    style={style}
    id={id}
    width={width}
    height={height}
    className={className}
    handleClick={handleClick}>
    <path fillRule="evenodd" clipRule="evenodd" fill={color} d={ICON_TEMPLATE}></path>
  </SVG>
);

const IconNewRelic = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = 'ws-icon',
  handleClick,
}) => (
  <SVG id={id} className={className} handleClick={handleClick} style={style} width={width} height={height} viewBox="0 0 137 111" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_62_328)">
      <path d="M135.15 41.1918C128.809 12.4002 93.8646 -4.53969 57.0719 3.32392C20.2792 11.1875 -4.38853 41.0167 1.95243 69.8083C8.29338 98.5999 43.2382 115.527 80.0309 107.639C116.824 99.75 141.491 69.9833 135.15 41.1918ZM66.0834 91.4364C58.7339 91.4364 51.5494 89.2836 45.4385 85.2504C39.3276 81.2172 34.5648 75.4846 31.7522 68.7776C28.9397 62.0706 28.2038 54.6905 29.6376 47.5703C31.0715 40.4502 34.6106 33.91 39.8075 28.7767C45.0043 23.6434 51.6256 20.1476 58.8339 18.7313C66.0421 17.315 73.5137 18.0419 80.3038 20.82C87.0939 23.5981 92.8974 28.3027 96.9806 34.3389C101.064 40.375 103.243 47.4716 103.243 54.7312C103.245 59.5518 102.285 64.3256 100.418 68.7796C98.551 73.2336 95.8139 77.2806 92.363 80.6893C88.9121 84.098 84.8149 86.8017 80.3058 88.6457C75.7966 90.4897 70.9637 91.438 66.0834 91.4364" fill="#008996"/>
      <path d="M75.3481 9.23727C65.9436 9.24963 56.754 12.0156 48.9405 17.1855C41.1271 22.3554 35.0406 29.6972 31.4503 38.2831C27.8601 46.8689 26.9272 56.3134 28.7696 65.4228C30.612 74.5323 35.1469 82.8977 41.8013 89.462C48.4557 96.0262 56.9308 100.495 66.1555 102.302C75.3801 104.11 84.9403 103.176 93.6277 99.6183C102.315 96.0605 109.74 90.0387 114.963 82.314C120.187 74.5893 122.975 65.5083 122.975 56.2189C122.973 50.0444 121.74 43.9307 119.345 38.2273C116.95 32.5239 113.441 27.3426 109.018 22.9794C104.595 18.6163 99.3446 15.1569 93.5673 12.7989C87.7901 10.441 81.5991 9.2307 75.3481 9.23727V9.23727ZM66.0835 90.8363C58.8511 90.8363 51.7811 88.7177 45.7678 84.7485C39.7545 80.7793 35.0679 75.1378 32.3007 68.5374C29.5336 61.9371 28.8102 54.6744 30.2221 47.6679C31.634 40.6614 35.1177 34.2259 40.2327 29.1753C45.3476 24.1246 51.8641 20.6858 58.9578 19.2936C66.0515 17.9014 73.4039 18.6184 80.085 21.354C86.7662 24.0895 92.476 28.7208 96.4922 34.6619C100.509 40.6031 102.651 47.5873 102.648 54.7312C102.648 59.4736 101.703 64.1696 99.8648 68.5509C98.0271 72.9322 95.3335 76.9129 91.938 80.2658C88.5425 83.6186 84.5115 86.2778 80.0753 88.0916C75.6391 89.9053 70.8847 90.8379 66.0835 90.8363Z" fill="#1DCAD3"/>
      <path d="M52.9964 51.1306L48.9843 42.8294C48.1324 41.1257 47.376 39.377 46.7187 37.5912L46.6301 37.6662C46.7694 39.1539 46.7947 41.0167 46.82 42.5794L46.9212 51.1306H43.9976V33.4531H47.3642L51.7054 42.0793C52.4281 43.5459 53.0415 45.0627 53.5406 46.6175L53.6166 46.5425C53.5406 45.6548 53.3634 43.1295 53.3634 41.5418V33.4656H56.1859V51.1306H52.9964Z" fill="#FAFDFF"/>
      <path d="M61.4511 45.4299V45.6424C61.4511 47.4302 62.1219 49.318 64.6785 49.318C65.8862 49.3153 67.0499 48.8698 67.9439 48.0678L69.045 49.7805C67.741 50.892 66.0718 51.4964 64.3494 51.4808C60.6537 51.4808 58.3249 48.8554 58.3249 44.7173C58.1957 42.9069 58.7756 41.1166 59.9449 39.7166C60.4356 39.1444 61.0521 38.6904 61.7475 38.3892C62.4428 38.0881 63.1985 37.9477 63.9571 37.9789C65.2848 37.9377 66.5758 38.4136 67.5515 39.304C68.6906 40.3292 69.2602 41.9044 69.2602 44.9173V45.5049L61.4511 45.4299ZM63.9824 40.0166C62.375 40.0166 61.4511 41.2668 61.4511 43.3671H66.2859C66.2859 41.2668 65.3493 40.0166 63.9317 40.0166" fill="#FAFDFF"/>
      <path d="M84.3849 51.1806H81.7144L80.1197 45.2298C79.702 43.6921 79.259 41.7043 79.259 41.7043C79.259 41.7043 79.0565 42.9545 78.411 45.3548L76.7783 51.1806H74.1204L70.5513 38.3913L73.361 38.0038L74.7912 44.2547C75.1456 45.8674 75.462 47.6551 75.462 47.6551H75.538C75.7249 46.4845 75.9742 45.3244 76.2847 44.1797L77.968 38.2038H80.7651L82.2333 44.0296C82.7775 46.1174 83.056 47.7052 83.056 47.7052H83.1446C83.1446 47.7052 83.4483 45.7424 83.7901 44.2297L85.1317 38.2038H88.0807L84.3849 51.1806Z" fill="#FAFDFF"/>
      <path d="M50.7561 73.8714L49.1994 71.146C48.3905 69.5562 47.3642 68.0839 46.1491 66.7704C46.0034 66.6059 45.8246 66.4732 45.6242 66.3806C45.4237 66.2881 45.2059 66.2377 44.9847 66.2328V73.8714H42.061V56.2064H47.516C51.5282 56.2064 53.3381 58.5067 53.3381 61.2071C53.3828 61.8178 53.3016 62.4311 53.0994 63.0099C52.8972 63.5887 52.5781 64.1209 52.1616 64.5744C51.7452 65.0279 51.2398 65.3932 50.6763 65.6482C50.1127 65.9031 49.5026 66.0425 48.8829 66.0578C49.9988 67.0726 50.9356 68.2641 51.6547 69.5833L54.2873 73.8214L50.7561 73.8714ZM46.6174 58.5818H44.9847V64.17H46.5162C47.0267 64.2449 47.5471 64.2182 48.047 64.0915C48.547 63.9648 49.0162 63.7408 49.4272 63.4324C49.6995 63.1448 49.9114 62.8066 50.0505 62.4375C50.1895 62.0684 50.253 61.6757 50.2372 61.2821C50.2372 59.4944 49.25 58.5818 46.6174 58.5818Z" fill="#FAFDFF"/>
      <path d="M58.1983 68.183V68.3331C58.1983 70.1208 58.8691 72.0086 61.4257 72.0086C62.63 72.0077 63.7903 71.5617 64.6785 70.7584L65.7922 72.4711C64.4918 73.5902 62.8213 74.1995 61.0967 74.1839C57.3883 74.1839 55.0721 71.546 55.0721 67.4204C54.9403 65.6097 55.5205 63.8185 56.6922 62.4197C57.1829 61.8475 57.7994 61.3935 58.4947 61.0923C59.19 60.7912 59.9457 60.6508 60.7043 60.682C62.0321 60.6408 63.323 61.1167 64.2988 62.0072C65.4379 63.0323 66.0074 64.62 66.0074 67.633V68.2205L58.1983 68.183ZM60.7296 62.7698C59.1222 62.7698 58.1983 64.02 58.1983 66.1077H63.0331C63.0331 64.02 62.0965 62.7698 60.679 62.7698" fill="#FAFDFF"/>
      <path d="M71.3232 74.1589C68.4628 74.1589 68.4628 71.6585 68.4628 70.5084V59.4943C68.4872 58.2388 68.3982 56.9837 68.197 55.7438L71.0827 55.0812C71.2927 56.2526 71.3691 57.4435 71.3105 58.6317V69.5832C71.3105 71.321 71.3991 71.596 71.6016 71.9085C71.7127 72.0366 71.8629 72.1254 72.0297 72.1615C72.1964 72.1976 72.3705 72.179 72.5256 72.1086L72.9939 73.8463C72.4516 74.0635 71.8703 74.1698 71.2852 74.1589" fill="#FAFDFF"/>
      <path d="M76.2592 59.0317C76.0149 59.0285 75.7736 58.9777 75.5491 58.8823C75.3247 58.7869 75.1214 58.6488 74.951 58.4758C74.7806 58.3029 74.6463 58.0984 74.5559 57.8742C74.4655 57.65 74.4207 57.4103 74.424 57.169C74.4241 56.7985 74.5361 56.4364 74.7457 56.1291C74.9552 55.8218 75.2528 55.5831 75.6003 55.4437C75.9479 55.3043 76.3296 55.2705 76.6968 55.3465C77.0639 55.4225 77.3997 55.605 77.6613 55.8705C77.923 56.1361 78.0985 56.4726 78.1654 56.8372C78.2323 57.2018 78.1877 57.5778 78.0372 57.9172C77.8866 58.2565 77.6371 58.5439 77.3204 58.7424C77.0037 58.9409 76.6342 59.0417 76.2592 59.0317ZM74.8796 73.8713V61.157L77.7527 60.6445V73.8713H74.8796Z" fill="#FAFDFF"/>
      <path d="M85.8529 74.2089C82.2837 74.2089 80.2966 71.7086 80.2966 67.5705C80.2966 62.8698 83.1444 60.6195 86.068 60.6195C86.7415 60.5993 87.4117 60.719 88.0352 60.971C88.6588 61.223 89.2218 61.6016 89.6878 62.0822L88.2576 63.9575C87.977 63.6569 87.6392 63.4139 87.2635 63.2423C86.8879 63.0707 86.4816 62.9738 86.068 62.9573C85.6369 62.9463 85.2118 63.0583 84.8436 63.2801C84.4754 63.5019 84.1798 63.8239 83.9924 64.2075C83.56 65.3574 83.3746 66.5836 83.4481 67.808C83.3049 69.0502 83.6194 70.3017 84.3341 71.3335C84.5524 71.5724 84.8203 71.7619 85.1196 71.8892C85.4188 72.0165 85.7423 72.0786 86.068 72.0711C86.5687 72.0447 87.0591 71.9207 87.5109 71.7061C87.9627 71.4915 88.3671 71.1907 88.7006 70.8209L90.0675 72.4962C89.5344 73.0617 88.8844 73.5073 88.1615 73.8028C87.4387 74.0984 86.66 74.2369 85.8782 74.2089" fill="#FAFDFF"/>
    </g>
    <defs>
      <clipPath id="clip0_62_328">
        <rect width="136.691" height="109.353" fill="white" transform="translate(0.205811 0.823547)"/>
      </clipPath>
    </defs>
  </SVG>
);

const IconPuzzle = ({
  id,
  style = {},
  color = '#F2F1F3',
  width = '20px',
  height = '20px',
  className = '',
  handleClick,
}) => (
  <SVG id={id} className={className} handleClick={handleClick} style={style} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 4H2C1.44772 4 1 4.44772 1 5V13C1 13.5523 1.44772 14 2 14H4C4.55228 14 5 13.5788 5 13.0265C5 13.0177 5 13.0089 5 13C5 12.5244 4 12 4 11C4 10 4.67118 9 6 9C7.32882 9 8 10 8 11C8 12 7 12.5244 7 13C7 13.0089 7 13.0177 7 13.0265C7 13.5788 7.44772 14 8 14H10C10.5523 14 11 13.5523 11 13V9M6 4H10C10.5523 4 11 4.44772 11 5V9M6 4V3M6 3C6.5 3 7 2.54545 7 2C7 1.45455 6.5 1 6 1C5.5 1 5 1.36365 5 2C5 2.63635 5.5 3 6 3ZM12 9C12 8.36365 12.4265 8 13 8C13.5735 8 14 8.36364 14 9C14 9.63636 13.5 10 13 10C12.5 10 12 9.63635 12 9ZM12 9H11" stroke="#F2F1F3" fill='transparent'/>
  </SVG>
);

export {
  IconUser,
  IconTeams,
  IconCog,
  IconDocs,
  IconBlog,
  IconMarketplace,
  IconHamburger,
  IconEdit,
  IconDelete,
  IconCopy,
  IconShare,
  IconRight,
  IconLeft,
  IconX,
  IconLog,
  IconZoomOut,
  IconZoomIn,
  IconLogic,
  IconBrackets,
  IconSearch,
  IconUndo,
  IconRedo,
  IconComment,
  IconLock,
  IconFit,
  IconMenu,
  IconDownload,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconArrowDown,
  IconView,
  IconHide,
  IconLightning,
  IconAction,
  IconReturn,
  IconChevronDown,
  IconChevronRight,
  IconChevronLeft,
  IconNotifications,
  IconPlus,
  IconDebug,
  IconDebugForward,
  IconRefresh,
  IconClose,
  IconPackage,
  IconCode,
  IconOpen,
  IconCollapse,
  IconDragHandle,
  IconPlay,
  IconStop,
  IconDropdownMenu,
  IconInfo,
  IconCaution,
  IconTriangle,
  IconCircle,
  IconPrivate,
  IconDropdownArrow,
  IconOpenModal,
  IconFilter,
  IconCheck,
  IconTable,
  IconHelp,
  IconFile,
  IconLogo,
  IconUpload,
  IconSync,
  IconGlobe,
  IconFolder,
  IconLinkOut,
  IconSaveAs,
  IconSaved,
  IconSaving,
  IconDiscord,
  IconFunction,
  IconBlock,
  IconKey,
  IconDirectionLeft,
  IconDirectionRight,
  IconDirectionUp,
  IconDirectionDown,
  IconDollarSign,
  IconLair,
  IconDeploy,
  IconWrench,
  IconTree,
  IconContainer,
  IconInfinity,
  IconCLI,
  IconFilePy,
  IconWS,
  IconClock,
  IconTerminal,
  IconSlack,
  IconFetch,
  IconHttp,
  IconSql,
  IconHome,
  IconFileJS,
  IconUsage,
  IconTemplate,
  IconNewRelic,
  IconPuzzle,
};
