export const FILE_TYPE_RUN_COMMANDS = {
  'py': 'python *',
};

export const HIDDEN_FILE_NAMES = {
  '.wayscript': true,
};

export const FILE_TYPE_NAME_MAP = {
  secret: '.secrets',
  env: '.env',
  readme: 'README.md',
};
