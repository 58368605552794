import {buildErrorMessage} from '../../helpers';
import {fetchError, buildFetchArgs} from './fetchHelpers';

export default async function fetcher(...args) {
  let toast;
  if (args[1] && args[1].hasOwnProperty('toast')) {
    toast = args[1].toast;
    delete args[1].toast;
  }
  let logError;
  if (args[1] && args[1].hasOwnProperty('logError')) {
    logError = args[1].logError;
    delete args[1].logError;
  }
  args = await buildFetchArgs(...args);
  try {
    const response = await fetch(...args);
    let data;
    if (response.status === 204) {
      // there may not be json data in this case
      try {
        data = await response.json();
      } catch (error) {
        data = {};
      }
    } else {
      data = await response.json();
    }
    if (response.ok) {
      return data;
    }
    const errorMessage = data.error ? buildErrorMessage(data.error) : response.statusText;
    const error = new Error(errorMessage);
    error.response = response;
    error.data = data;
    throw error;
  } catch (error) {
    await fetchError(error, toast, logError);
  }
}
