import {fetchError, buildFetchArgs} from './fetchHelpers';

export default async function fetcher(...args) {
  args = await buildFetchArgs(...args);

  try {
    const response = await fetch(...args);
    const data = await response.body;
    if (response.ok) {
      if (data !== undefined) return data.getReader();
      else return await response.text();
    }
    const errorMessage = response.statusText || 'Request Failed';
    const error = new Error(errorMessage);
    error.response = response;
    throw error;
  } catch (error) {
    await fetchError(error);
  }
}
