import React from 'react';
import {Flex, Text, Heading} from '@chakra-ui/react';
import UsageBar from './UsageBar';

const TableUsageBar = ({
  label,
  max,
  value,
  color,
  unit,
}) => (
  <Flex align='center'>
    {label ? <Text mr={2}>{label}</Text> : <Flex align='flex-end' mr={4}>
      <Heading size='sm' mr={2}>{value}</Heading>
      <Text fontWeight='200'>{unit}</Text>
    </Flex>
    }
    <UsageBar
      max={max}
      value={value}
      color={color}
      width='75px'
    />
  </Flex>
);

export default TableUsageBar;
