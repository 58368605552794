import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {cleanPathForPlatform} from '../../utils/helpers';

const initialState = {
  status: 'init',
  currentVersion: null,
  availableVersion: null,
  ignoreUpdate: false,
  currentWorkingDirectory: null,
};

export const connectBridge = createAsyncThunk(
  'desktop/connect',
  async(args, {getState, rejectWithValue}) => {
    const maxAttempts = 10;
    const connect = async(attempt = 0) => {
      if (typeof window === 'undefined') {
        return new Promise(resolve => setTimeout(async() => {
          await connect();
          resolve();
        }, 500));
      }
      let data;
      try {
        data = await window.electron.message.connect();
      } catch (error) {
        if (attempt > maxAttempts) return rejectWithValue('Connection failed');
        return new Promise(resolve => setTimeout(async() => {
          const info = await connect(attempt + 1);
          resolve(info);
        }, 500));
      }
      if (!data) {
        if (attempt > maxAttempts) return rejectWithValue('Connection failed');
        return new Promise(resolve => setTimeout(async() => {
          const info = await connect(attempt + 1);
          resolve(info);
        }, 500));
      } else {
        return data;
      }
    };
    return await connect();
  },
);

export const versionDownloaded = createAsyncThunk(
  'desktop/setAvailableVersion',
  async(args, {getState, dispatch}) => {
    const {version} = args;
    const state = getState();
    const {currentVersion, availableVersion} = state.desktop;
    dispatch(setAvailableVersion(version));
    if (version === currentVersion || version === availableVersion) {
      // check again in 30 minutes
      await window.electron.message.invoke('updateApp', {type: 'CHECK_LATER'});
    } else {
      dispatch(setIgnoreUpdate(false));
    }
  },
);

const desktopSlice = createSlice({
  name: 'desktop',
  initialState: initialState,
  reducers: {
    setAvailableVersion: (state, action) => {
      state.availableVersion = action.payload;
    },
    setIgnoreUpdate: (state, action) => {
      state.ignoreUpdate = action.payload;
    },
  },
  extraReducers: {
    [connectBridge.pending]: (state) => {
      state.status = 'pending';
    },
    [connectBridge.fulfilled]: (state, action) => {
      state.currentVersion = action.payload.version;
      state.status = 'idle';
      state.currentWorkingDirectory = cleanPathForPlatform(action.payload.cwd);
    },
  },
});

export const {
  setAvailableVersion,
  setIgnoreUpdate,
} = desktopSlice.actions;

export const selectIsNewVersionAvailable = (state) => {
  const {currentVersion, availableVersion} = state.desktop;
  if (availableVersion && availableVersion !== currentVersion) return true;
  return false;
};

export default desktopSlice.reducer;
