import {
  IconFilePy,
  IconLair,
  IconFile,
  IconWS,
  IconKey,
  IconDollarSign,
  IconBlog,
  IconDeploy,
  IconLightning,
  IconFileJS,
} from '../../components/Icons';
import {debugLog} from '../../../helpers';

export const isValidFileName = (name) => {
  return name !== '';
};

export const getFileNameExtension = (path) => {
  const chunksBySlash = path.split('/');
  const name = chunksBySlash[chunksBySlash.length - 1];
  const chunkNamesByPeriod = name.split('.');
  return chunkNamesByPeriod.length > 1 ? chunkNamesByPeriod[chunkNamesByPeriod.length - 1] : '';
};

export const convertFilePathToArray = (path) => {
  return path.split('/');
};

export const getFilePathExtensionIcon = (fileType, props, theme, colorMode) => {
  switch (fileType) {
  case 'pyFile':
    return <IconFilePy {...props} />;
  case 'lair':
    return <IconLair {...props} color={`${theme.colors[colorMode].primary[500]}`} />;
  case 'wsFile':
    return <IconWS
      {...props}
      color={`${theme.colors[colorMode].grey[50]}`}
      textColor={`${theme.colors[colorMode].grey[900]}`} />;
  case 'secretsFile':
    return <IconKey {...props} />;
  case 'envFile':
    return <IconDollarSign {...props} />;
  case 'txtFile':
    return <IconBlog {...props} />;
  case 'jsFile':
    return <IconFileJS {...props} />;
  case 'triggers':
    return <IconLightning
      {...props}
      color={`${theme.colors[colorMode].grey[50]}`} />;
  case 'prod':
    return <IconDeploy
      {...props}
      color={`${theme.colors[colorMode].teal[500]}`}
    />;
  default:
    return <IconFile />;
  }
};

export const readStreamAsText = async(reader) => {
  try {
    const stream = await new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader.read().then(({done, value}) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    const response = await new Response(stream);
    const blob = await response.blob();
    const text = await blob.text();
    return text;
  } catch (error) {
    debugLog(error);
  }
};

export const readStreamAsBlob = async(reader) => {
  try {
    const stream = await new ReadableStream({
      start(controller) {
        return pump();
        function pump() {
          return reader.read().then(({done, value}) => {
            // When no more data needs to be consumed, close the stream
            if (done) {
              controller.close();
              return;
            }
            // Enqueue the next data chunk into our target stream
            controller.enqueue(value);
            return pump();
          });
        }
      },
    });
    const response = await new Response(stream);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    debugLog(error);
  }
};

export const generateUniqueFileName = (filename, siblingNames) => {
  let copyIndex = 1;
  let extension = getFileNameExtension(filename) || '';
  let copyName = filename;
  if (extension) {
    copyName = filename.slice(0, filename.length - (extension.length + 1));
    extension = '.' + extension;
  }
  while (siblingNames.includes(copyName + extension)) {
    const split = copyName.split('-');
    if (split.length >= 2) {
      const [p, u] = split.slice(split.length - 2, split.length);
      if (u === 'copy') {
        copyName = `${split.join('-')}-${copyIndex}`;
      } else if (p === 'copy' && !isNaN(Number(u))) {
        copyName = `${split.slice(0, split.length - 1).join('-')}-${copyIndex}`;
        copyIndex++;
      } else {
        copyName = `${copyName}-copy`;
      }
    } else {
      copyName = `${copyName}-copy`;
    }
  }
  return copyName + extension;
};

export const updateFileNameOfPath = (path, oldName, newName) => {
  const pathList = path.split('/');
  const lastNode = pathList[pathList.length - 1];
  if (lastNode === oldName) {
    pathList[pathList.length - 1] = newName;
  }
  return pathList.join('/');
};

export const isFileContentEqual = (content1, content2) => {
  return content1 === content2 || content1.replaceAll('\r\n', '\n') === content2 || content2.replaceAll('\r\n', '\n') === content1;
};

export const encodeFilePath = (path) => {
  return path.replace(/\//g, '%2F');
};
