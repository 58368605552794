export const ENV_VAR_ENTRY_DELIM = '\n';
export const ENV_VAR_KEY_VAL_DELIM = '=';

export const convertEnvVarsStringToObj = (value) => {
  const entries = value ? value.split(ENV_VAR_ENTRY_DELIM) : [];
  return entries.map(entry => {
    return entry.split(ENV_VAR_KEY_VAL_DELIM);
  });
};

export const convertEnvVarsObjToString = (list) => {
  return list.map(entry => entry.join(ENV_VAR_KEY_VAL_DELIM)).join(ENV_VAR_ENTRY_DELIM);
};
