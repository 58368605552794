import {useMemo} from 'react';
import {combineReducers} from 'redux';
import {
  configureStore,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import filesReducer from '../features/files/FilesSlice';
import editorReducer from '../features/editor/EditorSlice';
import lairsReducer from '../features/lairs/LairsSlice';
import integrationsReducer from '../features/integrations/IntegrationsSlice';
import triggersReducer from '../features/triggers/TriggersSlice';
import workspacesReducer from './WorkspacesSlice';
import layoutReducer from '../features/layout/LayoutSlice';
import homeReducer from '../features/home/HomeSlice';
import settingsReducer from '../features/settings/SettingsSlice';
import modalReducer from '../features/modal/ModalSlice';
import userReducer from '../features/user/UserSlice';
import processesReducer from '../features/processes/ProcessesSlice';
import panelLeftReducer from '../features/layout/PanelLeftSlice';
import panelBottomReducer from '../features/layout/PanelBottomSlice';
import socketsReducer from '../features/sockets/SocketsSlice';
import desktopReducer from '../features/desktop/DesktopSlice';
import hotkeysReducer from '../features/hotkeys/HotKeysSlice';
import preferencesReducer from '../features/preferences/PreferencesSlice';
import {isElectron} from '../utils/helpers';
import {DOMAIN} from './constants';

let store;

export const connectBridge = createAsyncThunk(
  'electron/connect',
  async(args, {getState, rejectWithValue}) => {
    const maxAttempts = 10;
    const connect = async(attempt = 0) => {
      if (typeof window === 'undefined') {
        return new Promise(resolve => setTimeout(async() => {
          await connect();
          resolve();
        }, 500));
      }
      let data;
      try {
        data = await window.electron.message.connect();
      } catch (error) {
        if (attempt > maxAttempts) return rejectWithValue('Connection failed');
        return new Promise(resolve => setTimeout(async() => {
          const info = await connect(attempt + 1);
          resolve(info);
        }, 500));
      }
      if (!data) {
        if (attempt > maxAttempts) return rejectWithValue('Connection failed');
        return new Promise(resolve => setTimeout(async() => {
          const info = await connect(attempt + 1);
          resolve(info);
        }, 500));
      } else {
        return data;
      }
    };
    return await connect();
  },
);

export const selectAppReady = (state) => {
  return !isElectron || (state.desktop.status === 'idle' && DOMAIN);
};

const rootReducer = combineReducers({
  files: filesReducer,
  editor: editorReducer,
  lairs: lairsReducer,
  integrations: integrationsReducer,
  triggers: triggersReducer,
  workspaces: workspacesReducer,
  layout: layoutReducer,
  home: homeReducer,
  settings: settingsReducer,
  modal: modalReducer,
  user: userReducer,
  processes: processesReducer,
  panel_left: panelLeftReducer,
  panel_bottom: panelBottomReducer,
  desktop: desktopReducer,
  sockets: socketsReducer,
  hotkeys: hotkeysReducer,
  preferences: preferencesReducer,
});

function makeStore(initialState = {}) {
  return configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
  });
}

export const initializeStore = (preloadedState) => {
  let _store = store ?? makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
