import {
  Image,
  Text,
  Flex,
  useColorMode,
} from '@chakra-ui/react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {
  IconEdit,
} from './Icons';

const ImageUpload = styled.form`
  height: 5.5rem;
  width: 5.5rem;
`;

const FileUploadImgProfile = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.hover-edit-profile-icon {
    position: absolute;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    height: 5.55rem;
    width: 5.55rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover { cursor: pointer }
}
`;

const UserImage = ({
  img,
  readOnly = true,
  name,
  handleUploadImage,
  size = 'lg',
}) => {
  const {colorMode} = useColorMode();
  const [isIconShown, setIsIconShown] = useState(false);
  const [imgError, setImgError] = useState(false);

  const defaultImgStyling = <Flex
    background={`${colorMode}.grey.400`}
    w={size === 'sm' ? '1.5rem' : '5.5rem'}
    h={size === 'sm' ? '1.5rem' : '5.5rem'}
    borderRadius="50%"
    align="center"
    justify="center">
    <Text
      fontSize={size === 'sm' ? '1rem' : '2.5rem'}
      color={`${colorMode}.grey.50`}>
      {name ? name.charAt(0).toUpperCase() : ''}
    </Text>
  </Flex>;

  const isBase64 = (str) => {
    if (str === '' || str.trim() === '') { return false; }
    try {
      /* eslint-disable-next-line eqeqeq */
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const imageContent = (!img
    ? defaultImgStyling
    : (img !== 'avatar' && !imgError
      ? <Image
        userSelect="none"
        background={`${colorMode}.grey.400`}
        w={size === 'sm' ? '1.5rem' : '5.5rem'} h={size === 'sm' ? '1.5rem' : '5.5rem'}
        borderRadius="50%" src={isBase64(img) ? `data:image;base64, ${img}` : img}
        onError={() => setImgError(true)}
      /> : defaultImgStyling)
  );

  return (
    <>
      {!readOnly ? <ImageUpload
        id='user_img_upload'
        onMouseEnter={() => setIsIconShown(true)}
        onMouseLeave={() => setIsIconShown(false)}
      >
        <FileUploadImgProfile className="file-upload-img-profile" htmlFor="images-setting-upload_file-file-uploader">
          {imageContent}
          {isIconShown && <span style={{display: 'flex'}} className="hover-edit-profile-icon"><IconEdit width='22px' height='20px' color="grey" /></span>}
        </FileUploadImgProfile>
        <input className="file-uploader" type="file" name="file" id="images-setting-upload_file-file-uploader"
          onChange={e => handleUploadImage(e)} accept=".jpg,.jpeg,.png,.bmp" style={{display: 'none'}} />
        <input type="submit" id="submit_logo" className="file-uploader-submit"
          style={{display: 'none'}} />
      </ImageUpload>
        : imageContent
      }
    </>
  );
};

export default UserImage;
