import React from 'react';
import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
  selectLairView,
  selectCurrentLairId,
} from '../lairs/LairsSlice';
import fetchJson from '../../lib/fetchJson';
import {TAB_DELIM} from '../../app/constants';
import {IconPlay} from '../../components/Icons';
import moment from 'moment';
import theme from '../../theme';
import {Text} from '@chakra-ui/react';
import {logError as logRollbarError} from '../../components/ErrorBoundary';

const processesAdapter = createEntityAdapter();

export const fetchStartLairTerminal = createAsyncThunk(
  '/fetchStartLairTerminal',
  async(args, thunkAPI) => {
    const state = thunkAPI.getState();
    const lairView = selectLairView(state);
    // request new terminal process
    const data = await fetchJson('/terminal/start', {
      method: 'POST',
      body: JSON.stringify({
        lair_id: lairView,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchStopLairTerminal = createAsyncThunk(
  '/fetchStopLairTerminal',
  async(args, thunkAPI) => {
    const state = thunkAPI.getState();
    const lairView = selectLairView(state);
    const terminalId = selectTerminalProcessId(state);
    await fetchJson(`/lairs/${lairView}/${terminalId}/stop`, {
      method: 'POST',
    });
  },
);

export const fetchLairProcess = createAsyncThunk(
  '/fetchLairProcess',
  async(args, thunkAPI) => {
    const {processId} = args;
    const data = await fetchJson(`/processes/${processId}/detail`, {
      method: 'GET',
    });
    return data;
  },
);

export const fetchMoreLairProcesses = () => async(dispatch, getState) => {
  dispatch(setMoreProcessesStatus('pending'));
  const data = await dispatch(fetchLairProcesses());
  if (!data.error) {
    if (!data.payload.length) {
      dispatch(setMoreProcessesStatus('done'));
    } else {
      dispatch(setMoreProcessesStatus('idle'));
    }
  } else {
    dispatch(setMoreProcessesStatus('rejected'));
  }
};

export const fetchLairProcesses = createAsyncThunk(
  '/fetchLairProcesses',
  async(args, {getState, rejectWithValue}) => {
    if (!args) args = {};
    const {offset, limit} = args;
    const state = getState();
    const processesData = selectProcessesData(state);
    const lairId = selectCurrentLairId(state);
    if (!lairId) {
      const message = 'Cannot fetch processes: lairId not set';
      logRollbarError(message, {lairId, lairView: selectLairView(state)});
      return rejectWithValue(message);
    }
    const data = await fetchJson(`/processes?lair=${lairId}&offset=${!isNaN(offset) ? offset : processesData.length}&limit=${!isNaN(limit) ? limit : '20'}`, {
      method: 'GET',
    });
    return data.results.map(d => {
      return {
        ...d,
        lair_id: lairId,
      };
    });
  },
);

export const killProcess = (id) => async(dispatch, getState) => {
  await dispatch(fetchKillProcess({processId: id}));
  dispatch(fetchLairProcess({processId: id}));
};

export const fetchKillProcess = createAsyncThunk(
  'fetchKillProcess',
  async(args, thunkAPI) => {
    const {processId} = args;
    const data = await fetchJson(`/processes/${processId}/kill`, {
      method: 'POST',
    });
    return data;
  },
);

// TODO: CONSOLE STREAMING - fetch process history?
export const fetchProcessHistory = createAsyncThunk(
  '/fetchProcessHistory',
  async(args, thunkAPI) => {
    const {processId} = args;
    const data = await fetchJson(`/processes/${processId}/logs`, {
      method: 'GET',
    });
    return data;
  },
);

export const initialState = processesAdapter.getInitialState({
  terminal_status: 'init', // terminal status for UI states
  terminal_process_id: null, // terminal process_id.
  terminal_service_id: null, // terminal service_id.
  terminal_messages: [], // cache for terminal messages within the lair session
  terminal_command: null,
  status: 'init',
  more_processes_status: 'init',
  tabs: [], // tabs open across all lairs in order.
  tabs_added_order: [],
  focused: null, // tab that is focused - 'terminal-id'
  drag_source: null,
  view: 'terminal', // or 'processes'
  socket_id: 0,
});

const processesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    // Can pass adapter functions directly as case reducers.  Because we're passing this
    // as a value, `createSlice` will auto-generate the `bookAdded` action type / creator
    resetTerminalProcessId: state => { state.terminal_process_id = null; },
    processesAdded: processesAdapter.addOne,
    processRemoved: processesAdapter.removeOne,
    processesRemoved: processesAdapter.removeMany,
    processUpdated: processesAdapter.updateOne,
    processesUpdated: processesAdapter.updateMany,
    processUpserted: processesAdapter.upsertOne,
    processesUpserted: processesAdapter.upsertMany,
    resetProcesses: (state, action) => {
      Object.entries(initialState).forEach(entry => {
        if (entry[0] !== 'socket_id') state[entry[0]] = entry[1];
      });
    },
    setTerminalProcessId: (state, action) => {
      state.terminal_process_id = action.payload;
    },
    setTerminalServiceId: (state, action) => {
      state.terminal_service_id = action.payload;
    },
    setTerminalStatus: (state, action) => {
      state.terminal_status = action.payload;
    },
    setMoreProcessesStatus: (state, action) => {
      state.more_processes_status = action.payload;
    },
    setTerminalMessages: (state, action) => {
      state.terminal_messages = action.payload;
    },
    setTerminalCommand: (state, action) => {
      state.terminal_command = action.payload;
    },
    addTerminalMessage: (state, action) => {
      state.terminal_messages.push(action.payload);
    },
    setProcessesView: (state, action) => {
      state.view = action.payload;
    },
    setFocused: (state, action) => {
      let id;
      if (action.payload && typeof action.payload === 'object') {
        id = convertIdToPrefixedId(action.payload.id, action.payload.lairId);
      } else {
        id = action.payload;
      }
      state.focused = id;
    },
    addTab: (state, action) => {
      let id;
      if (typeof action.payload === 'object') {
        id = convertIdToPrefixedId(action.payload.id, action.payload.lairId);
      } else {
        id = action.payload;
      }

      if (!state.tabs.includes(id)) {
        state.tabs = [...state.tabs, id];
        state.tabs_added_order.push(id);
      }
    },
    deleteTab: (state, action) => {
      state.tabs = state.tabs.filter((tab) => tab !== action.payload);
      state.tabs_added_order = state.tabs_added_order.filter((tab) => tab !== action.payload);
    },
    setTabs: (state, action) => {
      state.tabs = action.payload;
    },
    setTabDragSource: (state, action) => {
      state.drag_source = action.payload;
    },
    incrementTerminalSocketId: (state, action) => {
      state.socket_id += 1;
    },
  },
  extraReducers: {
    // create async thunk reducers
    [fetchStartLairTerminal.pending]: (state, action) => {
      state.terminal_status = 'starting';
    },
    [fetchStartLairTerminal.fulfilled]: (state, action) => {
      state.terminal_status = 'join';
      state.terminal_process_id = action.payload.process_id;
      state.terminal_service_id = action.payload.service_id;
    },
    [fetchStartLairTerminal.rejected]: (state, action) => {
      state.terminal_status = 'rejected'; // error
    },
    [fetchLairProcess.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchLairProcess.fulfilled]: (state, action) => {
      state.status = 'idle';
      processesAdapter.upsertOne(state, action.payload.process);
    },
    [fetchLairProcess.rejected]: (state, action) => {
      // state.status = 'rejected'; // error
    },
    [fetchLairProcesses.pending]: (state, action) => {
      // state.status = 'pending';
    },
    [fetchLairProcesses.fulfilled]: (state, action) => {
      // state.status = 'idle';
      if (action.payload.length) {
        processesAdapter.upsertMany(state, action.payload);
      }
    },
    [fetchLairProcesses.rejected]: (state, action) => {
      state.status = 'rejected'; // error
    },
    [fetchKillProcess.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchKillProcess.fulfilled]: (state, action) => {
      state.status = 'idle';
    },
    [fetchKillProcess.rejected]: (state, action) => {
      state.status = 'rejected'; // error
    },
    [fetchStopLairTerminal.pending]: (state, action) => {
    },
    [fetchStopLairTerminal.fulfilled]: (state, action) => {
    },
    [fetchStopLairTerminal.rejected]: (state, action) => {
      // TODO CONSOLE STREAMING - IF CANT STOP WHAT SHOULD WE DO? RESEND?
    },
    [fetchProcessHistory.pending]: (state, action) => {
    },
    [fetchProcessHistory.fulfilled]: (state, action) => {
    },
    [fetchProcessHistory.rejected]: (state, action) => {
    },
  },
});

export const {
  resetTerminalProcessId,
  terminalAdded,
  terminalRemoved,
  terminalsRemoved,
  terminalUpdated,
  terminalsUpdated,
  terminalUpserted,
  terminalsUpdserted,
  terminalsLoaded,
  setTerminalProcessId,
  setTerminalServiceId,
  setProcessesView,
  addTerminalMessage,
  setTerminalMessages,
  setTerminalStatus,
  incrementTerminalSocketId,
  setTerminalCommand,
  setMoreProcessesStatus,
  resetProcesses,
} = processesSlice.actions;

const {
  setTabs,
  addTab,
  deleteTab,
  setTabDragSource,
  setFocused,
} = processesSlice.actions;

export {
  setTabs as setProcessesTabs,
  addTab as addProcessesTab,
  deleteTab as deleteProcessesTab,
  setTabDragSource as setProcessesTabDragSource,
  setFocused as setProcessesFocused,
};

const selectAllProcesses = processesAdapter.getSelectors((state) => state.processes);
const {selectAll, selectById} = selectAllProcesses;
export {selectAll as selectAllProcesses, selectById as selectProcessById};

export const selectFocusedProcess = (state) => {
  return state.processes.focused;
};

export const selectIsTerminalFocused = (state) => {
  const processFocused = selectFocusedProcess(state);
  return !processFocused;
};

export const selectProcessesTabsAddedOrder = (state) => {
  return state.processes.tabs_added_order;
};

export const selectProcessPropById = (state, id, prop) => {
  const process = selectById(state, id);
  if (!prop || !process) return;
  return process[prop];
};

export const selectProcessTabPropById = (state, prefixedId, prop) => {
  const {id} = convertPrefixedIdToObj(prefixedId);
  return selectProcessPropById(state, id, prop);
};

export const selectFocusedProcessId = (state) => {
  const focused = selectFocusedProcess(state);
  if (!focused) return null;
  return convertPrefixedIdToObj(focused).id;
};

export const selectFocusedProcessProp = (state, prop) => {
  const id = selectFocusedProcessId(state);
  return selectProcessPropById(state, id, prop);
};

export const selectFocusedProcessPropById = (state, id, prop) => {
  const process = selectById(state, id);
  if (!prop || !process) return;
  return process[prop];
};

export const selectTerminalStatus = (state) => {
  return state.processes.terminal_status;
};

export const selectProcessesStatus = (state) => {
  return state.processes.status;
};

export const selectMoreProcessesStatus = (state) => {
  return state.processes.more_processes_status;
};

export const selectTerminalProcessId = (state) => {
  return state.processes.terminal_process_id;
};

export const selectTerminalServiceId = (state) => {
  return state.processes.terminal_service_id;
};

export const selectTerminalMessages = (state) => {
  return state.processes.terminal_messages;
};

export const selectFocusedProcessOrTerminalId = (state) => {
  const isTerminalFocused = selectIsTerminalFocused(state);
  if (isTerminalFocused) {
    return selectTerminalProcessId(state);
  } else {
    return selectFocusedProcessId(state);
  }
};

export const selectFocusedProcessOrTerminalServiceId = (state) => {
  const isTerminalFocused = selectIsTerminalFocused(state);
  if (isTerminalFocused) {
    return selectTerminalServiceId(state);
  } else {
    // service_id not used for processes (only terminal)
    return selectFocusedProcessId(state);
  }
};

export const selectProcessesData = (state) => {
  const lairId = selectCurrentLairId(state);
  const processes = selectAll(state);
  return Object.values(processes).filter(p => {
    return p.lair_id === lairId;
  });
};

export const selectCompletedProcessesData = (state) => {
  const data = selectProcessesData(state);
  return data.filter(p => {
    return p.status !== 'running' && p.status !== 'starting';
  });
};

export const selectProcessesView = (state) => {
  return state.processes.view;
};

// Clear the terminal session data when switching lair
export const terminalClear = () => (dispatch, getState) => {
  dispatch(setTerminalProcessId(null));
  dispatch(setTerminalServiceId(null));
  dispatch(clearTerminalMessages());
  dispatch(setTerminalStatus('init'));
};

export const clearTerminalMessages = () => (dispatch, getState) => {
  dispatch(setTerminalMessages([]));
};

export const openProcess = (id) => (dispatch, getState) => {
  const state = getState();
  const lairView = selectLairView(state);
  dispatch(setFocused({id: id, lairId: lairView}));
  dispatch(setProcessesView('terminal'));
  dispatch(addTab({id: id, lairId: lairView}));
};

export const focusProcessTab = (id) => (dispatch, getState) => {
  dispatch(setFocused(id));
};

export const closeProcess = (id) => (dispatch, getState) => {
  const state = getState();
  const focused = selectFocusedProcess(state);
  dispatch(deleteTab(id));
  if (focused === id) {
    const state = getState();
    const tabsAddedOrder = selectProcessesTabsAddedOrder(state);
    if (tabsAddedOrder.length === 0) dispatch(setFocused(null));
    else dispatch(setFocused(tabsAddedOrder[tabsAddedOrder.length - 1]));
  }
};

export const focusTerminalTab = () => (dispatch, getState) => {
  dispatch(setProcessesView('terminal'));
  dispatch(setFocused(null));
};

const processTableDisplayStyle = (rowId, value, status, view) => {
  const running = theme.colors.success[500];
  const fail = theme.colors.error[500];
  const text = theme.colors.dark.grey[900];
  const done = view === 'editor' ? theme.colors.dark.grey[300] : theme.colors.dark.grey[100];
  let rowStyle;
  switch (rowId) {
  case 'started':
    rowStyle = `${new Date(value).toDateString()}, ${new Date(value).toLocaleTimeString()}`;
    break;
  case 'status':
    rowStyle = {
      node: <span style={{
        backgroundColor: value === 'starting' || value === 'running' ? running : (value === 'done' ? done : fail),
        borderRadius: '.2rem',
        padding: '.2rem .5rem',
        color: text,
        fontSize: '.9rem',
        textTransform: 'capitalize',
      }}>
        {value}
      </span>,
    };
    break;
  case 'source':
    rowStyle = <span style={{display: 'flex', alignItems: 'center'}}><IconPlay height="15px" width="15px" /><span style={{paddingLeft: '5px'}}>{value}</span></span>;
    break;
  default:
    // rowStyle = {node: <span style={{color: (status === 'killed' || status === 'done') && done}}>{value}</span>};
    rowStyle = {
      node: <Text color={(status === 'killed' || status === 'done') && done}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
      >
        {value}
      </Text>,
    };
  }
  return rowStyle;
};

export const selectProcessTableRowIds = (state, view = 'editor') => {
  let processesData = view === 'editor' ? selectProcessesData(state) : selectCompletedProcessesData(state);
  processesData.sort((a, b) => moment.utc(b.created_date) - moment.utc(a.created_date));
  processesData = processesData.map(data => {
    return {
      ...data,
      created_date: moment(moment.utc(data.created_date).toDate()).local().format('MMMM Do YYYY, h:mm:ss a'),
    };
  });
  return JSON.stringify(processesData.map(p => p.id));
};

export const selectProcessTableHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectProcessTableRowData = (state, processId, rowIndex, columns, view = 'editor') => {
  const process = selectById(state, processId);

  const data = {};
  columns.forEach(c => {
    data[c] = {
      display: processTableDisplayStyle(c, process[c], process.status, view),
    };
  });
  data.useDispatch = view === 'editor';
  data.onClick = view !== 'log' && (() => view === 'editor' && openProcess(process.id));
  if (view === 'editor') {
    data.overflowMenu = {
      title: '',
      items: [
        {
          title: '',
          items: [
            {
              display: 'Kill',
              useDispatch: true,
              onClick: () => killProcess(processId),
            },
          ],
        },
      ],
    };
  }
  return data;
};

export const convertIdToPrefixedId = (id, lairId) => {
  return lairId + TAB_DELIM + id;
};

export const convertPrefixedIdToObj = (id) => {
  const elements = id.split(TAB_DELIM);
  return {
    lairId: elements[0],
    id: elements[1],
  };
};

export const selectProcessesTabs = (state) => {
  const lairView = selectLairView(state);
  return state.processes.tabs && state.processes.tabs.filter(tab => convertPrefixedIdToObj(tab).lairId === lairView);
};

export const selectProcessesTabDragSource = (state) => {
  return state.processes.drag_source;
};

export const reOrderProcessesTab = (id, targetId) => (dispatch, getState) => {
  const state = getState();
  const tabs = [...state.processes.tabs];
  const tabIndex = tabs.indexOf(id);
  const targetIndex = tabs.indexOf(targetId);
  if (!targetId || !id || id === targetId || tabIndex === -1 || targetIndex === -1) return;
  const isLeft = tabIndex < targetIndex;
  const increment = isLeft ? 1 : -1;
  let i = tabIndex;
  while (isLeft ? i < targetIndex : i > targetIndex) {
    tabs[i] = tabs[i + increment];
    i = i + increment;
  }
  tabs[targetIndex] = id;
  dispatch(setTabs(tabs));
};

export const selectTerminalSocketId = (state) => {
  return state.processes.socket_id;
};

export const selectTerminalCommand = (state) => {
  return state.processes.terminal_command;
};

export const selectShouldStartTerminalProcess = (state) => {
  const processId = selectTerminalProcessId(state);
  const socketId = selectTerminalSocketId(state);
  const lairView = selectLairView(state);
  if (!processId && socketId && lairView) return true; // initialize
  if (processId) {
    const process = selectById(state, processId);
    if (process) {
      if (!['running'].includes(process.status)) {
        return true;
      }
    }
  }
  return false;
};

export default processesSlice.reducer;
