/* global
    Rollbar,
*/
import React, {useEffect} from 'react';
import {Flex, Button, Text} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {debugLog} from '../../helpers';

export const logError = (message = 'error', data = {}) => {
  if (typeof Rollbar === 'object') {
    Rollbar.critical(message, data);
  }
  debugLog(message, data);
};

const ErrBound = ({
  error = '',
  onReset = null,
  ...props
}) => {
  useEffect(() => {
    logError(`ERROR BOUNDARY: ${error || ''}`);
  }, [error]);
  return (
    <Flex
      flexWrap="wrap"
      px={1}
      py={4}
    >
      <Flex
        flex="0 0 100%"
        justify="center">
        <Text as="p" fontWeight="700" pb={2}>Something went wrong</Text>
      </Flex>
      <Flex
        flex="0 0 100%"
        justify="center">
        { props.onReset &&
            <Button
              variant="secondary"
              m={2}
              size="md"
              onClick={ props.onReset }>
                Reset
            </Button>
        }
        <Button
          variant="secondary-outline"
          m={2}
          size="md"
          onClick={() => window.open('https://discord.gg/SEAPQnCTpp', '_blank').focus()}
        >
            Report Bug
        </Button>
        <Button
          variant="secondary-outline"
          m={2}
          size="md"
          onClick={ () => window.location.reload() }>
            Refresh Page
        </Button>
      </Flex>
    </Flex>
  );
};

ErrBound.propTypes = {
  error: PropTypes.string,
  onReset: PropTypes.func,
};

export default ErrBound;
