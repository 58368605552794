import getConfig from 'next/config';
import checkIsElectron from 'is-electron';
import {defaultBackendDomain, defaultFrontendDomain} from '../../helpers';

const config = getConfig();
const publicRuntimeConfig = config ? config.publicRuntimeConfig || {} : {};

export const FILE_EXTENSIONS = {
  'py': {
    executable: true,
    editable: true,
    icon: 'pyFile',
  },
  'js': {
    executable: true,
    editable: true,
    icon: 'jsFile',
  },
  'txt': {
    executable: false,
    editable: true,
    icon: 'txtFile',
  },
  'csv': {
    executable: false,
    editable: true,
    icon: 'csvFile',
  },
  'secrets': {
    executable: false,
    editable: true,
    icon: 'secretsFile',
  },
  'ws': {
    executable: true,
    editable: true,
    icon: 'wsFile',
  },
  'env': {
    executable: false,
    editable: true,
    icon: 'envFile',
  },
  'md': {
    executable: false,
    editable: true,
    icon: 'mdFile',
  },
  'prod': {
    executable: false,
    editable: false,
    icon: 'prod',
  },
  'triggers': {
    executable: false,
    editable: true,
    icon: 'triggers',
  },
  'zip': {executable: false, editable: true, binary: true},
  'jpg': {executable: false, editable: true, binary: true},
  'png': {executable: false, editable: true, binary: true},
  'gif': {executable: false, editable: true, binary: true},
  'bmp': {executable: false, editable: true, binary: true},
  'tiff': {executable: false, editable: true, binary: true},
  'psd': {executable: false, editable: true, binary: true},
  'mp4': {executable: false, editable: true, binary: true},
  'mkv': {executable: false, editable: true, binary: true},
  'avi': {executable: false, editable: true, binary: true},
  'mov': {executable: false, editable: true, binary: true},
  'mpg': {executable: false, editable: true, binary: true},
  'vob': {executable: false, editable: true, binary: true},
  'mp3': {executable: false, editable: true, binary: true},
  'aac': {executable: false, editable: true, binary: true},
  'wav': {executable: false, editable: true, binary: true},
  'flac': {executable: false, editable: true, binary: true},
  'ogg': {executable: false, editable: true, binary: true},
  'mka': {executable: false, editable: true, binary: true},
  'wma': {executable: false, editable: true, binary: true},
  'pdf': {executable: false, editable: true, binary: true},
  'doc': {executable: false, editable: true, binary: true},
  'xls': {executable: false, editable: true, binary: true},
  'ppt': {executable: false, editable: true, binary: true},
  'docx': {executable: false, editable: true, binary: true},
  'odt': {executable: false, editable: true, binary: true},
  'rar': {executable: false, editable: true, binary: true},
  '7z': {executable: false, editable: true, binary: true},
  'tar': {executable: false, editable: true, binary: true},
  'iso': {executable: false, editable: true, binary: true},
  'mdb': {executable: false, editable: true, binary: true},
  'aacde': {executable: false, editable: true, binary: true},
  'frm': {executable: false, editable: true, binary: true},
  'sqlite': {executable: false, editable: true, binary: true},
};

export const TAB_DELIM = String.fromCodePoint(0xF0006);

export const SELF_HOSTED = publicRuntimeConfig?.NEXT_PUBLIC_SELF_HOSTED === 'true' || false;

const isElectron = checkIsElectron() && (typeof window !== 'undefined' && !window.Cypress);
let frontendDomain;
let backendDomain;
if (isElectron) {
  backendDomain = typeof window === 'undefined' ? null : window.electron.domains.backend();
  frontendDomain = typeof window === 'undefined' ? null : window.electron.domains.frontend();
}

const NEXT_PUBLIC_DOMAIN = publicRuntimeConfig?.NEXT_PUBLIC_DOMAIN || defaultBackendDomain;
const LOCAL_PUBLIC_DOMAIN = publicRuntimeConfig?.NEXT_PUBLIC_DOMAIN || 'local';
export const DOMAIN = backendDomain !== undefined
  ? backendDomain
  : (typeof process === 'undefined'
    ? NEXT_PUBLIC_DOMAIN
    : LOCAL_PUBLIC_DOMAIN);

const NEXT_PUBLIC_DOMAIN_FE = publicRuntimeConfig?.NEXT_PUBLIC_DOMAIN_FE || defaultFrontendDomain;
const LOCAL_PUBLIC_DOMAIN_FE = publicRuntimeConfig?.NEXT_PUBLIC_DOMAIN_FE || 'https://localhost:8000/';

export const DOMAIN_FE = frontendDomain !== undefined
  ? frontendDomain
  : (typeof process === 'undefined'
    ? NEXT_PUBLIC_DOMAIN_FE
    : LOCAL_PUBLIC_DOMAIN_FE);

export const PLATFORM = typeof window === 'undefined'
  ? 'mac'
  : window.navigator?.platform.toLowerCase().includes('mac') ? 'mac' : 'win';

export const STATUS_INVALID_WORKSPACE = 420;

export const IS_MOCK = publicRuntimeConfig?.NEXT_PUBLIC_MOCK === 'all';

export const IS_WEB_PROD = typeof process !== 'undefined' && process?.env.NEXT_PUBLIC_PROD === 'true';

export const IS_ELECTRON_PROD = typeof process === 'undefined';

export const IS_DEV = !IS_WEB_PROD && !IS_ELECTRON_PROD;

export const TRIGGER_TYPES = {
  cron: {endpoint: false},
  http: {endpoint: true, uniqueSettings: ['path']},
  deploy: {endpoint: true},
};

export const TRIGGER_ENDPOINT_TYPES = Object.entries(TRIGGER_TYPES)
  .filter(entry => entry[1].endpoint)
  .map(entry => entry[0]);

export const TAB_TYPES = ['file', 'file-comparison', 'triggers', 'secrets', 'env'];

export const TOOLBAR_WIDTH = 2.3;

export const RETRY_FETCH_LIMIT = 3;
