import React from 'react';

const SVG = ({
  style = {},
  fill = '#fff',
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 20 20',
  children = '',
  handleClick,
  id,
}) => (
  <svg
    id={id}
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    onClick={(e) => {
      if (e.target.className.baseVal.includes('tutorial-block')) return;
      if (handleClick) handleClick(e);
    }}
  >
    {children}
  </svg>
);

export {SVG};
