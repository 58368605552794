import fetchJson from '../../lib/fetchJson';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  selectFocusedId,
} from '../editor/EditorSlice';
import {
  selectFilePropById,
  fetchFileSave,
  selectFileDescendantsWithFilter,
  selectWorkspaceRoot,
  selectFileById,
  fetchAddFile,
  selectAllLairFiles,
  selectLairViewFileId,
  selectTriggersFileId,
  buildFilePath,
  syncFiles,
  selectIntegrationsFileId,
} from '../files/FilesSlice';
import {
  selectCurrentLairId,
  selectLairView,
} from '../lairs/LairsSlice';
import {v4 as uuidv4} from 'uuid';
import {TRIGGER_TYPES} from '../../app/constants';
import {FILE_TYPE_RUN_COMMANDS} from '../files/constants';
import {generateUniquePath, isElectron} from '../../utils/helpers';
import {selectAllIntegrations} from '../integrations/IntegrationsSlice';
import {triggerIcons} from './constants';
import * as analytics from '../../utils/analytics';

const triggersAdapter = createEntityAdapter({
  selectId: (trigger) => trigger.name,
});

export const fetchTriggers = createAsyncThunk(
  'triggers/fetchTriggers',
  async() => {
    const data = await fetchJson('/triggers/metadata');
    return data;
  },
);

const triggersSlice = createSlice({
  name: 'triggers',
  initialState: triggersAdapter.getInitialState({
    status: 'init',
    ids: [],
    entities: {},
  }),
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchTriggers.fulfilled]: (state, action) => {
      const triggers = action.payload;
      const triggerEntities = triggers.map(trigger => {
        return {
          schema: trigger.schema,
          ...trigger.meta,
        };
      });
      triggersAdapter.setAll(state, triggerEntities);
      state.status = 'idle';
    },
  },
});

const {selectAll, selectById} = triggersAdapter.getSelectors((state) => state.triggers);
export {selectAll as selectAllTriggers, selectById as selectTriggerByName};

export const selectAllAvailableTriggers = (state) => {
  const triggers = selectAll(state);
  const integrations = selectAllIntegrations(state);
  return triggers.map(trigger => {
    const hasIntegration = integrations.reduce((acc, integration) => {
      return acc || trigger.name === integration.type;
    }, false);

    if (!hasIntegration) return trigger;

    const fileId = selectIntegrationsFileId(state);
    let content = selectFilePropById(state, fileId, 'content');
    let isSetup;
    if (!content) {
      isSetup = false;
    } else {
      content = JSON.parse(content);
      isSetup = content.reduce((acc, entry) => {
        return acc || trigger.name === entry.name;
      }, false);
    }

    if (isSetup) return trigger;

    return {
      ...trigger,
      isUnavailable: true,
    };
  });
};

export const selectTriggerInfo = (state, view) => {
  if (view?.includes('trigger-')) {
    const row = Number(view.split('-')[1]);
    const fileId = selectFocusedId(state);
    let content = selectFilePropById(state, fileId, 'content');
    if (!content) return {};
    content = JSON.parse(content);
    return content[row];
  } else return {};
};

export const getTriggerEndpointUrl = (domain, triggerSettings) => {
  let url = domain;
  if (triggerSettings) {
    const {type, settings} = triggerSettings;
    if (type === 'http') url += settings.path || '';
  }
  return url;
};

export const selectTriggerEndpointUrl = (state, lairId, triggersView) => {
  let triggerSettings;
  if (triggersView && triggersView.includes('trigger-')) {
    triggerSettings = selectTriggerInfo(state, triggersView);
  }

  const domain = selectFilePropById(state, lairId, 'domain');
  return getTriggerEndpointUrl(domain, triggerSettings);
};

export const selectTriggersTableRowIds = (state) => {
  const fileId = selectFocusedId(state);
  let content = selectFilePropById(state, fileId, 'content');
  if (!content) return null;
  content = JSON.parse(content);
  return JSON.stringify(content.map((c, i) => `trigger-${i}`)); // use indices to identify triggers
};

export const selectTriggersTableRowData = (state, triggerId, rowIndex, columns, setView) => {
  const fileId = selectFocusedId(state);
  let content = selectFilePropById(state, fileId, 'content');
  if (!content) return null;
  content = JSON.parse(content);
  const trigger = content[rowIndex];
  const lairId = selectCurrentLairId(state);
  const data = {};
  const renderDisplay = (trigger, id) => {
    let display = trigger[id];
    if (id === 'type') {
      display = {
        icon: triggerIcons[trigger[id]],
        text: trigger[id],
      };
    }
    return display;
  };

  columns.forEach(c => {
    const endpoint = selectTriggerEndpointUrl(state, lairId, triggerId);
    let display;
    if (c === 'endpoint') {
      display = TRIGGER_TYPES[trigger.type].endpoint
        ? {endpoint: endpoint}
        : {text: 'n/a'};
    } else { display = renderDisplay(trigger, c); }
    data[c] = {
      display,
    };
  });
  data.onClick = () => setView(`trigger-${rowIndex}`);
  return data;
};

export const selectTriggersTableHeadRowData = (state, columns) => {
  const data = {};
  columns.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  return data;
};

export const selectTriggerSettingsView = (state, triggersView) => {
  if (!triggersView.includes('trigger-')) return null;
  const triggerSettings = selectTriggerInfo(state, triggersView);
  const triggerInfo = selectById(state, triggerSettings.type);
  if (triggerSettings === null || !triggerInfo) return null;
  const lairId = selectCurrentLairId(state);
  const lairFile = selectFileById(state, lairId);
  if (!lairFile) return null;
  const entryOptions = selectFileDescendantsWithFilter(
    state,
    lairFile.id,
    (file) => {
      return /^.+\.(py)$/.test(file.name);
    },
  );
  const lairFiles = Object.values(selectAllLairFiles(state));
  const excludeIds = lairFiles
    .map(l => l.id).filter(id => id !== lairFile.id);
  const workspaceRoot = selectWorkspaceRoot(state);
  const testTriggerOptions = selectFileDescendantsWithFilter(
    state,
    workspaceRoot.id,
    (file) => {
      return /^.+\.(json)$/.test(file.name);
    },
    excludeIds,
  );

  // All events have an id, test_event, and entrypoint
  const elements = [
    {
      id: 'id',
      name: 'id',
      display_name: 'Trigger Name',
      type: 'text_input',
      default: '',
      placeholder: 'myTrigger',
      value: triggerSettings.id,
    }, {
      id: 'command',
      name: 'command',
      display_name: 'Command To Run',
      type: 'command',
      default: 'echo hello world',
      values: entryOptions.map(file => file.path),
      formula: FILE_TYPE_RUN_COMMANDS.py,
      value: triggerSettings.command,
    },
  ];
  const {schema} = triggerInfo;
  elements.push(...getTriggerSettingsElements('root', triggerSettings.settings, schema));

  const testSource = {
    id: 'test_event',
    name: 'test_event',
    display_name: 'Test Source',
    type: 'file_selector',
    default: '',
    value: triggerSettings.test_event,
    values: [''].concat(testTriggerOptions.map(file => {
      const filePath = buildFilePath(state, file.id, false).split('/');
      filePath.shift();
      return filePath.join('/');
    })),
  };
  elements.push(testSource);

  const endpoint = getTriggerEndpointUrl(lairFile.domain, triggerSettings);
  return {
    endpoint,
    elements,
    runDisabled: !triggerSettings.command,
  };
};

export const selectUniqueTriggerSetting = (state, defaultValue, id, newTriggerType) => {
  const typeSetting = TRIGGER_TYPES[newTriggerType];
  const requiresUniqueValue = typeSetting?.uniqueSettings?.includes(id);
  if (requiresUniqueValue) {
    const fileId = selectTriggersFileId(state);
    const content = JSON.parse(selectFilePropById(state, fileId, 'content'));
    const settingValues = content.map(c => c.settings[id]);
    return generateUniquePath('', settingValues);
  } else {
    return defaultValue;
  }
};

export const addTrigger = type => async(dispatch, getState) => {
  analytics.track(analytics.triggerAddedEvent, {type});
  const state = getState();
  const fileId = selectFocusedId(state);
  let content = JSON.parse(selectFilePropById(state, fileId, 'content'));
  const triggerIds = content.map(trigger => trigger.id);
  let newTriggerType = type;
  let ind = 2;
  while (triggerIds.includes(newTriggerType)) {
    newTriggerType = `${type}-${ind}`;
    ind++;
  }
  const triggerInfo = selectById(state, type);
  const settings = {};
  const {schema} = triggerInfo;

  const upsertNode = (item) => {
    let id;
    if (typeof item === 'string') {
      id = item;
    } else {
      id = item.id;
    }
    const elementSettings = schema[id];
    settings[id] = selectUniqueTriggerSetting(state, elementSettings.default || elementSettings.value, id, type);
    addChildren(id, schema[id]);
  };

  const addChildren = (id, node) => {
    let {children, default: defaultValue} = node;
    if (children) {
      if (!Array.isArray(children)) {
        if (defaultValue) {
          children = children[defaultValue] || [];
        } else children = [];
      }
      children.forEach(upsertNode);
    }
  };

  addChildren('root', schema.root);

  content = [
    ...content,
    {
      type,
      id: newTriggerType,
      command: 'echo hello world',
      test_event: '',
      settings,
    },
  ];
  const newContent = JSON.stringify(content);
  const data = await dispatch(fetchFileSave({id: fileId, value: newContent}));
  if (data.error) {
    return data;
  }
  return {
    triggerId: `trigger-${content.length - 1}`,
  };
};

export const removeTrigger = row => (dispatch, getState) => {
  const state = getState();
  const fileId = selectFocusedId(state);
  let content = JSON.parse(selectFilePropById(state, fileId, 'content'));
  const rowNum = Number(row.split('-')[1]);
  content = content.filter((c, i) => i !== rowNum);
  const newContent = JSON.stringify(content);
  dispatch(fetchFileSave({id: fileId, value: newContent}));
};

// create a `test_{triggerType}.json` file in active lair and return its file_uuid
export const addTestTriggers = (triggerId) => async(dispatch, getState) => {
  const state = getState();
  const triggerGivenId = selectTriggerInfo(state, triggerId).id;
  const lairId = selectLairView(state);
  const lairFile = selectFileById(state, lairId);
  const fileNames = lairFile.children.map(childId => {
    return selectFilePropById(state, childId, 'name');
  });
  let ind = 2;
  let newFileName = `test_${triggerGivenId}.json`;
  while (fileNames.includes(newFileName)) {
    newFileName = `test_${triggerGivenId}-${ind}.json`;
    ind++;
  }
  const id = uuidv4();
  await dispatch(fetchAddFile({
    parent: lairId,
    name: newFileName,
    is_directory: false,
    id,
    content: '{"key": "value"}',
  }));
  let filePath = buildFilePath(getState(), id, false).split('/');
  // remove workspace
  filePath.shift();
  filePath = filePath.join('/');

  await dispatch(updateTriggers({
    id: triggerId,
    updates: {
      test_event: filePath,
    },
  }));
  return id;
};

const getTriggerSettingsElements = (root, triggerSettings, schema) => {
  let elements = [];
  const upsertNode = (item) => {
    let id;
    let updates = {};
    if (typeof item === 'string') {
      id = item;
    } else {
      id = item.id;
      updates = item;
    }
    let found;
    const setting = triggerSettings[id];
    const value = setting || schema[id].default;
    elements = elements.map(element => {
      if (element.id === id) {
        found = true;
        element = {...element, ...updates, value: value};
      }
      return element;
    });
    if (!found) elements.push({id, ...schema[id], ...updates, value: value});
    addChildren(id, schema[id]);
  };

  const addChildren = (id) => {
    const node = schema[id];
    let {children} = node;
    if (children) {
      if (!Array.isArray(children)) {
        const setting = triggerSettings[id];
        if (setting) {
          children = children[setting] || [];
        } else children = [];
      }
      children.forEach(upsertNode);
    }
  };

  addChildren(root);
  return elements;
};

export const updateTriggers = payload => async(dispatch, getState) => {
  const {id, updates, toast} = payload;
  const state = getState();
  const fileId = selectFocusedId(state);
  let content = JSON.parse(selectFilePropById(state, fileId, 'content'));
  const rowNum = Number(id.split('-')[1]);
  let rowContent = {...content[rowNum]};
  Object.entries(updates).forEach(entry => {
    const [name, value] = entry;
    if (['id', 'command', 'test_event'].includes(name)) {
      let newValue = value;
      if (name === 'id') {
        // ensure unique
        const integrationIds = content.map((integration, i) => i === rowNum ? null : integration.id);
        let ind = 2;
        while (integrationIds.includes(newValue)) {
          newValue = `${value}-${ind}`;
          ind++;
        }
      }
      rowContent = {
        ...rowContent,
        [name]: newValue,
      };
    } else {
      rowContent = {
        ...rowContent,
        settings: {
          ...rowContent.settings,
          [name]: value,
        },
      };

      // add any required elements in setting
      const triggerInfo = selectById(state, rowContent.type);
      if (triggerInfo) {
        const elements = getTriggerSettingsElements(name, rowContent.settings, triggerInfo.schema);
        elements.forEach(element => {
          const currentSetting = rowContent.settings[element.id];
          if (!currentSetting && (element.required || element.default)) {
            rowContent.settings[element.id] = element.default || null;
          }
        });
        if (elements.length) {
          rowContent = {
            ...rowContent,
            settings: {
              ...rowContent.settings,
            },
          };
        }
      }
    }
  });
  content = content.map((item, i) => {
    if (i === rowNum) return rowContent;
    return item;
  });
  const newContent = JSON.stringify(content);
  await dispatch(fetchFileSave({id: fileId, value: newContent, toast}));
};

// TODO update to use integration_servie when ready
export const fetchRunTrigger = createAsyncThunk(
  'triggers/runTrigger',
  async(view, {dispatch, getState}) => {
    const state = getState();
    const lairId = selectLairViewFileId(state);
    const triggersFileId = selectTriggersFileId(state);
    const fileJson = JSON.parse(selectFileById(state, triggersFileId).content);
    const trigger = fileJson[Number(view)];
    analytics.track(analytics.processManuallyInvokedEvent, {
      type: 'trigger',
      target: trigger.id,
    });
    if (isElectron) {
      await dispatch(syncFiles({pushReady: true}));
    }
    await fetchJson(`/lairs/${lairId}/triggers/${trigger.id}/manual`, {
      method: 'POST',
    });
  },
);

export default triggersSlice.reducer;
