import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';

const socketsAdapter = createEntityAdapter();

export const initialState = socketsAdapter.getInitialState({
  rooms: [],
});

const socketsSlice = createSlice({
  name: 'sockets',
  initialState,
  reducers: {
    addSocketRoom: (state, action) => {
      state.rooms.push(action.payload);
    },
    removeSocketRoom: (state, action) => {
      state.rooms = state.rooms.filter(room => {
        return action.payload !== room;
      });
    },
  },
  extraReducers: {
    // create async thunk reducers
  },
});

export const {
  addSocketRoom,
  removeSocketRoom,
} = socketsSlice.actions;

export const selectAllSocketRooms = (state) => {
  return state.sockets.rooms;
};

export const selectAllSocketRoomsStringified = (state) => {
  return JSON.stringify(selectAllSocketRooms(state));
};

export const selectIsSocketRoomConnected = (state, roomID) => {
  return state.scokets.rooms.includes(roomID);
};

export default socketsSlice.reducer;
