import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import fetchJson from '../../lib/fetchJson';
import {DOMAIN, DOMAIN_FE} from '../../app/constants';
import {
  getAccessToken,
  setAccessToken as _setAccessToken,
  logout as _logout,
} from '../../utils/authentication';
import {isElectron} from '../../utils/helpers';
import {wayscriptBackendDomain} from '../../../helpers';
import * as analytics from '../../utils/analytics';
import {logError as logRollbarError} from '../../components/ErrorBoundary';

export const setAccessToken = createAsyncThunk(
  '/setAccessToken',
  async({accessToken, userId}) => {
    await _setAccessToken(accessToken, userId);
  },
);

export const fetchCurrentUser = createAsyncThunk(
  '/fetchCurrentUser',
  async(args, {dispatch, rejectWithValue}) => {
    try {
      const user = await fetchJson('/users/current', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
      });
      if (wayscriptBackendDomain.includes(DOMAIN)) {
        try {
          analytics.identify({
            id: user.id,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            createdAt: user.created_date,
          });
          analytics.track(analytics.sessionStartedEvent, {client: isElectron ? 'desktop' : 'web'});
        } catch (error) {
          logRollbarError('Segment identify call failed', {email: user.email, id: user.id});
        }
      }
      if (user.id && isElectron) {
        await window.electron.message.invoke('message', {
          type: 'SET_USER_ID',
          payload: user.id,
        });
        await window.electron.message.invoke('message', {
          type: 'SET_DOMAINS',
          payload: {backendDomain: DOMAIN, frontendDomain: DOMAIN_FE, userId: user.id},
        });
      }
      // before user id is defined, accessToken is stored under
      // 'default' key. now make sure it is set under the userId key
      const accessToken = await getAccessToken();
      await dispatch(setAccessToken({accessToken, userId: user.id}));
      return user;
    } catch (error) {
      await _logout();
      return rejectWithValue('Fetch Current User failed');
    }
  },
);

export const logout = createAsyncThunk(
  '/logout',
  async(args, {dispatch}) => {
    analytics.track(analytics.signedOutEvent);
    await _logout();
  },
);

export const fetchUserApiKey = createAsyncThunk(
  '/fetchUserApiKey',
  async(args, {dispatch}) => {
    const {workspaceId, userId} = args;
    const apiKey = await fetchJson(`/workspaces/${workspaceId}/users/${userId}/application-key`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    });
    return apiKey;
  },
);

export const fetchRegenerateKey = createAsyncThunk(
  '/fetchRegenerateKey',
  async(args, {dispatch}) => {
    const {workspaceId, userId} = args;
    const newApiKey = await fetchJson(`/workspaces/${workspaceId}/users/${userId}/application-key/refresh`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
    });
    return newApiKey;
  },
);

const initialState = {
  status: 'init',
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  avatar: null,
  apiKey: null,
  status_access_token: 'init',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.current = action.payload;
    },
  },
  extraReducers: {
    [setAccessToken.pending]: (state, action) => {
      if (state.status_access_token === 'init') state.status_access_token = 'pending';
    },
    [setAccessToken.fulfilled]: (state, action) => {
      state.status_access_token = 'idle';
    },
    [logout.fulfilled]: (state, action) => {
      state.status = 'logout';
      state.id = null;
    },
    [fetchCurrentUser.fulfilled]: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.avatar = action.payload.avatar;
      state.status = 'idle';
    },
    [fetchCurrentUser.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchCurrentUser.rejected]: (state, action) => {
      const {error} = action;
      state.status = 'rejected';
      if (error?.message !== 'UNAUTHORIZED') {
        if (isElectron) {
          _logout();
          state.status = 'logout';
        }
      }
    },
    [fetchUserApiKey.fulfilled]: (state, action) => {
      state.apiKey = action.payload.application_key;
    },
    [fetchRegenerateKey.fulfilled]: (state, action) => {
      state.apiKey = action.payload.application_key;
    },
  },
});

export const {setCurrentUser} = userSlice.actions;

export const selectUser = (state) => {
  return state.user;
};

export const selectUserProp = (state, prop) => {
  return state.user[prop];
};

export default userSlice.reducer;
