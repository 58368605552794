import React from 'react';
const SelectorUserOption = ({value, avatar}) => {
  return (<span style={{display: 'flex', alignItems: 'center'}}>
    {avatar
      ? <img style={{height: '1.2rem', width: '1.2rem', borderRadius: '50%', marginRight: '.2rem'}} src={avatar} />
      : <span style={{height: '1.2rem', width: '1.2rem', borderRadius: '50%', marginRight: '.2rem', border: '1px solid grey'}}></span>
    }
    <span>{value}</span>
  </span>
  );
};

export default SelectorUserOption;
