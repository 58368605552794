import fetchJson from '../../lib/fetchJson';
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  selectFilePropById,
  fetchFileSave,
  selectIntegrationsFileId,
} from '../files/FilesSlice';
import {selectSelectedWorkspaceId} from '../../app/WorkspacesSlice';
import {integrationCategories} from './constants';
const integrationsAdapter = createEntityAdapter({
  selectId: (integration) => integration.id,
});

export const fetchIntegrations = createAsyncThunk(
  'integrations/fetchIntegrations',
  async(userId, thunkAPI) => {
    const data = await fetchJson('/integrations/metadata');
    return data;
  },
);

export const fetchWorkspaceIntegrations = createAsyncThunk(
  'integrations/fetchWorkspaceIntegrations',
  async(workspaceId, {getState}) => {
    const data = await fetchJson(`/workspace-integrations/workspace/${workspaceId}`);
    return data;
  },
);

export const fetchCreateIntegration = createAsyncThunk(
  'integrations/fetchCreateIntegration',
  async(args = {}, {getState}) => {
    const state = getState();
    const {type, name, settings} = args;
    const workspaceId = selectSelectedWorkspaceId(state);
    const data = await fetchJson('/workspace-integrations', {
      method: 'POST',
      body: JSON.stringify({
        workspace_id: workspaceId,
        type,
        name,
        settings,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchUpdateIntegration = createAsyncThunk(
  'integrations/fetchUpdateIntegration',
  async(args = {}, {getState}) => {
    const state = getState();
    const {id, type, name, settings} = args;
    const workspaceId = selectSelectedWorkspaceId(state);
    const data = await fetchJson(`/workspace-integrations/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        workspace_id: workspaceId,
        type,
        name,
        settings,
      }),
      headers: {'Content-Type': 'application/json'},
    });
    return data;
  },
);

export const fetchRemoveIntegration = createAsyncThunk(
  'integrations/fetchRemoveIntegration',
  async(id, {getState}) => {
    await fetchJson(`/workspace-integrations/${id}`, {
      method: 'DELETE',
    });
    return id;
  },
);

export const preProcessIntegrationPayload = (payload) => {
  const newPayload = {
    ...payload,
    settings: JSON.parse(payload.settings),
  };
  return newPayload;
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: integrationsAdapter.getInitialState({
    status: 'init',
    statusMetadata: 'init',
    ids: [],
    metadata: {},
    entities: {},
  }),
  reducers: {
    integrationsUpsertMany: integrationsAdapter.upsertMany,
    integrationsRemoveOne: integrationsAdapter.removeOne,
  },
  extraReducers: {
    [fetchIntegrations.pending]: (state, action) => {
      state.statusMetadata = 'pending';
    },
    [fetchIntegrations.fulfilled]: (state, action) => {
      const metadata = action.payload.reduce((acc, integration) => {
        acc[integration.meta.type] = {
          schema: integration.schema,
          ...integration.meta,
        };
        return acc;
      }, {});
      state.metadata = metadata;
      state.statusMetadata = 'idle';
    },
    [fetchWorkspaceIntegrations.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchWorkspaceIntegrations.fulfilled]: (state, action) => {
      integrationsAdapter.upsertMany(state, action.payload);
      state.status = 'idle';
    },
    [fetchCreateIntegration.fulfilled]: (state, action) => {
      integrationsAdapter.upsertOne(state, action.payload);
    },
    [fetchUpdateIntegration.fulfilled]: (state, action) => {
      integrationsAdapter.upsertOne(state, action.payload);
    },
    [fetchRemoveIntegration.fulfilled]: (state, action) => {
      integrationsAdapter.removeOne(state, action.payload);
    },
  },
});

export const {
  integrationsUpsertMany,
  integrationsRemoveOne,
} = integrationsSlice.actions;

const {selectAll, selectById} = integrationsAdapter.getSelectors((state) => state.integrations);
export {selectAll as selectAllIntegrations, selectById as selectIntegrationByName};

export const integrationHasAllProps = (state, id, props) => {
  const integration = selectById(state, id);
  if (!integration) return;
  return Object.entries(props).reduce((acc, prop) => {
    const [key, value] = prop;
    return acc && integration[key] === value;
  }, true);
};

export const selectIntegrationsWithAllProps = (state, props) => {
  const integrations = selectAll(state);
  return Object.values(integrations).reduce((acc, integration) => {
    // traverse props and see if data.prop strictly equals each prop
    if (integrationHasAllProps(state, integration.id, props)) acc[integration.id] = integration;

    return acc;
  }, {});
};

export const selectAllWorkspaceIntegrations = (state) => {
  const workspaceId = selectSelectedWorkspaceId(state);
  return selectIntegrationsWithAllProps(state, {workspace_id: workspaceId});
};

export const selectIntegrationInfo = (state, id, options = {}) => {
  const {stringify} = options;
  const info = selectById(state, id);
  return stringify ? JSON.stringify(info) : info;
};

export const convertIntegrationTypeToDisplay = (type) => {
  const words = type.split('_');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const selectWorkspaceIntegrationIds = (state, options = {}) => {
  const {stringify} = options;
  const workspaceIntegrations = selectAllWorkspaceIntegrations(state);
  const workspaceIds = Object.keys(workspaceIntegrations);
  return stringify ? JSON.stringify(workspaceIds) : workspaceIds;
};
export const selectIntegrationsTableData = (state, setView) => {
  const fileId = selectIntegrationsFileId(state);
  let content = selectFilePropById(state, fileId, 'content');
  if (!content) return null;
  content = JSON.parse(content);
  const columnsInfo = [
    {id: 'service', display: 'Service'},
    {id: 'name', display: 'name'},
  ];
  const data = {};
  data.columns = columnsInfo.map(c => c.id);
  data.rows = content.map((c, i) => `integration-${i}`); // use indices to identify integrations
  columnsInfo.forEach(c => {
    const {id, display} = c;
    data[id] = {
      display,
    };
  });
  content.forEach((integration, i) => {
    data[`integration-${i}`] = {};
    columnsInfo.forEach(c => {
      let display;
      if (c.id === 'service') display = integration.name;
      else if (c.id === 'name') display = integration.id;
      data[`integration-${i}`][c.id] = {
        display,
      };
    });
    data[`integration-${i}`].onClick = () => setView(`integration-${i}`);
  });
  return data;
};

export const selectIntegrationsMetadata = (state, options = {}) => {
  const {stringify} = options;
  const integrationKeys = Object.keys(state.integrations.metadata);
  return stringify ? JSON.stringify(integrationKeys) : integrationKeys;
};

export const selectIntegrationSettingsView = (state, id, type) => {
  const integrationSettings = selectById(state, id)?.settings;
  let elements = [];
  const metadata = state?.integrations?.metadata;
  if (!metadata) return null;
  const integrationInfo = metadata[type];
  if (!integrationInfo) return null;
  const {schema} = integrationInfo;
  const {root} = schema;

  if (integrationInfo.allow_multiple) {
    elements.push({
      name: 'id',
      display_name: 'Integration Name',
      type: 'text_input',
      default: '',
      placeholder: 'myIntegration',
      value: integrationSettings?.id,
    });
  }

  const upsertNode = (item) => {
    let id;
    let updates = {};
    if (typeof item === 'string') {
      id = item;
      updates = {id: item};
    } else {
      id = item.id;
      updates = item;
    }
    let found;
    const setting = integrationSettings && integrationSettings[id];
    const value = setting || schema[id].default;
    elements = elements.map(element => {
      if (element.id === id) {
        found = true;
        element = {...element, ...updates, value: value};
      }
      return element;
    });
    if (!found) elements.push({id, ...schema[id], ...updates, value: value});
    addChildren(id, schema[id]);
  };

  const addChildren = (id, node) => {
    let {children} = node;
    if (children) {
      if (!Array.isArray(children)) {
        const setting = selectById(state, id);
        if (setting) {
          children = children[setting] || [];
        } else children = [];
      }
      children.forEach(upsertNode);
    }
  };

  addChildren('root', root);

  return elements;
};

export const selectActiveIntegrationForCategory = (state, category, options = {}) => {
  const {stringify} = options;
  const types = integrationCategories[category]?.integrations || [];
  let integrations = Object.values(selectAll(state));
  integrations = integrations.filter(integration => types.includes(integration.type));
  integrations = integrations.map(integration => ({
    id: integration.id,
    type: integration.type,
    integration_link: integration.integration_link,
  }));
  return stringify ? JSON.stringify(integrations) : integrations;
};

export const selectCategoryForIntegration = (type) => {
  return Object.values(integrationCategories).reduce((acc, value) => {
    if (value.integrations.includes(type)) acc = value;
    return acc;
  });
};

// NOT BEING USED
export const addIntegration = name => async(dispatch, getState) => {
  const state = getState();
  const fileId = selectIntegrationsFileId(state);
  let content = JSON.parse(selectFilePropById(state, fileId, 'content'));
  const integrationIds = content.map(integration => integration.id);
  let newIntegrationName = name;
  let ind = 2;
  while (integrationIds.includes(newIntegrationName)) {
    newIntegrationName = `${name}-${ind}`;
    ind++;
  }
  // use default values for elements that are always visible
  const integrationSettings = selectById(state, name);
  const settings = {};
  const {schema} = integrationSettings;

  const upsertNode = (item) => {
    let id;
    if (typeof item === 'string') {
      id = item;
    } else {
      id = item.id;
    }
    const elementSettings = schema[id];
    settings[id] = elementSettings.default || elementSettings.value;
    addChildren(id, schema[id]);
  };

  const addChildren = (id, node) => {
    let {children, default: defaultValue} = node;
    if (children) {
      if (!Array.isArray(children)) {
        if (defaultValue) {
          children = children[defaultValue];
        } else children = [];
      }
      children.forEach(upsertNode);
    }
  };

  addChildren('root', schema.root);

  content = [
    ...content,
    {
      name,
      id: newIntegrationName,
      settings,
    },
  ];
  const newContent = JSON.stringify(content);
  await dispatch(fetchFileSave({id: fileId, value: newContent}));
  return `integration-${content.length - 1}`;
};

// NOT BEING USED
export const removeIntegration = row => (dispatch, getState) => {
  const state = getState();
  const fileId = selectIntegrationsFileId(state);
  let content = JSON.parse(selectFilePropById(state, fileId, 'content'));
  const rowNum = Number(row.split('-')[1]);
  content = content.filter((c, i) => i !== rowNum);
  const newContent = JSON.stringify(content);
  dispatch(fetchFileSave({id: fileId, value: newContent}));
};

// NOT BEING USED
export const updateIntegrations = payload => (dispatch, getState) => {
  const {id, updates} = payload;
  const state = getState();
  const content = selectAll(state);
  let rowContent = selectById(state, id);
  Object.entries(updates).forEach(entry => {
    const [name, value] = entry;
    if (name === 'id') {
      // ensure unique
      const integrationIds = Object.values(content).map((integration) => integration.id);
      let ind = 2;
      let newValue = value;
      while (integrationIds.includes(newValue)) {
        newValue = `${value}-${ind}`;
        ind++;
      }
      rowContent = {
        ...rowContent,
        [name]: newValue,
      };
    } else {
      rowContent = {
        ...rowContent,
        settings: {
          ...rowContent.settings,
          [name]: value,
        },
      };
    }
  });

  // dispatch(fetchFileSave({id: fileId, value: newContent}));
};

export default integrationsSlice.reducer;
