import React from 'react';
import {extendTheme} from '@chakra-ui/react';
import {mode, createBreakpoints} from '@chakra-ui/theme-tools';

export const bodyFontSize = 13;
const fonts = {mono: '\'Menlo\', monospace'};

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
});

const themeColors = {
  grey: {
    light: ['#0F0E11', '#151316', '#19181C', '#211F24', '#28262C', '#45424D', '#686273', '#9691A1', '#BEBAC4', '#F2F1F3'],
    dark: ['#F2F1F3', '#BEBAC4', '#9691A1', '#686273', '#45424D', '#28262C', '#211F24', '#19181C', '#151316', '#0F0E11'],
    brand: ['#F2F1F3', '#BEBAC4', '#9691A1', '#686273', '#45424D', '#28262C', '#211F24', '#19181C', '#151316', '#0F0E11'],
  },
  fg: {
    light: ['#F2F1F3', '#BEBAC4', '#961A1'],
    dark: ['#0D0C0E', '#151316', '#19181C', '#211F24', '#28262C', '#45424D'],
    brand: ['#0D0C0E', '#151316', '#19181C', '#211F24', '#28262C', '#45424D'],
  },
  bg: {
    light: ['#961A1', '#BEBAC4', '#F2F1F3'],
    dark: ['#45424D', '#28262C', '#211F24', '#19181C', '#151316', '#0D0C0E'],
    brand: ['#45424D', '#28262C', '#211F24', '#19181C', '#151316', '#0D0C0E'],
  },
  primary: {
    light: ['#56406D', '#423597', '#5645BD', '#6F61C7', '#B397D6'],
    dark: ['#B397D6', '#6F61C7', '#5645BD', '#423597', '#56406D'],
    brand: ['#B397D6', '#6F61C7', '#5645BD', '#423597', '#56406D'],
  },
  secondary: {
    light: ['#417D9C', '#55A0CA'],
    dark: ['#55A0CA', '#417D9C'],
    brand: ['#55A0CA', '#417D9C'],
  },
  red: {
    light: '#D35454',
    dark: '#D35454',
    brand: '#D35454',
  },
  teal: {
    light: '#5EC3BD',
    dark: '#5EC3BD',
    brand: '#5EC3BD',
  },
  white: {
    light: '#F2F1F3',
    dark: '#F2F1F3',
    brand: '#F2F1F3',
  },
};

const buildColorDict = list => {
  const dict = {};
  list = Array.isArray(list) ? list : [list]; // convert single value to list
  const keys = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]; // chakra standard options
  keys.forEach((key, i) => {
    const index = Math.floor((list.length * i) / 10);
    dict[key] = list[index];
  });
  return dict;
};

const colors = {
  error: {
    50: '#D35454',
    100: '#D35454',
    200: '#D35454',
    300: '#D35454',
    400: '#D35454',
    500: '#D35454',
    600: '#D35454',
    700: '#D35454',
    800: '#D35454',
    900: '#D35454',
  },
  success: {
    50: '#91DC76',
    100: '#91DC76',
    200: '#91DC76',
    300: '#91DC76',
    400: '#91DC76',
    500: '#91DC76',
    600: '#91DC76',
    700: '#91DC76',
    800: '#91DC76',
    900: '#91DC76',
  },
  caution: {
    50: '#E3724E',
    100: '#E3724E',
    200: '#E3724E',
    300: '#E3724E',
    400: '#E3724E',
    500: '#E3724E',
    600: '#E3724E',
    700: '#E3724E',
    800: '#E3724E',
    900: '#E3724E',
  },
  warning: {
    50: '#EDBA3B',
    100: '#EDBA3B',
    200: '#EDBA3B',
    300: '#EDBA3B',
    400: '#EDBA3B',
    500: '#EDBA3B',
    600: '#EDBA3B',
    700: '#EDBA3B',
    800: '#EDBA3B',
    900: '#EDBA3B',
  },
};

// add processed themeColrs to colors.theme
Object.entries(themeColors).forEach((entry) => {
  const [colorKey, modeDict] = entry;
  Object.entries(modeDict).forEach((entry) => {
    const [modeKey, colorList] = entry;
    const mode = colors[modeKey];
    if (!mode) colors[modeKey] = {};
    colors[modeKey][colorKey] = buildColorDict(colorList);
  });
});

// TODO color mode not working on global components
const styles = {
  global: (props) => ({
    'body': {
      backgroundColor: mode(themeColors.bg.light[2], themeColors.bg.dark[5])(props),
      color: mode('black', 'gray.100')(props),
    },
    'html, body': {
      fontSize: `${bodyFontSize}px`,
      lineHeight: 'tall',
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, Noto Sans, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    },
    '.tippy-tooltip.menu-theme': {
      backgroundColor: 'transparent',
    },
    '.ws-terminal': {
      paddingLeft: '2px',
      backgroundColor: 'black',
    },
    a: {
      color: mode('teal.500', 'teal.300')(props),
    },
    button: {
      _focus: {
        boxShadow: '0 0 0 2px #9691A1 !important',
      },
    },
  }),
};
const components = {
  Modal: {
    variants: {
      dark: {
        dialog: {
          background: themeColors.bg.dark[1],
          paddingY: '1rem',
        },
        header: {
          paddingLeft: '2.5rem',
          paddingRight: '5rem',
        },
        body: {
          paddingX: '2.5rem',
        },
        footer: {
          paddingX: '2.5rem',
        },
        closeButton: {
          top: '2rem',
          right: '2rem',
        },
      },
    },
  },
  Tooltip: {
    variants: {
      hotkey: {
        background: themeColors.grey.dark[5],
        color: themeColors.grey.dark[0],
        borderRadius: '.25rem',
        border: `1px solid ${themeColors.grey.dark[4]}`,
        boxShadow: 'rgb(0 0 0 / 20%) 0px 4px 24px',
      },
    },
  },
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: props => ({
      dialog: {
        bg: mode('white', '#141214')(props),
      },
    }),
  },
  Button: {
    variants: {
      'link': {
        padding: 0,
        borderRadius: 0,
        background: 'transparent',
        color: themeColors.primary.dark[1],
        borderBottom: `1px solid ${themeColors.primary.dark[1]}`,
        _hover: {
          color: themeColors.primary.dark[0],
          borderBottom: themeColors.primary.dark[0],
        },
      },
      'primary': {
        background: themeColors.primary.dark[2],
        _hover: {background: '#42368b'},
      },
      'primary-outline': {
        background: 'transparent',
        border: '2px',
        borderColor: themeColors.primary.dark[2],
        _hover: {background: themeColors.grey.dark[5]},
      },
      'red': {
        background: themeColors.red.dark,
        _hover: {background: '#a74c4c'},
      },
      'secondary': {
        background: themeColors.secondary.dark[1],
        _hover: {background: themeColors.grey.dark[5]},
      },
      'secondary-outline': {
        background: 'transparent',
        border: '2px',
        borderColor: themeColors.secondary.dark[1],
        _hover: {background: themeColors.grey.dark[5]},
      },
      'white-outline': {
        background: 'transparent',
        border: '2px',
        borderColor: 'white',
        _hover: {background: themeColors.grey.dark[5]},
      },
      'grey': {
        background: themeColors.grey.dark[6],
        _hover: {background: themeColors.grey.dark[5]},
      },
      'grey-outline': {
        background: 'transparent',
        border: '2px',
        color: themeColors.grey.dark[3],
        borderColor: themeColors.grey.dark[3],
        _hover: {background: themeColors.grey.dark[6]},
      },
    },
  },
  ModalBody: {
    baseStyle: {
      paddingLeft: '0',
    },
  },
  Input: {
    defaultProps: {
      focusBorderColor: themeColors.grey.dark[3],
    },
  },
  Text: {
    variants: {
      'product-header1-bold': {
        fontWeight: '700',
        fontSize: '1.875rem',
      },
      'product-header1': {
        fontWeight: '400',
        fontSize: '1.875rem',
      },
      'product-header2': {
        fontWeight: '700',
        fontSize: '1.25rem',
      },
      'product-body': {
        fontWeight: '400',
        fontSize: '.8462rem',
      },
      'product-subtitle': {
        fontWeight: '500',
        fontSize: '1rem',
      },
      'product-label': {
        fontWeight: '500',
        fontSize: '.5625rem',
      },
      'product-code': {
        fontWeight: '400',
        fontSize: '.6875rem',
      },
      'marketing-header1': {
        fontWeight: '700',
        fontSize: '3.5rem',
      },
      'marketing-subheader1': {
        fontWeight: '400',
        fontSize: '1.75rem',
      },
      'marketing-header2': {
        fontWeight: '700',
        fontSize: '1.75rem',
      },
      'marketing-body': {
        fontWeight: '300',
        fontSize: '1.25rem',
      },
      'marketing-label': {
        fontWeight: '700',
        fontSize: '.9375rem',
      },
    },
  },
};

const fontSizes = {
  xs: '.6923rem', // 9px
  sm: '.8462rem', // 11px
  md: '1rem', // 13px
  lg: '1.2308rem', // 16px
  'xl': '1.5385rem', // 20px
  '2xl': '1.8462rem', // 24px
  '3xl': '2.1538rem', // 28px
  '4xl': '2.3077rem', // 30px
};

const space = {
  0: '0',
  1: '0.154rem', // 2px
  2: '0.308rem', // 4px
  3: '0.615rem', // 8px
  4: '1.231rem', // 16px
  5: '2.462rem', // 32px
  6: '4.923rem', // 64px
  7: '9.846rem', // 128px
};

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};
const theme = extendTheme({
  config,
  fonts,
  breakpoints,
  styles,
  colors: colors,
  components,
  fontSizes,
  space,
  icons: {
    logo: {
      path: (
        <svg
          width="3000"
          height="3163"
          viewBox="0 0 3000 3163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="3000" height="3162.95" fill="none" />
          <path
            d="M1470.89 1448.81L2170 2488.19H820V706.392H2170L1470.89 1448.81ZM1408.21 1515.37L909.196 2045.3V2393.46H1998.84L1408.21 1515.37Z"
            fill="currentColor"
          />
        </svg>
      ),
      viewBox: '0 0 3000 3163',
    },
  },
});

export default theme;
