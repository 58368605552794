import {isElectron, setStoredItem} from './helpers';
import {
  getCookie,
  setCookie,
} from './cookies';
import {IS_MOCK} from '../app/constants';

export const getAccessToken = async() => {
  if (IS_MOCK) return 'mock-token';
  if (isElectron) {
    const response = await window.electron.message.invoke('message', {
      type: 'GET_ACCESS_TOKEN',
    });
    const {error, payload} = response;
    if (!error) {
      return payload;
    }
  } else {
    const token = getCookie('wsxAuth');
    return token;
  }
};
if (typeof window !== 'undefined') window.getAuth = getAccessToken;

export const setAccessToken = async(accessToken, userId) => {
  if (isElectron) {
    await window.electron.message.invoke('message', {
      type: 'SET_ACCESS_TOKEN',
      payload: {accessToken, userId},
    });
  } else {
    setCookie({name: 'wsxAuth', value: accessToken, path: '/'});
  }
};
if (typeof window !== 'undefined') window.setAuth = setAccessToken;

export const deleteAccessToken = async() => {
  const deleteToken = async() => {
    if (isElectron) {
      await window.electron.message.invoke('message', {
        type: 'SET_ACCESS_TOKEN',
        payload: '',
      });
    } else {
      document.cookie = 'wsxAuth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  };

  while (await getAccessToken()) {
    await deleteToken();
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
};

export const deleteStoreHome = async() => {
  return await window.electron.message.invoke('message', {type: 'DELETE_STORE_HOME'});
};

// Used only for local electron app development
export const setUserId = async(id) => {
  await window.electron.message.invoke('message', {
    type: 'SET_USER_ID',
    payload: id,
  });
};

export const logout = async(id) => {
  await setStoredItem('workspace', '');
  await deleteAccessToken();
  if (isElectron) {
    await window.electron.message.invoke('message', {
      type: 'SET_USER_ID',
      payload: 'default',
    });
    await window.electron.message.invoke('message', {
      type: 'RESET_DEFAULT_USER',
    });
  }
};
