import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  status: 'init',
  width: 15,
  minWidth: 11,
  hidden: false,
};

export const panelLeftSlice = createSlice({
  name: 'panelLeft',
  initialState,
  reducers: {
    setPanelLeft: (state, action) => {
      Object.entries(action.payload).forEach(entry => {
        const [key, val] = entry;
        state[key] = val;
      });
      state.status = 'idle';
    },
  },
  extraReducers: {
  },
});

export const {
  setPanelLeft,
} = panelLeftSlice.actions;

export const selectPanelLeftStatus = (state) => {
  return state.panel_left.status;
};

export default panelLeftSlice.reducer;
